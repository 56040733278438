import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {LanguageDetectorService} from '../../service/locale/language-detector/language-detector.service';
import {AbstractLanguage} from '../../service/locale/language-detector/type/abstract-language';
import {DomUtils} from '../../utils/dom/dom-utils';
import {SearchRequest} from '../../service/api-client/search-pattern/type/search-request';
import {Subject} from 'rxjs';
import {AppComponent} from '../../app.component';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-common-search-bar',
   templateUrl: './common-search-bar.component.html',
   styleUrls: ['./common-search-bar.component.scss']
})
export class CommonSearchBarComponent implements OnInit, AfterContentChecked {
   public static searchRequestParametersSubject: Subject<SearchRequest> = new Subject<SearchRequest>();
   public static MAIN_SEARCH_BAR_ID = 'main-search-bar-id';

   mainSearchBarId = CommonSearchBarComponent.MAIN_SEARCH_BAR_ID;
   translationSheet: TranslationSheet;
   clientLanguage: AbstractLanguage;
   advancedSearchRoute = environment.frontEndRoutes.advancedSearch;

   constructor(
      public rootApp: AppComponent,
      private router: Router,
      private staticDictionary: StaticDictionaryService,
      private languageDetectorService: LanguageDetectorService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
      this.clientLanguage = languageDetectorService.getLanguage();
   }

   ngOnInit(): void {

   }

   ngAfterContentChecked(): void {
      if (this.rootApp.mainCategories.length > 0) {
         this.categoryChange();
      }
   }


   /**
    * The idea is to make the search select control adaptative in width. We could
    * use the 'ch' width units (e.g. width: 15ch), but that is a bit unreliable because it only works when
    * the chars have a fixed width (as in monospaced)
    *
    * The technique is to actually use a hidden component that automatically adapts its width. Then we set the content of
    * that control to the selected text, use the DOM utils to get its width in px and set the same width to the visible
    * select control
    */
   categoryChange(): void {

      const selectCategoryOptionSelected = $('#select-category option:selected');

      const selectCategoryLabel = DomUtils.getNullSafeHtmlFormElementById('select-category');

      let selectedText = selectCategoryOptionSelected.text().trim();
      if (selectedText === '') {
         // The page is still initializing, so, calculate for the "all option"
         selectedText = this.translationSheet.topBarNavigation.categories.all;
      }

      const hiddenTextWidthCalculator = $('#hidden-text-width-calculator');
      hiddenTextWidthCalculator.text(selectedText);

      let lenghHiddenWidthCalculatorInPx = hiddenTextWidthCalculator.width();

      if (!((lenghHiddenWidthCalculatorInPx === null) || (lenghHiddenWidthCalculatorInPx === undefined))) {
         lenghHiddenWidthCalculatorInPx += 25; // The width of the arrow down icon, approx
         selectCategoryLabel.style.width = lenghHiddenWidthCalculatorInPx + 'px';
      }
   }

   getPlaceholder(): string {
      const s1 = $('#select-category option:selected');

      const t1 = s1.text();

      return this.translationSheet.topBarNavigation.searchIn + ': ' + t1;
   }

   searchResults(): void {
      const selectCategory = DomUtils.getNullSafeHtmlElementById('select-category') as HTMLSelectElement;
      const searchTextBar = DomUtils.getNullSafeHtmlFormElementById('include-keywords-search-bar');

      const selectedCategoryValue = selectCategory.value;
      const searchText = searchTextBar.value ?? '';

      const searchParameters = new SearchRequest([], [], []);

      searchParameters.includeTagId(parseInt(selectedCategoryValue, 10));
      searchParameters.includeCommaSeparatedKeywordList(searchText);

      const route = environment.frontEndRoutes.searchResults + '?' + searchParameters.serializeAsQueryString();

      this.router.navigateByUrl(route).then(() => {
         CommonSearchBarComponent.searchRequestParametersSubject.next(searchParameters);
      });
   }
}
