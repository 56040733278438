import {AbstractLanguage} from '../../../../locale/language-detector/type/abstract-language';
import {Spanish} from '../../../../locale/language-detector/type/spanish';
import {French} from '../../../../locale/language-detector/type/french';

export class AbstractProjectTag {
   private readonly id: number;
   private readonly descriptionEnglish: string;
   private readonly descriptionFrench: string;
   private readonly descriptionSpanish: string;
   private readonly cssSubclass: string;

   public constructor(
      id: number,
      descriptionEnglish: string,
      descriptionSpanish: string,
      descriptionFrench: string,
      cssSubclass: string
   ) {
      this.id = id;
      this.descriptionEnglish = descriptionEnglish;
      this.descriptionFrench = descriptionFrench;
      this.descriptionSpanish = descriptionSpanish;
      this.cssSubclass = cssSubclass;
   }

   getId(): number {
      return this.id;
   }

   getDescriptionForLanguage(language: AbstractLanguage): string {
      if (language instanceof Spanish) {
         return this.descriptionSpanish;
      }

      if (language instanceof French) {
         return this.descriptionFrench;
      }

      return this.descriptionEnglish;
   }

   getDescriptionEnglish(): string {
      return this.descriptionEnglish;
   }

   getDescriptionFrench(): string {
      return this.descriptionFrench;
   }

   getDescriptionSpanish(): string {
      return this.descriptionSpanish;
   }

   getCssClass(): string {
      return this.cssSubclass;
   }
}
