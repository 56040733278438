import {Component, OnDestroy, OnInit} from '@angular/core';
import {FavouritesManagerService} from '../../service/favourites-service/favourites-manager.service';
import {PatternFavouritesContainer} from '../../service/favourites-service/type/pattern-favourites-container';
import {Subscription} from 'rxjs';
import {AddToFavourites} from '../../service/favourites-service/type/favourites-action/add-to-favourites';
import {AbstractFavouritesAction} from '../../service/favourites-service/type/favourites-action/abstract-favourites-action';
import {RemoveFromFavourites} from '../../service/favourites-service/type/favourites-action/remove-from-favourites';
import {SyncFavourites} from '../../service/favourites-service/type/favourites-action/sync-favourites';
import {Router} from '@angular/router';
import {CommonSearchBarComponent} from '../common-search-bar/common-search-bar.component';
import {SearchRequest} from '../../service/api-client/search-pattern/type/search-request';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-favourites-count',
   templateUrl: './favourites-count.component.html',
   styleUrls: ['../common-nav-bar-right/common-nav-bar-right.component.scss']
})
export class FavouritesCountComponent implements OnInit, OnDestroy {

   favouritesCount = 0;
   favouritesUpdated: Subscription;
   bussy = false;

   // searchRequestParams: SearchRequestObject = new SearchRequestObject([], [], []);

   constructor(
      private favouritesService: FavouritesManagerService,
      private router: Router
   ) {
      this.favouritesUpdated = favouritesService.favouritesUpdatedSubject.subscribe((updated: AbstractFavouritesAction) => {
         if (updated instanceof AddToFavourites) {
            this.favouritesCount++;
            // this.searchRequestParams.includePatternId(updated.getProjectId());
         } else if (updated instanceof RemoveFromFavourites) {
            this.favouritesCount--;

         } else if (updated instanceof SyncFavourites) {
            this.updateFavouritesCount();
         }
      });
   }

   formatFavouritesCount(count: number): string {
      if (count === 0) {
         return '';
      }

      if (count > 9) {
         return '9+';
      }

      return count.toString();
   }

   ngOnInit(): void {

      this.updateFavouritesCount();
   }

   private updateFavouritesCount(): void {
      this.bussy = true;
      this.favouritesService.getCurrentUserFavouritesList().subscribe((list: PatternFavouritesContainer) => {
         this.favouritesCount = list.count();
         this.bussy = false;
      });
   }

   configureFavouritesSearch(): void {

      this.favouritesService.getCurrentUserFavouritesList().subscribe((list: PatternFavouritesContainer) => {
         const favouritesByIds = list.serializeAsArrayOfIds();
         // If there are no favourites, we would be doing an empty search and that would retrieve all results from the
         // database. By setting this to a non-existing value, we force the search engine to perform a non-empty search,
         // and since -1 is a pattern id that doesn't exist, it would be effectively returning an empty resultset
         favouritesByIds.push(-1);

         const searchObject = new SearchRequest([], favouritesByIds, []);
         CommonSearchBarComponent.searchRequestParametersSubject.next(searchObject);

         const url = environment.frontEndRoutes.searchResults + '?' + searchObject.serializeAsQueryString();

         this.router.navigateByUrl(url).then();
      });


      // this.router.navigateByUrl('/search-results', {state: {searchCriteriaExternallyProvided: true}}).then(() => {
      //
      //    this.favouritesService.getCurrentUserFavouritesList().subscribe((list: PatternFavouritesContainer) => {
      //       const favouritesByIds = list.serializeAsArrayOfIds();
      //       // If there are no favourites, we would be doing an empty search and that would retrieve all results from the
      //       // database. By setting this to a non-existing value, we force the search engine to perform a non-empty search,
      //       // and since -1 is a pattern id that doesn't exist, it would be effectively returning an empty resultset
      //       favouritesByIds.push(-1);
      //
      //       CommonSearchBarComponent.searchRequestParametersSubject.next(new SearchRequest([], favouritesByIds, []));
      //    });
      // });
   }

   ngOnDestroy(): void {
      this.favouritesUpdated.unsubscribe();
   }
}
