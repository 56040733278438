import {AbstractBasicProjectData} from './abstract-basic-project-data';
import {BasicProjectDataInterface} from '../interface/basic-project-data-interface';

export class BasicProjectInfo extends AbstractBasicProjectData {
   public static constructFromInterface(i: BasicProjectDataInterface): BasicProjectInfo {
      return new BasicProjectInfo(i.projectId, i.projectTitleEnglish);
   }

   public constructor(projectId: number, titleEnglish: string) {
      super(projectId, titleEnglish);
   }
}
