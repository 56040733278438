import {Component, OnInit} from '@angular/core';
import {VerificationService} from '../service/checkout/verification/verification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';
import {VerificationDetails} from '../service/checkout/verification/type/verification-details';
import {PdfVerificationUnmanagedError} from './pdf-verification-unmanaged-error';
import {TranslatablePlaceholder} from '../service/locale/static-dictionary/type/translatable-placeholder';

@Component({
   selector: 'app-pdf-verification',
   templateUrl: './pdf-verification.component.html',
   styleUrls: ['./pdf-verification.component.scss']
})
export class PdfVerificationComponent implements OnInit {

   pageStatuses = {
      loading: 1,
      ready: 2
   };

   pageStatus: number;

   translationSheet: TranslationSheet;

   verificationDetails: VerificationDetails;

   constructor(
      private verificationService: VerificationService,
      private activatedRoute: ActivatedRoute,
      private staticDictionary: StaticDictionaryService,
      private router: Router
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.pageStatus = this.pageStatuses.loading;

      const downloadToken = this.activatedRoute.snapshot.paramMap.get('download-token');
      const pdfFormat = this.activatedRoute.snapshot.paramMap.get('pdf-format');
      const pdfLanguage = this.activatedRoute.snapshot.paramMap.get('pdf-language');

      if ((downloadToken === null) || (pdfFormat === null) || (pdfLanguage === null)) {
         this.redirectHome();
         return;
      }

      this
         .verificationService
         .getVerificationDetails(downloadToken, parseInt(pdfFormat, 10), parseInt(pdfLanguage, 10))
         .subscribe((v: VerificationDetails) => {
            this.verificationDetails = v;
            this.pageStatus = this.pageStatuses.ready;
         }, () => {
            this.redirectHome();
         });
   }

   private redirectHome(): void {
      this.router.navigate([environment.frontEndRoutes.home]).then(() => {
      });
   }

   getDeviceFromPdfFormatTranslated(): string {
      const pdfType = this.verificationDetails.getPdfFormat();

      if ((pdfType === 1) || (pdfType === 4)) {
         return this.translationSheet.downloadDetails.mobile;
      }

      if ((pdfType === 2) || (pdfType === 5)) {
         return this.translationSheet.downloadDetails.tablet;
      }

      if ((pdfType === 3) || (pdfType === 6)) {
         return this.translationSheet.downloadDetails.desktop;
      }

      throw new PdfVerificationUnmanagedError('Unrecognized PDF format: ' + pdfType.toString());
   }

   getSummaryTypeTranslated(): string {
      const pdfType = this.verificationDetails.getPdfFormat();

      if ((pdfType === 1) || (pdfType === 2) || (pdfType === 3)) {
         return this.translationSheet.downloadDetails.summarizedByColor;
      }

      if ((pdfType === 4) || (pdfType === 5) || (pdfType === 6)) {
         return this.translationSheet.downloadDetails.summarizedByGrid;
      }

      throw new PdfVerificationUnmanagedError('Unrecognized PDF format: ' + pdfType.toString());
   }

   contactUs() {
      let url = environment.frontEndRoutes.contactUs + '?';

      url =
         url + environment.queryStringParameterNames.contactUsSubject + '=' +
         this.translationSheet.verification.somethingNotRightContactUs.predefinedSubject + ': ' +
         this.verificationDetails.getReferenceForContact();

      const translatablePlaceholder: TranslatablePlaceholder = {name: 'branding', value: environment.branding.companyName};

      url =
         url + '&' +
         environment.queryStringParameterNames.contactUsBody +
         '=' + this.translationSheet.verification.somethingNotRightContactUs.predefinedBody.expand(
            [translatablePlaceholder]
         ) + ': ';

      this.router.navigateByUrl(url).then();
   }

   getLanguage(): string {
      if (this.verificationDetails.getLanguage().isFrench()) {
         return this.translationSheet.common.french;
      }

      if (this.verificationDetails.getLanguage().isSpanish()) {
         return this.translationSheet.common.spanish;
      }

      return this.translationSheet.common.english;
   }
}
