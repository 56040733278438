import {Component, OnInit, ViewChild} from '@angular/core';
import {BuildShoppingCartListService} from './service/build-shopping-cart-list-service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CommonSearchBarComponent} from '../top-navigation-bar/common-search-bar/common-search-bar.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {SearchRequest} from '../service/api-client/search-pattern/type/search-request';
import {UserService} from '../service/api-client/user/user.service';
import {ShoppingCartContainer} from './type/shopping-cart-container';
import {UserCollection} from '../service/api-client/user/type/user-collection';
import {UserCollectionItem} from '../service/api-client/user/type/user-collection-item';
import {ShoppingCartStorageService} from '../service/shopping-cart/shopping-cart-storage.service';
import {environment} from '../../environments/environment';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {AbstractLanguage} from '../service/locale/language-detector/type/abstract-language';
import {English} from '../service/locale/language-detector/type/english';
import {AbstractSalesChannel} from './type/sales-channels/abstract-sales-channel';
import {UnknownSalesChannel} from './type/sales-channels/unknown-sales-channel';
import {LanguageFactory} from '../service/locale/language-detector/helper/language-factory';
import {SalesChannelFactory} from './helper/sales-channel-factory';
import {ShoppingCartItem} from '../service/shopping-cart/object/shopping-cart-item';
import {SetTitleService} from '../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-shopping-cart',
   templateUrl: './shopping-cart.component.html',
   styleUrls: ['./shopping-cart.component.scss']
})

export class ShoppingCartComponent implements OnInit {
   shoppingCartList: ShoppingCartContainer | null = null;

   translationSheet: TranslationSheet;
   faqUrl = environment.frontEndRoutes.frequentlyAskedQuestions;

   loginCheckStatuses = {
      checking: 1,
      userLoggedIn: 2,
      userNotLoggedIn: 3
   };

   loginCheckStatus = this.loginCheckStatuses.checking;


   loadingShoppingCarStatuses = {
      loading: 1,
      loaded: 2
   };

   loadingShoppingCartStatus = this.loadingShoppingCarStatuses.loading;

   @ViewChild('generic_search_button') primaryActionComponent: PrimaryActionComponent;

   constructor(
      protected shoppingCartStorageService: ShoppingCartStorageService,
      protected buildShoppingCartListService: BuildShoppingCartListService,
      protected staticDictionaryService: StaticDictionaryService,
      protected router: Router,
      protected user: UserService,
      protected activatedRoute: ActivatedRoute,
      protected languageDetectorService: LanguageDetectorService,
      private titleService: SetTitleService,
      private canonical: SetCanonicalLinkService
   ) {
      this.translationSheet = staticDictionaryService.getTranslationSheet();

      this.removeFromShoppingCartItemsAlreadyInUserCollection();
   }

   private buildShoppingCart(): void {
      this.buildShoppingCartListService.buildShoppingCartListAsync().subscribe((s: ShoppingCartContainer) => {
         this.loadingShoppingCartStatus = this.loadingShoppingCarStatuses.loaded;
         this.shoppingCartList = s;
      });
   }

   ngOnInit(): void {
      this.titleService.setTitle(this.translationSheet.shoppingCart.yourShoppingCart);
      this.canonical.setCanonicalUrlBasedOnCurrentRoute();
      this.activatedRoute.queryParamMap.subscribe((q: ParamMap) => {
         if (q.has(environment.queryStringParameterNames.fastCheckout)) {
            this.activateFastCheckout(q);
         }

         this.removeFromShoppingCartItemsAlreadyInUserCollection();
      });
   }

   private removeFromShoppingCartItemsAlreadyInUserCollection(): void {
      this.user.getCurrentUser().subscribe(() => {
         const shoppingCartContainer = this.shoppingCartStorageService.readFromStorage();

         this.loginCheckStatus = this.loginCheckStatuses.userLoggedIn;
         this.user.getUserCollection().subscribe((userCollection: UserCollection) => {
            userCollection.getItems().forEach((item: UserCollectionItem) => {
               shoppingCartContainer.removeItemByIds(item.getProjectId(), item.getTemplateId());
            });

            this.shoppingCartStorageService.writeToStorage(shoppingCartContainer);
            this.buildShoppingCart();
         });

      }, () => {
         this.loginCheckStatus = this.loginCheckStatuses.userNotLoggedIn;
         this.buildShoppingCart();
      });
   }

   activateFastCheckout(q: ParamMap): void {
      const patternIdQs = q.get(environment.queryStringParameterNames.projectId);
      const templateIdQs = q.get(environment.queryStringParameterNames.templateId);
      const languageLiteralQs = q.get(environment.queryStringParameterNames.languageLiteral);
      const salesChannelIdQs = q.get(environment.queryStringParameterNames.salesChannel);

      if ((patternIdQs === null) || (templateIdQs === null)) {
         this.redirectHome();
         return;
      }

      const patternId = parseInt(patternIdQs, 10);
      const templateId = parseInt(templateIdQs, 10);

      let language: AbstractLanguage = new English();
      let salesChannel: AbstractSalesChannel = new UnknownSalesChannel();

      if (languageLiteralQs !== null) {
         try {
            language = LanguageFactory.createFromString(languageLiteralQs);
         } catch (e) {
            // Fail silently
         }
      }

      if (salesChannelIdQs !== null) {
         try {
            salesChannel = SalesChannelFactory.constructFromDbId(parseInt(salesChannelIdQs, 10));
         } catch (e) {
            // Fail silently
         }
      }

      const shoppingCartContainer = this.shoppingCartStorageService.readFromStorage();
      shoppingCartContainer.addItem(new ShoppingCartItem(patternId, templateId, 1, '', false));
      this.shoppingCartStorageService.writeToStorage(shoppingCartContainer);

      this
         .user
         .fastCheckoutEvent(patternId, templateId, language.getDbId(), salesChannel.getDbId())
         .subscribe(() => {
         });
   }

   genericSearch(): void {
      this.primaryActionComponent.setBusy(true);
      const searchCriteria = new SearchRequest([], [], []);
      const route = environment.frontEndRoutes.searchResults + '?' + searchCriteria.serializeAsQueryString();
      this.router.navigateByUrl(route).then(() => {
         CommonSearchBarComponent.searchRequestParametersSubject.next(new SearchRequest([], [], []));
         this.primaryActionComponent.setBusy(false);
      });
   }

   private redirectHome(): void {
      this.router.navigate([environment.frontEndRoutes.home]).then(() => {
      });
   }
}
