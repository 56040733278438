import {AbstractPalette} from './abstract-palette';
import {palettes} from './palette-interface';

export class Madeira extends AbstractPalette {

   getCssClass(): string {
      return 'madeira';
   }

   getUserFriendlyDescriptor(): string {
      return 'Madeira';
   }

   getValue(): string {
      return palettes.MADEIRA.toString();
   }
}
