import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchPatternsService} from '../service/api-client/search-pattern/search-patterns.service';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../service/api-client/user/user.service';
import {FavouritesManagerService} from '../service/favourites-service/favourites-manager.service';
import {PatternFavouritesContainer} from '../service/favourites-service/type/pattern-favourites-container';
import {PatternSearchResults} from '../service/api-client/search-pattern/type/pattern-search-results';
import {SearchRequest} from '../service/api-client/search-pattern/type/search-request';
import {CommonSearchBarComponent} from '../top-navigation-bar/common-search-bar/common-search-bar.component';
import {Subscription} from 'rxjs';
import {ProjectFullInfo} from '../service/api-client/project/type/project-full-info';
import Bricks from 'bricks.js';
import {DomUtils} from '../utils/dom/dom-utils';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {environment} from '../../environments/environment';
import {ModalComponent} from '../form-reusable-components/modal/modal/modal.component';
import {AbstractUserNameControlIcons} from '../form-reusable-components/textboxes/online-validated-username-textbox/UserNameControlIcons/abstract-user-name-control-icons';
import {UserNameControlStandardIcon} from '../form-reusable-components/textboxes/online-validated-username-textbox/UserNameControlIcons/user-name-control-standard-icon';
import {OnlineValidatedUsernameTextboxComponent} from '../form-reusable-components/textboxes/online-validated-username-textbox/online-validated-username-textbox.component';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {EventLoggerService} from '../service/api-client/events/event-logger.service';
import {SuccessModalComponent} from '../form-reusable-components/modal/success-modal/success-modal.component';
import {SharingPlatforms} from '../service/api-client/search-pattern/type/sharing-platforms';
import {SocialMediaShare} from '../service/api-client/search-pattern/type/social-media-share/social-media-share';
import {SocialMediaShareUtils} from '../utils/social-media/social-media-share-utils';

@Component({
   selector: 'app-search-results',
   templateUrl: './search-results.component.html',
   styleUrls: [
      './search-results.component.scss'
   ]
})
export class SearchResultsComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit, AfterContentChecked, AfterViewChecked {

   public static readonly MASONRY_CONTAINER_ID = 'masonry-container';

   // If you change this value, you also have to update the SCSS variable $searchItemshowcaseTileWidthPx in src/styles.scss
   public static readonly searchItemshowcaseTileWidthPx = 255;

   selectedShareOption = 1;

   shareOptions = {
      withAFriend: 1,
      inFacebook: 2,
      inPinterest: 3
   };

   shareWithNameValid = false;
   shareWithEmailValid = false;

   private readonly LAST_SEARCH_LOCAL_STORAGE_KEY = 'p0tAPCs0By';

   @ViewChild('sharePopupSelector') sharePopUp: ModalComponent;
   @ViewChild('validated_username_textbox') usernameTextbox: OnlineValidatedUsernameTextboxComponent;
   @ViewChild('validated_email_textbox') emailTextbox: OnlineValidatedEmailComponent;
   @ViewChild('share_with_friends_button') shareWithFriendsButton: PrimaryActionComponent;
   @ViewChild('share_search_success_modal') shareSuccessModal: SuccessModalComponent;
   @ViewChild('share_search_facebook') shareSearchFacebook: PrimaryActionComponent;
   @ViewChild('share_search_pinterest') shareSearchPinterest: PrimaryActionComponent;

   environment = environment;
   sharePopupId = 'share-popup-id';
   sharePopupSuccess = 'share-popup-success';

   masonryContainerId = SearchResultsComponent.MASONRY_CONTAINER_ID;

   private sizes = [
      {columns: 1, gutter: 20},
      {mq: '576px', columns: 2, gutter: 20},
      {mq: '992px', columns: 3, gutter: 20},
      {mq: '1120px', columns: 4, gutter: 20}
   ];

   pageStatuses = {
      loading: 0,
      ok: 1,
      error: 2,
      emptySearch: 3
   };

   pageStatus: number;

   isUserLoggedIn = false;
   searchResultItems: Array<ProjectFullInfo> = [];
   searchResultObject: PatternSearchResults | null = null;
   userFavouritesList: PatternFavouritesContainer | null = null;
   translationSheet: TranslationSheet;

   private searchParameterChange: Subscription;
   private checkLoggedInSubscription: Subscription;
   private bricksInstance: Bricks.Instance | null = null;
   protected bussy = false;

   constructor(
      protected searchPatternService: SearchPatternsService,
      public languageDetectorService: LanguageDetectorService,
      protected userService: UserService,
      protected favouritesManagerService: FavouritesManagerService,
      protected router: Router,
      private staticDictionary: StaticDictionaryService,
      protected activatedRoute: ActivatedRoute,
      protected eventLoggerService: EventLoggerService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();

      // This is just a subscription, it is not doing anything yet unless the data stream changes
      this
         .searchParameterChange = CommonSearchBarComponent
         .searchRequestParametersSubject
         .subscribe((searchParameters: SearchRequest) => {
            this.searchParametersChanged(searchParameters, false);
         });

      // Another subscription
      this.checkLoggedInSubscription = this.userService.getCurrentUser().subscribe(() => {
         this.isUserLoggedIn = true;
      }, () => {
         this.isUserLoggedIn = false;
      });
   }

   protected static resetContainer(): void {

      const itemsInContainer = Array.from(document.getElementsByClassName('masonry-item'));

      itemsInContainer.forEach((e: Element) => {
         e.remove();
      });

      const container = DomUtils.getNullSafeHtmlFormElementById(SearchResultsComponent.MASONRY_CONTAINER_ID);

      container.removeAttribute('style');
   }

   ngOnInit(): void {
      const queryString = window.location.search;
      const searchRequest = SearchRequest.constructFromQueryString(queryString);
      this.searchParametersChanged(searchRequest, false);

      this.userFavouritesList = null;

      this.favouritesManagerService.getCurrentUserFavouritesList().subscribe((favouritesList: PatternFavouritesContainer) => {
         this.userFavouritesList = favouritesList;
      });
   }

   private searchParametersChanged(searchParameters: SearchRequest, paginationEvent: boolean): void {
      if (this.bussy) {
         // A request already in progress, reject any others in the meantime. This could happen when the infinite scroll
         // control fires multiple 'onScroll' events at once (we don't know as to why this could happen, but it happens)
         return;
      }

      this.bussy = true;

      // Storing the current search in local storage will make the system to load actual results when the user lands on the
      // result page directly (vs. redirected from somewhere else). Also avoids showing an "empty result" message when they
      // change the webpage language. It will also help pre-populating the search fields when switching to advanced search
      window.localStorage.setItem(
         this.LAST_SEARCH_LOCAL_STORAGE_KEY,
         JSON.stringify(searchParameters.serializeAsJson(true)));

      if (!paginationEvent) {
         this.pageStatus = this.pageStatuses.loading;
      }

      this.searchPatternService.searchAndGetTemplateFullInfo(searchParameters).subscribe(
         (searchResultObj: PatternSearchResults) => {

            if (!paginationEvent) {

               SearchResultsComponent.resetContainer();
               this.searchResultItems = [];
            }

            this.searchResultObject = searchResultObj;

            searchResultObj.getPatternsFound().forEach((a: ProjectFullInfo) => {
               this.searchResultItems.push(a);
            });

            this.initBricksInstance();

            if (this.searchResultItems.length === 0) {
               this.pageStatus = this.pageStatuses.emptySearch;
            } else {
               this.pageStatus = this.pageStatuses.ok;
            }

            this.bussy = false;
         }, () => {
            this.pageStatus = this.pageStatuses.emptySearch;
            this.bussy = false;
         });
   }

   checkPatternIsFavourited(patternId: number): boolean | null {
      if (this.userFavouritesList === null) {
         return null;
      }

      return this.userFavouritesList.has(patternId);
   }

   protected initBricksInstance(): void {
      const thereAreElementToPack = this.searchResultItems.length > 0;
      const containerExists = DomUtils.getHtmlElementById(this.masonryContainerId);

      const c1 = thereAreElementToPack && containerExists;

      if (c1 && this.bricksInstance === null) {
         this.bricksInstance = Bricks({container: '.' + this.masonryContainerId, sizes: this.sizes, packed: 'data-packed'});
      } else if (c1 && this.bricksInstance !== null) {
         this.bricksInstance.pack();
         this.bricksInstance.resize();
         this.bricksInstance.update();
      }
   }

   ngAfterViewInit(): void {
      this.initBricksInstance();
   }

   ngAfterContentChecked(): void {
      this.initBricksInstance();
   }

   ngAfterContentInit(): void {
      this.initBricksInstance();
   }

   ngAfterViewChecked(): void {
      this.initBricksInstance();
   }

   onScrollDown() {
      if ((!this.bussy) && (this.searchResultObject !== null)) {
         if (this.searchResultObject.setNextPage()) {
            this.searchParametersChanged(this.searchResultObject.getSearchRequest(), true);
         }
      }
   }

   ngOnDestroy(): void {
      this.searchParameterChange.unsubscribe();
      this.checkLoggedInSubscription.unsubscribe();
   }

   showSharePopup(): void {
      this.sharePopUp.show();
   }

   closeSharePopup(): void {
      this.sharePopUp.hide();
   }

   getShareWithFriendIcon(): AbstractUserNameControlIcons {
      return new UserNameControlStandardIcon();
   }

   shareSearchResultsWithFriend() {
      if (this.shareWithEmailValid && this.shareWithNameValid && this.searchResultObject !== undefined) {
         this.shareWithFriendsButton.setBusy(true);

         const validName = this.usernameTextbox.getWellFormedUsername();
         const validEmail = this.emailTextbox.getWellFormedEmail();

         if (this.searchResultObject === undefined || this.searchResultObject === null) {
            return;
         }

         const searchId = this.searchResultObject.getSearchId();

         this.eventLoggerService.shareSearchResultsByEmailOutbound(searchId, validName, validEmail).subscribe(() => {
            this.shareWithFriendsButton.setBusy(false);
            this.usernameTextbox.resetControl();
            this.emailTextbox.resetControl();
            this.sharePopUp.hide();

            this.shareSuccessModal.show();
         }, () => {
            this.shareWithFriendsButton.setBusy(false);
         });
      }
   }

   shareSearchResultInFacebook(): void {
      if (this.searchResultObject !== null) {
         this.shareSearchFacebook.setBusy(true);
         const searchId = this.searchResultObject.getSearchId();
         this
            .eventLoggerService
            .shareSearchSocialMediaOutbound(searchId, SharingPlatforms.facebook)
            .subscribe((s: SocialMediaShare) => {
               this.shareSearchFacebook.setBusy(false);
               this.sharePopUp.hide();
               const lan = this.languageDetectorService.getLanguage();

               const assetUrl = s.getShareFacebook().getShareUrlForLanguage(lan);
               SocialMediaShareUtils.openFacebookSharePopup(assetUrl);
            });
      }
   }

   shareSearchResultInPinterest(): void {
      if (this.searchResultObject !== null) {
         this.shareSearchPinterest.setBusy(true);
         const searchId = this.searchResultObject.getSearchId();
         this
            .eventLoggerService
            .shareSearchSocialMediaOutbound(searchId, SharingPlatforms.pinterest)
            .subscribe((s: SocialMediaShare) => {
               this.shareSearchPinterest.setBusy(false);
               this.sharePopUp.hide();
               const lan = this.languageDetectorService.getLanguage();

               SocialMediaShareUtils.openPinterestSharePopup(s.getSharePinterest(), lan);
            });
      }
   }

   advancedSearch(): void {
      this.router.navigateByUrl(environment.frontEndRoutes.advancedSearch).then();
   }
}
