import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DownloadPdfDetails} from '../../../service/api-client/user/type/download-pdf-details';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {AbstractPdfGenerationStatus} from '../../../service/api-client/user/type/PdfGenerationStatus/abstract-pdf-generation-status';
import {PdfStatusInProgress} from '../../../service/api-client/user/type/PdfGenerationStatus/pdf-status-in-progress';
import {PdfStatusNeedsRequeue} from '../../../service/api-client/user/type/PdfGenerationStatus/pdf-status-needs-requeue';
import {DownloadService} from '../../../service/checkout/download/download.service';
import {PrimaryActionComponent} from '../../../form-reusable-components/buttons/primary-action/primary-action.component';
import {saveAs} from 'file-saver';
import {BlobResponse} from '../../../service/api-client/xstitch-api-gateway/type/blob-response';
import {RequeueService} from '../../../service/checkout/requeue/requeue.service';
import {UserCollectionItem} from '../../../service/api-client/user/type/user-collection-item';

@Component({
   selector: 'app-download-details',
   templateUrl: './download-details.component.html',
   styleUrls: [
      './download-details.component.scss',
      '../collection.component.scss'
   ]
})
export class DownloadDetailsComponent implements OnInit {

   @Input() downloadPdfDetails: DownloadPdfDetails;
   @Input() pdfGenerationStatus: AbstractPdfGenerationStatus;
   @Input() downloadToken: string;

   @ViewChild('grid_mobile_download_button') gridMobileDownloadButton: PrimaryActionComponent;
   @ViewChild('grid_tablet_download_button') gridTabletDownloadButton: PrimaryActionComponent;
   @ViewChild('grid_desktop_download_button') gridDesktopDownloadButton: PrimaryActionComponent;

   @ViewChild('color_mobile_download_button') colorMobileDownloadButton: PrimaryActionComponent;
   @ViewChild('color_tablet_download_button') colorTabletDownloadButton: PrimaryActionComponent;
   @ViewChild('color_desktop_download_button') colorDesktopDownloadButton: PrimaryActionComponent;

   @ViewChild('regenerate_button') regenerateButton: PrimaryActionComponent;

   forceGridMobileShowDownloaded = false;
   forceGridTabletShowDownloaded = false;
   forceGridDesktopShowDownloaded = false;
   forceColorMobileShowDownloaded = false;
   forceColorTabletShowDownloaded = false;
   forceColorDesktopShowDownloaded = false;

   public translationSheet: TranslationSheet;

   constructor(
      private dictionary: StaticDictionaryService,
      private downloadService: DownloadService,
      private requeueService: RequeueService
   ) {
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   public isDoneStatus(): boolean {
      return (this.downloadPdfDetails !== null) && (this.downloadPdfDetails !== undefined);
   }

   public isInProgressStatus(): boolean {
      return this.pdfGenerationStatus instanceof PdfStatusInProgress;
   }

   public getEtaInHours(): number {
      if (this.pdfGenerationStatus instanceof PdfStatusInProgress) {
         return this.pdfGenerationStatus.getEtaInHours();
      }

      return 0;
   }

   public isInRegenerateStatus(): boolean {
      return this.pdfGenerationStatus instanceof PdfStatusNeedsRequeue;
   }

   public getHourLiteral(hour: number): string {
      if (hour === 0) {
         return this.translationSheet.downloadDetails.inProgressStatus.lessThan +
            ' 1 ' +
            this.translationSheet.downloadDetails.inProgressStatus.hourSingular;
      }

      if (hour === 1) {
         return '1' + this.translationSheet.downloadDetails.inProgressStatus.hourSingular;
      }

      return hour.toString() + ' ' + this.translationSheet.downloadDetails.inProgressStatus.hourPlural;
   }

   public downloadGridMobile(): void {
      this.downloadAndSaveAction(
         this.gridMobileDownloadButton,
         this.downloadPdfDetails.getByGridMobile().getDownloadUrl()
      );

      this.forceGridMobileShowDownloaded = true;
   }

   public downloadGridTablet(): void {
      this.downloadAndSaveAction(
         this.gridTabletDownloadButton,
         this.downloadPdfDetails.getByGridTablet().getDownloadUrl()
      );

      this.forceGridTabletShowDownloaded = true;
   }

   public downloadGridDesktop() {
      this.downloadAndSaveAction(
         this.gridDesktopDownloadButton,
         this.downloadPdfDetails.getByGridDesktop().getDownloadUrl()
      );

      this.forceGridDesktopShowDownloaded = true;
   }

   public downloadColorMobile() {
      this.downloadAndSaveAction(
         this.colorMobileDownloadButton,
         this.downloadPdfDetails.getByColorMobile().getDownloadUrl()
      );

      this.forceColorMobileShowDownloaded = true;
   }

   public downloadColorTablet() {
      this.downloadAndSaveAction(
         this.colorTabletDownloadButton,
         this.downloadPdfDetails.getByColorTablet().getDownloadUrl()
      );

      this.forceColorTabletShowDownloaded = true;
   }

   public downloadColorDesktop() {
      this.downloadAndSaveAction(
         this.colorDesktopDownloadButton,
         this.downloadPdfDetails.getByColorDesktop().getDownloadUrl()
      );

      this.forceColorDesktopShowDownloaded = true;
   }

   private downloadAndSaveAction(button: PrimaryActionComponent, downloadUrl: string): void {
      button.setBusy(true);
      this.downloadService.download(downloadUrl).subscribe((rawFile: BlobResponse) => {
         saveAs(rawFile.getBlob(), rawFile.getName());
         button.setBusy(false);
      });
   }

   postRegenerate(): void {
      this.regenerateButton.setBusy(true);

      this.requeueService.requeueToken(this.downloadToken).subscribe((r: UserCollectionItem) => {
         const generationsStatus = r.getGenerationStatus();
         const downloadDetails = r.getDownloadDetails();

         if (generationsStatus !== undefined) {
            this.pdfGenerationStatus = generationsStatus;
         }

         if (downloadDetails !== undefined) {
            this.downloadPdfDetails = downloadDetails;
         }

         this.regenerateButton.setBusy(false);
      });
   }
}
