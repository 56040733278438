import {Component, Input, OnInit} from '@angular/core';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';

@Component({
   selector: 'app-slide',
   templateUrl: './slide.component.html',
   styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {

   @Input() title: string;
   @Input() description: string;
   @Input() assetUrl: string;

   translationSheet: TranslationSheet;

   constructor(dictionary: StaticDictionaryService) {
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

}
