import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c11-your-choices',
  templateUrl: './c11-your-choices.component.html'
})
export class C11YourChoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
