import {Component, Input, OnInit} from '@angular/core';

@Component({
   selector: 'app-flash-message-dismissable-success',
   templateUrl: './flash-message-dismissable-success.component.html',
   styleUrls: ['./flash-message-dismissable-success.component.scss']
})
export class FlashMessageDismissableSuccessComponent implements OnInit {
   @Input() message: string;
   
   constructor() {
   }

   ngOnInit(): void {
   }

}
