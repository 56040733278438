import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a1-measurement-and-personalisation',
  templateUrl: './c10a1-measurement-and-personalisation.component.html'
})
export class C10a1MeasurementAndPersonalisationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
