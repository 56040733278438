import {Component, Input, OnInit} from '@angular/core';
import {CountedDimensions} from '../service/api-client/search-pattern/type/counted-dimensions';
import * as bootstrap from 'bootstrap';
import {DomUtils} from '../utils/dom/dom-utils';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {CalculatorsService} from '../service/api-client/calculators/calculators.service';

@Component({
   selector: 'app-dimensions-calculator',
   templateUrl: './dimensions-calculator.component.html',
   styleUrls: ['./dimensions-calculator.component.scss']
})
export class DimensionsCalculatorComponent implements OnInit {
   @Input() patternTitle: string;
   @Input() templateId: number;
   @Input() countedDimensions: CountedDimensions[];
   @Input() widthInStitches: number;
   @Input() heightInStitches: number;
   @Input() coveragePercentage: number;
   @Input() totalStitches: number;

   fabricDimensionsCalculatorModalId = 'fabric-dimensions-calculator';

   translationSheet: TranslationSheet;

   constructor(staticDictionary: StaticDictionaryService, private calculatorsService: CalculatorsService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      const exampleEl = DomUtils.getNullSafeHtmlElementById('count-tooltip');

      // tslint:disable-next-line:no-unused-expression
      new bootstrap.Tooltip(exampleEl, {
         boundary: document.body
      });

      this.recalculateDimensions();
   }

   recalculateDimensions() {

      const storedCount = this.getCountFromLocalStore();
      const storedUnits = this.getUnitsFromLocalStore();

      const filtered = this.countedDimensions.filter((element: CountedDimensions) => {
         return element.getCountNumeric() === storedCount;
      });

      // Assumed only one is selected
      if (filtered.length !== 1) {
         return; // Not working properly, hopefully someone notices
      }

      const calculation = DomUtils.getNullSafeHtmlElementById('calculations');

      let lengthUnitsId: number;

      if (storedUnits === 'centimetres') {
         const widthCentimetres = filtered[0].getWidthCentimetres();
         const heightCentimetres = filtered[0].getHeightCentimetres();
         calculation.innerText = widthCentimetres.toString() + ' x ' + heightCentimetres.toString() + ' cm.';
         lengthUnitsId = 1;
      } else {
         const widthInches = filtered[0].getWidthInches();
         const heightInches = filtered[0].getHeightInches();
         calculation.innerText = widthInches.toString() + ' x ' + heightInches.toString() + ' ' + this.translationSheet.dimensionsCalculator.inchesShort;
         lengthUnitsId = 2;
      }

      this.calculatorsService.logDimensionsUsed(this.templateId, lengthUnitsId, storedCount);
   }

   saveCountToLocalStore(): void {
      const selectControl = DomUtils.getNullSafeHtmlSelectElementById('count-selector');
      const selectedValue = parseInt(selectControl.value, 10);

      localStorage.setItem('preferred_fabric_count', selectedValue.toString());
   }

   toggleUnitsTo(to: string) {
      const units = this.getUnitsFromLocalStore();

      if (units === to) {
         return;
      }

      if (to === 'inches') {
         localStorage.setItem('preferred_units', 'inches');

      } else {
         localStorage.setItem('preferred_units', 'centimetres');
      }
   }

   getUnitsFromLocalStore(): string {
      const preferredUnits = localStorage.getItem('preferred_units');

      let result: string;

      if ((preferredUnits === null) || (preferredUnits === undefined)) {
         result = 'centimetres';
         localStorage.setItem('preferred_units', result);
      } else {
         result = preferredUnits;
      }

      return result;
   }

   getCountFromLocalStore(): number {
      let count: number;

      const storedPreferredFabricCount = localStorage.getItem('preferred_fabric_count');

      if (storedPreferredFabricCount === null || storedPreferredFabricCount === undefined) {
         count = 14;
         localStorage.setItem('preferred_fabric_count', count.toString());
      } else {
         count = parseInt(storedPreferredFabricCount, 10);
      }

      return count;
   }

   clickEvent(): void {
      const units = this.getUnitsFromLocalStore();

      let unitsId: number;

      if (units === 'inches') {
         unitsId = 2;
      } else {
         unitsId = 1;
      }

      this.calculatorsService.logDimensionsUsed(this.templateId, unitsId, this.getCountFromLocalStore());
   }
}
