import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ProjectFullInfoInterface} from '../../service/api-client/project/interface/project-full-info-interface';
import {StandardApiErrorResponse} from '../../service/api-client/xstitch-api-gateway/type/standard-api-error-response';
import {StandardApiErrorModalComponent} from '../../form-reusable-components/modal/standard-api-error-modal/standard-api-error-modal.component';

@Component({
   selector: 'app-template-admin',
   templateUrl: './template-admin.component.html',
   styleUrls: ['./template-admin.component.scss']
})
export class TemplateAdminComponent implements OnInit {

   @Input() selectedProjectInfo: ProjectFullInfoInterface;
   @Output() deleteTemplateSuccess: EventEmitter<void> = new EventEmitter<void>();

   updateTemplateErrorModalId = 'updateTemplateErrorModalId';

   @ViewChild('update_template_error_modal_id') updateTemplateErrorModal: StandardApiErrorModalComponent;

   constructor() {
   }

   ngOnInit(): void {
   }

   showErrorModal(error: StandardApiErrorResponse): void {
      this.updateTemplateErrorModal.apiErrorResponse = error;
      this.updateTemplateErrorModal.show();
   }
}
