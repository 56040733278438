import {Component, OnInit} from '@angular/core';
import {AbstractSelfContainedModalComponent} from '../abstract-self-contained-modal/abstract-self-contained-modal.component';

@Component({
   selector: 'app-share-template-or-search-success',
   templateUrl: './share-template-or-search-success.component.html',
   styleUrls: ['./share-template-or-search-success.component.scss']
})
export class ShareTemplateOrSearchSuccessComponent extends AbstractSelfContainedModalComponent implements OnInit {

   ngOnInit(): void {
   }

}
