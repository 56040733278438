import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-c02-a5-other-sources-of-personal-data',
  templateUrl: './c02-a5-other-sources-of-personal-data.component.html'
})
export class C02A5OtherSourcesOfPersonalDataComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
