import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractSelfContainedModalComponent} from '../abstract-self-contained-modal/abstract-self-contained-modal.component';

@Component({
   selector: 'app-success-modal',
   templateUrl: './success-modal.component.html',
   styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent extends AbstractSelfContainedModalComponent implements OnInit {

   @Input() id: string;
   @Input() title: string;
   @Input() message: string;

   @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();

   ngOnInit(): void {
   }

   emitClickEvent() {
      this.clickEvent.emit();
   }
}
