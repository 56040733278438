import {UserCollectionItemResponseApiInterface, UserCollectionResponseApiInterface} from '../interface/user-collection-response-api-interface';
import {UserCollectionItem} from './user-collection-item';


export class UserCollection {

   public static constructFromInterface(i: UserCollectionResponseApiInterface): UserCollection {

      const items: Array<UserCollectionItem> = new Array<UserCollectionItem>();

      i.forEach((item: UserCollectionItemResponseApiInterface) => {
         items.push(UserCollectionItem.constructFromInterface(item));
      });

      return new UserCollection(items);

   }

   public constructor(
      private items: Array<UserCollectionItem>
   ) {
   }

   public getItems(): Array<UserCollectionItem> {
      return this.items;
   }
}
