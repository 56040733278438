export class PatternFlagsItem {

   private static readonly patternIdEntry = 'p';
   private static readonly sharedWithFriendsEntry = 'f';
   private static readonly sharedInFacebookEntry = 's';
   private static readonly sharedInPinterestEntry = 'sp';

   private readonly patternId: number;
   private sharedWithFriends: boolean;
   private sharedInFacebook: boolean;
   private favourited: boolean;
   private sharedInPinterest: boolean;

   static unserialize(serialized: object): PatternFlagsItem | null {
      const patternId = parseInt(serialized[PatternFlagsItem.patternIdEntry], 10);
      const sharedWithFriends = serialized[PatternFlagsItem.sharedWithFriendsEntry] === true;
      const sharedInFacebook = serialized[PatternFlagsItem.sharedInFacebookEntry] === true;
      const sharedInPinterest = serialized[PatternFlagsItem.sharedInPinterestEntry] === true;

      if (patternId > 0) {
         return new PatternFlagsItem(patternId, sharedWithFriends, sharedInFacebook, sharedInPinterest);
      }

      return null;
   }

   public constructor(
      patternId: number,
      sharedWithFriends: boolean,
      sharedInFacebook: boolean,
      sharedInPinterest: boolean
   ) {
      this.patternId = patternId;
      this.sharedInFacebook = sharedInFacebook;
      this.sharedWithFriends = sharedWithFriends;
      this.sharedInPinterest = sharedInPinterest;
   }

   serialize(): object {
      const result = {};

      result[PatternFlagsItem.patternIdEntry] = this.patternId;
      result[PatternFlagsItem.sharedInFacebookEntry] = this.sharedInFacebook;
      result[PatternFlagsItem.sharedWithFriendsEntry] = this.sharedWithFriends;
      result[PatternFlagsItem.sharedInPinterestEntry] = this.sharedInPinterest;

      return result;
   }

   public isSharedWithFriends(): boolean {
      return this.sharedWithFriends;
   }

   public isSharedInFacebook(): boolean {
      return this.sharedInFacebook;
   }

   public isSharedInPinterest(): boolean {
      return this.sharedInPinterest;
   }

   public getPatternId(): number {
      return this.patternId;
   }

   public setSharedWithFriends(sharedWithFriends: boolean): void {
      this.sharedWithFriends = sharedWithFriends;
   }

   public setSharedInFacebook(sharedInFacebook: boolean): void {
      this.sharedInFacebook = sharedInFacebook;
   }

   public setFavourited(favourited: boolean): void {
      this.favourited = favourited;
   }

   public setSharedInPinterest(sharedInPinterest: boolean): void {
      this.sharedInPinterest = sharedInPinterest;
   }
}
