import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../../api-client/xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {StandardApiResponseDeprecated} from '../../api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {UserCollectionItem} from '../../api-client/user/type/user-collection-item';
import {UserCollectionItemResponseApiInterface} from '../../api-client/user/interface/user-collection-response-api-interface';

@Injectable({
   providedIn: 'root'
})
export class RequeueService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public requeueToken(downloadToken: string): Observable<UserCollectionItem> {
      return new Observable<UserCollectionItem>((s: Subscriber<UserCollectionItem>) => {
         let backendUrl = environment.xstitchApiEndpoints.checkout.pdfRequeue;
         backendUrl = backendUrl.replace('{download-token}', downloadToken);

         const payload: Map<string, any> = new Map<string, any>();

         const response = this
            .xstitchApiGateway
            .postWithJsonBodyRelativeRoute<UserCollectionItemResponseApiInterface>(
               backendUrl,
               payload
            );

         response.subscribe((r: UserCollectionItemResponseApiInterface) => {
            s.next(UserCollectionItem.constructFromInterface(r));
         }, error => {
            s.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }
}
