import {ProjectFullInfoInterface} from '../interface/project-full-info-interface';
import {ProjectTemplate} from '../../search-pattern/type/project-template';
import {ProjectTemplateInterface} from '../../search-pattern/interface/project-template-interface';
import {SimpleProjectTagInterface} from '../../project-tag/interface/project-tag-interface/simple-project-tag-interface';
import {SimpleProjectTag} from '../../project-tag/type/project-tag/simple-project-tag';
import {LocalisedProjectInfo} from './localised-project-info';
import {PayloadNamingRepo as PNR} from '../../xstitch-api-gateway/payload-naming-repo';

export class ProjectFullInfo extends LocalisedProjectInfo {
   private static readonly FREE_TAG_ID = 4;

   static constructFromInterface(si: ProjectFullInfoInterface): ProjectFullInfo {


      let containsFreeTag = false;
      const tags: SimpleProjectTag[] = [];

      si.tags.forEach((ti: SimpleProjectTagInterface) => {
         tags.push(SimpleProjectTag.constructFromInterface(ti));


         if (ti.id === ProjectFullInfo.FREE_TAG_ID) {
            containsFreeTag = true;
         }
      });

      const templates: ProjectTemplate[] = [];

      si.projectTemplates.forEach((pt: ProjectTemplateInterface) => {
         templates.push(ProjectTemplate.constructFromInterface(pt));
      });

      return new ProjectFullInfo(
         si.projectId,
         si.projectTitleEnglish,
         si.projectTitleFrench,
         si.projectTitleSpanish,
         si.descriptionEnglish,
         si.descriptionFrench,
         si.descriptionSpanish,
         templates,
         si.projectShowcaseImageUrl,
         containsFreeTag,
         tags,
         si.projectShowcaseImageWidthPx,
         si.projectShowcaseImageHeightPx,
         si.isPrivateProject
      );
   }

   public constructor(
      projectId: number,
      projectTitleEnglish: string,
      projectTitleFrench: string,
      projectTitleSpanish: string,
      descriptionEnglish: string | null | undefined,
      descriptionFrench: string | null | undefined,
      descriptionSpanish: string | null | undefined,
      private readonly projectTemplates: ProjectTemplate[],
      private readonly projectShowcaseImageUrl: string,
      private readonly containsFreeTag: boolean,
      private readonly tags: SimpleProjectTag[],
      private readonly projectShowcaseImageWidthPx: number,
      private readonly projectShowcaseImageHeightPx: number,
      private readonly isPrivateProject: boolean) {

      super(
         projectId,
         projectTitleEnglish,
         projectTitleFrench,
         projectTitleSpanish,
         descriptionEnglish,
         descriptionFrench,
         descriptionSpanish
      );
   }

   getProjectShowboxImageUrl(): string {
      return this.projectShowcaseImageUrl;
   }

   getRelatedSearchTags(): SimpleProjectTag[] {
      return this.tags;
   }

   getFirstTemplate(): ProjectTemplate {
      return this.projectTemplates[0];
   }

   getTemplateById(templateId: number): ProjectTemplate | null {
      let result: ProjectTemplate | null = null;

      this.projectTemplates.forEach((p: ProjectTemplate) => {
         if (p.getTemplateId() === templateId) {
            result = p;
         }
      });

      return result;
   }

   countTemplates(): number {
      return this.projectTemplates.length;
   }

   getTemplates(): ProjectTemplate[] {
      return this.projectTemplates;
   }

   hasFreeTag(): boolean {
      return this.containsFreeTag;
   }

   getProjectShowcaseImageWidthPx(): number {
      return this.projectShowcaseImageWidthPx;
   }

   getProjectShowcaseImageHeightPx(): number {
      return this.projectShowcaseImageHeightPx;
   }

   checkIsPrivateProject(): boolean {
      return this.isPrivateProject;
   }

   getTagIds(): Array<number> {
      const result: Array<number> = [];

      this.tags.forEach((a: SimpleProjectTag) => {
         result.push(a.getId());
      });

      return result;
   }

   serializeForProjectUpdate(): Map<string, any> {
      const payload: Map<string, any> = new Map<string, any>();

      payload
         .set(PNR.PROJECT_TITLE_ENGLISH, this.getProjectTitleEnglish())
         .set(PNR.PROJECT_TITLE_SPANISH, this.getProjectTitleSpanish())
         .set(PNR.PROJECT_TITLE_FRENCH, this.getProjectTitleFrench())
         .set(PNR.DESCRIPTION_ENGLISH, this.getDescriptionEnglish())
         .set(PNR.DESCRIPTION_SPANISH, this.getDescriptionSpanish())
         .set(PNR.DESCRIPTION_FRENCH, this.getDescriptionFrench())
         .set(PNR.PROJECT_TAGS, this.getTagIds())
      ;

      return payload;
   }
}
