import {AbstractPdfGenerationStatus} from './abstract-pdf-generation-status';

export class PdfStatusInProgress extends AbstractPdfGenerationStatus {
   public constructor(private etaInHours: number) {
      super();
   }

   public getEtaInHours(): number {
      return this.etaInHours;
   }
}
