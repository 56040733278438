export const xstitchApiEndpointPathParameters = {
   managingToken: '{managingToken}'
};

export const xstitchApiEndpoints = {
   // ToDo: use the PHPRoute syntax to parametrize routes (eg checkout/free/{templateId}), and include the parameters in the
   //  route itself for the sake of clarity. Then use string search replacements to replace the placeholders for
   //  actual values.

   projectTag: {
      getProjectTag: 'project/tag'
   },
   search: {
      searchAndGetTemplateFullInfo: 'search',
      getAllTagsReport: 'full-tag-list',
      getTreeTagsReport: 'full-tag-tree',
      getProjectPublicComments: 'project/comments',
      revisitSearchEmail: 'search-results/revisit/email/' + xstitchApiEndpointPathParameters.managingToken,
      revisitSearchSocialMedia: 'search-results/revisit/social-media/' + xstitchApiEndpointPathParameters.managingToken,
      shareSearchByEmailOutbound: 'user/events/share/search-results/email',
      shareSearchPinterestOutbound: 'user/events/share/search-results/pinterest/outbound',
      shareSearchFacebookOutbound: 'user/events/share/search-results/facebook/outbound'
   },
   calculators: {
      time_schedule_calculator: 'calculators/pattern-scheduler'
   },
   user: {
      registerNewUser: 'user/register',
      verifyUserAccount: 'user/account/verify/', // Actually it is 'user/account/verify/{email}/{verification token}
      logInUser: 'user/login',
      userSessionLoggedIn_DEPRECATED: 'user/session/logged-in',
      resendVerificationEmail: 'user/account/resend-verification',
      forgotPasswordRequest: 'user/account/forgot-password-request',
      resetForgottenPassword: 'user/account/reset-forgotten-password',
      contactUsMessage: 'user/contact-us',
      check_reset_password_account_token_valid: 'user/account/check-reset-account-password-token-valid',
      sync_local_favourite_projects: 'user/favourites/sync-local',
      get_user_favourite_patterns_list: 'user/favourites/get',
      leaveCommentForProject: 'user/projects/leave-comment',
      userAccountDetails: 'user/account/details',
      changeUserName: 'user/account/change-name',
      changeAccountPassword: 'user/account/change-password',
      changeNewsletterFrequency: 'user/account/change-newsletter-frequency',
      userCollection: 'user/collection',
      downloadDetailsByToken: 'pdf/download-status/{download-token}'
   },
   client_utils: {
      password_structure_checker: 'client-utils/check-password-structure',
      email_structure_checker: 'client-utils/check-email-structure',
      username_structure_checker: 'client-utils/check-user-name-structure',
      logFrontEndError: 'client-utils/log-front-end-error'
   },
   homePage: {
      homepageCategoryConfiguration: 'frontend/homepage'
   },
   events: {
      facebookOutboundTraffic: 'user/events/facebookshare/outbound/template/', // Add the template ID as route param
      pinterestOutboundTraffic: 'user/events/pinterestshare/outbound/template/', // Add the template ID as route param
      shareTemplateByEmail: 'user/events/share/template/email',
      unsubscribeEmailShare: 'user/events/unsubscribe/email-share',
      resubscribeEmailShare: 'user/events/resubscribe/email-share',

      addTemplateToFavourites: 'user/events/add-project-favourites',
      removeTemplateFromFavourites: 'user/events/remove-project-favourites',
      homepageVisit: 'user/events/homepage-visit',
      templateVisit: 'user/events/template-visit',
      dimensionsCalculatorUse: 'user/events/dimensions-calculator'
   },
   checkout: {
      paypal: {
         captureOrder: 'checkout/paypal/capture-order',
         createOrder: 'checkout/paypal/create-order',
         getMerchantPublicDetails: 'checkout/paypal/public-merchant-details'
      },
      free: {
         addFreeTemplateToCollection: 'checkout/free'
      },
      pdfVerification: 'verify-pdf/{download-token}/{pdf-format}/{pdf-language}',
      pdfRequeue: 'pdf/requeue/{download-token}'
   },
   admin: {
      apiStatus: 'api-status',
      tagAdmin: {
         getTags: 'admin/tag',
         postTag: 'admin/tag',
         putTag: 'admin/tag/{tagId}',
         deleteTag: 'admin/tag/{tagId}'
      },
      projectTemplateAdmin: {
         createEmptyTemplate: 'admin/template',
         updateAndDeleteTemplate: 'admin/template/{templateId}'
      },
      project: {
         getProjectListForDropdownSelector: 'admin/project/dropdown-selector',
         getAndDeleteProject: 'admin/project/{projectId}'
      }
   }
};

export const queryStringParameterNames = {
   successRedirect: 'success-redirect',
   cancelRedirect: 'cancel-redirect',
   contactUsBody: 'contact-us-body',
   contactUsSubject: 'contact-us-subject',
   usserAccountActiveSection: 'active-section'
};
export const frontEndRoutesPathParameters = {
   managingToken: 'managingToken',
   revisitType: 'revisitType'
};

export const frontEndRoutes = {
   unsubscribeSharing: 'unsubscribe/email-share/:' + frontEndRoutesPathParameters.managingToken,
   resubscribeSharing: 'resubscribe/email-share/:' + frontEndRoutesPathParameters.managingToken,
   pdfVerification: 'verify-pdf/:download-token/:pdf-format/:pdf-language',
   commonTemplateDownloadArea: 'standalone-download-area/:download-token',
   accountHome: 'user/home/account',
   accountDetails: 'test',
   loginRoute: 'account/login',
   shoppingCartRoute: 'shopping-cart',
   checkoutRoute: 'checkout',
   registerRoute: 'account/register',
   checkoutSuccess: 'checkout-success',
   addFreeTemplateToCollection: 'template/free-template/add-to-collection/:template-id',
   home: '/',
   contactUs: '/contact-us',
   searchResults: 'search-results',
   revisitEmailSearchResults: 'revisit/:' + frontEndRoutesPathParameters.revisitType + '/:' + frontEndRoutesPathParameters.managingToken,
   revisitSocialMediaSearchResults: 'revisit/:' + frontEndRoutesPathParameters.revisitType + '/:' + frontEndRoutesPathParameters.managingToken,
   advancedSearch: 'search/advanced',
};

export const xstitchApiErrorCodes = {
   malformed_request: {
      missing_parameter: 1000
   },
   users: {
      user_with_same_email_exists: 'emailAlreadyExists',
      incorrect_username_or_password: 'purposedlyGenericLogInFail',
      must_verify_account_before_log_in: 'accountNotVerifed',
      reset_password_token_obsolete: 30005,
      user_not_logged_in: 30012
   },
   utils: {
      password_check_too_short: 'expectedMinValue',
      unrecognized_email_structure: 20002,
      username_too_short: 'expectedMinValue',
      username_too_long: 'expectedMaxValue',
      email_subject_too_short: 30008,
      email_subject_too_long: 30009,
      email_plain_text_body_too_short: 30010,
      email_plain_text_body_too_long: 30011,
   }
};


export const cookieLanguageName = 'YH0SoYJTAW';

export const emailAddresses = {
   default_email_address: 'webmaster@replicant.xstitchlibrary.com'
};

export const branding = {
   companyName: 'XStitch Library'
};

export const continuousOnlineValidationRequestBufferMillis = 1000;
