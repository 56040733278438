import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import * as bootstrap from 'bootstrap';
import {DomUtils} from '../utils/dom/dom-utils';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';

@Component({
   selector: 'app-swatch-selector',
   templateUrl: './swatch-selector.component.html',
   encapsulation: ViewEncapsulation.None
})
export class SwatchSelectorComponent implements OnInit, OnDestroy {

   translationSheet: TranslationSheet;

   private static getSwatchCarouselElement(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('swatch-carousel');
   }

   private static leftRightArrowVisibility(): void {
      SwatchSelectorComponent.manageLeftArrowVisibility();
      SwatchSelectorComponent.manageRightArrowVisibility();
   }

   private static manageLeftArrowVisibility(): void {
      const swatchCarousel = SwatchSelectorComponent.getSwatchCarouselElement();
      const leftArrow = DomUtils.getNullSafeHtmlElementById('left-arrow');

      const scrollLeft = swatchCarousel.scrollLeft;

      const allTheWayLeft = scrollLeft === 0;

      if (allTheWayLeft) {
         leftArrow.style.visibility = 'hidden';
      } else {
         leftArrow.style.visibility = '';
      }
   }

   private static manageRightArrowVisibility(): void {
      const swatchCarousel = SwatchSelectorComponent.getSwatchCarouselElement();
      const scrollLeft = swatchCarousel.scrollLeft;
      const carouselWidth = swatchCarousel.clientWidth;
      const scrollWidth = swatchCarousel.scrollWidth;
      const rightArrow = DomUtils.getNullSafeHtmlElementById('right-arrow');

      const allTheWayRight = scrollLeft === (scrollWidth - carouselWidth);

      if (allTheWayRight) {
         rightArrow.style.visibility = 'hidden';
      } else {
         rightArrow.style.visibility = '';
      }
   }

   constructor(staticDictionaryService: StaticDictionaryService) {
      this.translationSheet = staticDictionaryService.getTranslationSheet();
   }

   ngOnInit(): void {
      const exampleEl = DomUtils.getNullSafeHtmlElementById('swatch-background');

      // tslint:disable-next-line:no-unused-expression
      new bootstrap.Tooltip(exampleEl, {
         boundary: document.body
      });

      SwatchSelectorComponent.leftRightArrowVisibility();

      const swatchCarousel = SwatchSelectorComponent.getSwatchCarouselElement();
      swatchCarousel.addEventListener('scroll', SwatchSelectorComponent.leftRightArrowVisibility);

      window.addEventListener('resize', SwatchSelectorComponent.leftRightArrowVisibility);

   }

   ngOnDestroy(): void {
      window.removeEventListener('resize', SwatchSelectorComponent.leftRightArrowVisibility);
   }

   changeBackground(cssClass: string): void {
      const img = DomUtils.getNullSafeHtmlElementById('pattern-thumbnail');

      const classListToRemove: string[] = [];

      img.classList.forEach((imgCssClass: string) => {
         if (imgCssClass.startsWith('swatch-')) {
            classListToRemove.push(imgCssClass);
         }
      });

      classListToRemove.forEach((cssClassToRemove: string) => {
         img.classList.remove(cssClassToRemove);
      });

      img.classList.add(cssClass);

      const swatchSelected = document.getElementsByClassName('swatch-selected');

      Array.from(swatchSelected).forEach((element: HTMLElement) => {
         element.classList.remove('swatch-selected');
      });

      const swatchToSelect = document.getElementsByClassName('xs-swatch ' + cssClass);

      // Hopefully, only one element
      const el = swatchToSelect.item(0);
      if ((swatchToSelect.length !== 1) || (el === null) || (el === undefined)) {
         return;
      }

      el.classList.add('swatch-selected');
   }

   scrollLeft(): void {
      const swatchCarousel = SwatchSelectorComponent.getSwatchCarouselElement();

      swatchCarousel.scrollLeft -= 20;
   }

   scrollRight(): void {
      const swatchCarousel = SwatchSelectorComponent.getSwatchCarouselElement();

      swatchCarousel.scrollLeft += 20;
   }
}
