import {SelectableOptionInterface} from '../interface/selectable-option-interface';

export class SelectableOptionCollection {
   private readonly indexedCollection: Map<string, SelectableOptionInterface> = new Map<string, SelectableOptionInterface>();

   public constructor(private readonly options: Array<SelectableOptionInterface>) {
      let k: string;
      options.forEach((o: SelectableOptionInterface) => {
         k = o.getValue();
         if (this.indexedCollection.has(k)) {
            throw new Error('Duplicated key in collection');
         }
         this.indexedCollection.set(k, o);
      });
   }

   public findByValue(v: string): SelectableOptionInterface | null {
      if (this.indexedCollection.has(v)) {
         return this.indexedCollection.get(v);
      }

      return null;
   }

   /**
    * This function was also implemented to return an IterableIterator<SelectableOptionInterface>, as in ...
    *
    * return this.indexedCollection.values()
    *
    * ...which avoided doing the loop, but that caused issues in the template, as I always get a...
    *
    * "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    * Previous value: '[object Map Iterator]'. Current value: '[object Map Iterator]'"
    *
    * ... kind of error in the console
    */
   public getUnindexedCollection(): Array<SelectableOptionInterface> {
      const result: Array<SelectableOptionInterface> = [];

      this.indexedCollection.forEach((v: SelectableOptionInterface) => {
         result.push(v);
      });

      return result;
   }
}
