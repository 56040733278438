import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a3-online-marketing',
  templateUrl: './c10a3-online-marketing.component.html'
})
export class C10a3OnlineMarketingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
