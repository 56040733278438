import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c02-a3-when-you-shop-with-us-online',
  templateUrl: './c02-a3-when-you-shop-with-us-online.component.html'
})
export class C02A3WhenYouShopWithUsOnlineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
