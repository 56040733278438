import {TranslatablePlaceholder} from './translatable-placeholder';

export class ParametrizedTranslation {

   public constructor(private parametrizedMessage: string) {
   }

   public expand(values: TranslatablePlaceholder[]): string {
      let result = this.parametrizedMessage;

      values.forEach((p: TranslatablePlaceholder) => {
         result = result.replace('%' + p.name + '%', p.value);
      });

      return result;
   }
}
