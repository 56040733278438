import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
})
export class CookiePolicyComponent implements OnInit {



  constructor() {
  }

  ngOnInit(): void {
  }

}
