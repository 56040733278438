import {Injectable} from '@angular/core';
import {LocalBrowserShoppingCartContainer} from './object/local-browser-shopping-cart-container';

@Injectable({
   providedIn: 'root'
})

export class ShoppingCartStorageService {

   private static readonly SHOPPING_CART_STORAGE_ENTRY = 'sc';

   constructor() {
   }

   readFromStorage(): LocalBrowserShoppingCartContainer {
      const storage = localStorage.getItem(ShoppingCartStorageService.SHOPPING_CART_STORAGE_ENTRY);
      if ((storage === '') || (storage === null) || (storage === undefined)) {
         return new LocalBrowserShoppingCartContainer();
      }

      return LocalBrowserShoppingCartContainer.unserialize(storage);
   }

   writeToStorage(cart: LocalBrowserShoppingCartContainer): void {
      const serialized = cart.serialize();
      localStorage.setItem(ShoppingCartStorageService.SHOPPING_CART_STORAGE_ENTRY, JSON.stringify(serialized));
   }

   resetShoppingCartContent(): void {
      const emptyShoppingCart = new LocalBrowserShoppingCartContainer();
      this.writeToStorage(emptyShoppingCart);
   }
}
