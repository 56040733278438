import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c09-cookies-and-similar-technologies',
  templateUrl: './c09-cookies-and-similar-technologies.component.html'
})
export class C09CookiesAndSimilarTechnologiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
