import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c08-how-long-we-use-personal-data-for',
  templateUrl: './c08-how-long-we-use-personal-data-for.component.html'
})
export class C08HowLongWeUsePersonalDataForComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
