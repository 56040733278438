import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {AdminProjectTag} from './type/project-tag/admin-project-tag';
import {environment} from '../../../../environments/environment';
import {AdminProjectTagInterface} from './interface/project-tag-interface/admin-project-tag-interface';
import {PayloadNamingRepo as PNR} from '../xstitch-api-gateway/payload-naming-repo';
import {StandardApiResponseDeprecated} from '../xstitch-api-gateway/type/standard-api-response-deprecated';

@Injectable({
   providedIn: 'root'
})
export class ProjectTagService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public getAllTagsForAdmin(filterKeyword: string | undefined): Observable<AdminProjectTag[]> {
      return new Observable<AdminProjectTag[]>((s: Subscriber<AdminProjectTag[]>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.tagAdmin.getTags;

         if (filterKeyword) {
            endpoint = endpoint + '?' + PNR.TAG_FILTER_KEYWORD + '=' + filterKeyword;
         }

         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<AdminProjectTagInterface[]>(endpoint);

         response.subscribe((raw: AdminProjectTagInterface[]) => {
            const result = new Array<AdminProjectTag>();

            raw.forEach((i: AdminProjectTagInterface) => {
               result.push(AdminProjectTag.constructFromInterface(i));
            });

            s.next(result);
         }, () => {
            s.error();
         });
      });
   }

   public updateTag(
      tagId: number,
      titleEnglish: string,
      titleSpanish: string,
      titleFrench: string,
      cssClass: string,
      showHomePage: boolean,
      homePageImage: string | null,
      parentId: number | null
   ): Observable<void> {
      return new Observable<void>((subscriber: Subscriber<void>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.tagAdmin.putTag;
         endpoint = endpoint.replace('{tagId}', tagId.toString(10));
         const payload: Map<string, any> = new Map<string, any>();

         payload
            .set(PNR.DESCRIPTION_ENGLISH, titleEnglish)
            .set(PNR.DESCRIPTION_SPANISH, titleSpanish)
            .set(PNR.DESCRIPTION_FRENCH, titleFrench)
            .set(PNR.TAG_CSS_SUBCLASS, cssClass)
            .set(PNR.TAG_SHOW_HOMEPAGE, showHomePage)
            .set(PNR.TAG_HOMEPAGE_IMG, homePageImage)
            .set(PNR.TAG_PARENT_ID, parentId);

         const response = this.xstitchApiGateway.putWithJsonBodyRelativeRoute(endpoint, payload);

         response.subscribe(() => {
            subscriber.next();
         }, (e) => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(e));
         });
      });
   }

   public createTag(
      titleEnglish: string,
      titleSpanish: string,
      titleFrench: string,
      cssClass: string,
      showHomePage: boolean,
      homePageImage: string | null,
      parentId: number | null
   ): Observable<AdminProjectTag> {
      return new Observable<AdminProjectTag>((subscriber: Subscriber<AdminProjectTag>) => {
         const endpoint = environment.xstitchApiEndpoints.admin.tagAdmin.postTag;
         const payload: Map<string, any> = new Map<string, any>();

         payload
            .set(PNR.DESCRIPTION_ENGLISH, titleEnglish)
            .set(PNR.DESCRIPTION_SPANISH, titleSpanish)
            .set(PNR.DESCRIPTION_FRENCH, titleFrench)
            .set(PNR.TAG_CSS_SUBCLASS, cssClass)
            .set(PNR.TAG_SHOW_HOMEPAGE, showHomePage)
            .set(PNR.TAG_HOMEPAGE_IMG, homePageImage)
            .set(PNR.TAG_PARENT_ID, parentId);

         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<AdminProjectTagInterface>(endpoint, payload);

         response.subscribe((a: AdminProjectTagInterface) => {
            subscriber.next(AdminProjectTag.constructFromInterface(a));
         }, (e) => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(e));
         });
      });
   }

   public deleteTag(tagId: number): Observable<void> {
      return new Observable<void>((subscriber: Subscriber<void>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.tagAdmin.deleteTag;
         endpoint = endpoint.replace('{tagId}', tagId.toString());
         const response = this.xstitchApiGateway.deleteRelativeRoute(endpoint);

         response.subscribe(() => {
            subscriber.next();
         }, (error) => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }
}
