import {ShareFacebook} from './share-facebook';
import {SharePinterest} from './share-pinterest';
import {SocialMediaShareInterface} from '../../interface/social-media-share/social-media-share-interface';

export class SocialMediaShare {

   public constructor(private shareFacebook: ShareFacebook, private sharePinterest: SharePinterest) {
   }

   public static constructFromInterface(i: SocialMediaShareInterface): SocialMediaShare {
      const shareFacebook = ShareFacebook.constructFromInterface(i.facebook);
      const sharePinterest = SharePinterest.constructFromInterface(i.pinterest);

      return new SocialMediaShare(shareFacebook, sharePinterest);
   }

   public getShareFacebook(): ShareFacebook {
      return this.shareFacebook;
   }

   public getSharePinterest(): SharePinterest {
      return this.sharePinterest;
   }
}
