import {Component, Input} from '@angular/core';
import {AbstractSelfContainedModalComponent} from '../../form-reusable-components/modal/abstract-self-contained-modal/abstract-self-contained-modal.component';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-modal-login-first-to-add-free-template',
   templateUrl: './login-first-to-add-free-template-popup.component.html',
   styleUrls: ['./login-first-to-add-free-template-popup.component.scss']
})
export class LoginFirstToAddFreeTemplatePopupComponent extends AbstractSelfContainedModalComponent {

   @Input() logInUrl: string;

   environmentVars = environment;
}
