import {CountedDimensionsInterface} from '../interface/counted-dimensions-interface';

// noinspection DuplicatedCode
export class CountedDimensions {
   private countLiteral: string;
   private countNumeric: number;
   private widthInches: number;
   private widthMilimitres: number;
   private heightInches: number;
   private heightMilimitres: number;

   private constructor() {
   }

   public static constructFromInterface(cdi: CountedDimensionsInterface): CountedDimensions {
      const r = new CountedDimensions();

      r.countLiteral = cdi.countLiteral;
      r.countNumeric = cdi.countNumeric;
      r.widthInches = cdi.widthInches;
      r.widthMilimitres = cdi.widthCentimetres;
      r.heightInches = cdi.heightInches;
      r.heightMilimitres = cdi.heightCentimetres;

      return r;
   }

   getCountLiteral(): string {
      return this.countLiteral;
   }

   getCountNumeric(): number {
      return this.countNumeric;
   }

   getWidthInches(): number {
      return this.widthInches;
   }

   getWidthCentimetres(): number {
      return this.widthMilimitres;
   }

   getHeightInches(): number {
      return this.heightInches;
   }

   getHeightCentimetres(): number {
      return this.heightMilimitres;
   }
}
