import {Component, OnInit, ViewChild} from '@angular/core';
import * as bootstrap from 'bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../service/api-client/user/user.service';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {FormCommonFunctionality} from '../form-reusable-components/form-common-functionality';
import {OnlineValidatedPasswordComponent} from '../form-reusable-components/textboxes/online-validated-password/online-validated-password.component';
import {StandardApiResponseDeprecated} from '../service/api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {environment} from '../../environments/environment';
import {DomUtils} from '../utils/dom/dom-utils';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';

@Component({
   selector: 'app-forgot-password',
   templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
   @ViewChild(OnlineValidatedEmailComponent) onlineValidatedEmailComponent: OnlineValidatedEmailComponent;
   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent;
   @ViewChild(OnlineValidatedPasswordComponent) onlineValidatedPasswordComponent: OnlineValidatedPasswordComponent;

   pageStatuses = {
      landing_with_no_token: 0,
      landing_with_token: 1,
      request_sent_success: 2,
      request_sent_failure: 3,
      password_reset_success: 4,
      password_reset_failure_obsolete_token: 5,
      password_reset_failure_unknown_error: 6
   };

   private popup: bootstrap.Modal;

   pageStatus: number;
   emailValid = false;
   passwordValid = false;
   obsoleteToken = false;

   translationSheet: TranslationSheet;
   environment = environment;

   constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private userService: UserService,
      private staticDictionary: StaticDictionaryService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {

      const forgotPasswordToken = this.activatedRoute.snapshot.paramMap.get('forgot-password-token');

      if ((forgotPasswordToken === null) || (forgotPasswordToken === undefined)) {
         this.pageStatus = this.pageStatuses.landing_with_no_token;
      } else {
         this.userService.checkResetAccountPasswordTokenIsValid(forgotPasswordToken).subscribe(
            () => {
               this.pageStatus = this.pageStatuses.landing_with_token;
            }, () => {
               this.pageStatus = this.pageStatuses.password_reset_failure_obsolete_token; // we assume
            }
         );
      }

      this.popup = new bootstrap.Modal(DomUtils.getNullSafeHtmlElementById('reset-password-account-popup'));
      this.popup.show();
   }

   cancel() {
      this.popup.hide();
      this.router.navigate([environment.frontEndRoutes.loginRoute]).then(() => {
      });
   }

   sendResetRequest() {
      this.primaryActionComponent.setBusy(true);
      FormCommonFunctionality.disableFormFieldSetById('forgot-password-fieldset');
      const email = this.onlineValidatedEmailComponent.getWellFormedEmail();
      this.userService.forgotPasswordRequest(email).subscribe(() => {
         this.pageStatus = this.pageStatuses.request_sent_success;
         this.primaryActionComponent.setBusy(false);
         FormCommonFunctionality.enableFormFieldSetById('forgot-password-fieldset');
      }, () => {
         this.pageStatus = this.pageStatuses.request_sent_failure;
         this.primaryActionComponent.setBusy(false);
         FormCommonFunctionality.enableFormFieldSetById('forgot-password-fieldset');
      });
   }

   tryAgainForgotPasswordNoToken() {
      this.pageStatus = this.pageStatuses.landing_with_no_token;
      this.emailValid = false;
   }

   closeAndBackHome() {
      this.popup.hide();
      this.router.navigate(['/']).then(() => {
      });
   }

   closeAndLogIn() {
      this.popup.hide();
      this.router.navigate([environment.frontEndRoutes.loginRoute]).then(() => {
      });
   }

   resetPassword() {
      this.obsoleteToken = false;
      const forgotPasswordToken = this.activatedRoute.snapshot.paramMap.get('forgot-password-token');
      const newPassword = this.onlineValidatedPasswordComponent.getWellFormedPassword();

      if ((forgotPasswordToken === null) || (forgotPasswordToken === undefined)) {
         this.pageStatus = this.pageStatuses.landing_with_no_token;
      } else {
         this.userService.resetForgottenPassword(newPassword, forgotPasswordToken).subscribe(
            () => {
               this.pageStatus = this.pageStatuses.password_reset_success;
            },
            (error: StandardApiResponseDeprecated) => {
               if (error.getResponseItems()[0].getCode() === environment.xstitch_api_error_codes.users.reset_password_token_obsolete) {
                  this.pageStatus = this.pageStatuses.password_reset_failure_obsolete_token;
               } else {
                  this.pageStatus = this.pageStatuses.password_reset_failure_unknown_error;
               }
            });
      }
   }
}
