export class BlobResponse {
   public constructor(private blob: Blob, private name: string) {
   }

   public getBlob(): Blob {
      return this.blob;
   }

   public getName(): string {
      return this.name;
   }
}
