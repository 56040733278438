import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectableOptionInterface} from '../interface/selectable-option-interface';
import {SelectableOptionCollection} from '../type/selectable-option-collection';

@Component({
   selector: 'app-generic-multi-select',
   templateUrl: './generic-multi-select.component.html',
   styleUrls: ['./generic-multi-select.component.scss']
})
export class GenericMultiSelectComponent implements OnInit, AfterViewInit {

   @Input() allAvailableOptions: SelectableOptionCollection;
   @Input() selectedOptions: Array<SelectableOptionInterface>;
   @Output() updateEvent: EventEmitter<Array<SelectableOptionInterface>> = new EventEmitter<Array<SelectableOptionInterface>>();

   selectedOptionsModel: Array<string>;

   constructor() {
   }

   ngOnInit(): void {
      this.setSelectedOptions(this.selectedOptions);
   }

   ngAfterViewInit(): void {

   }

   public setSelectedOptions(options: Array<SelectableOptionInterface>): void {
      this.selectedOptionsModel = [];
      this.selectedOptions = options;

      options.forEach((o: SelectableOptionInterface) => {
         this.selectedOptionsModel.push(o.getValue());
      });
   }

   multiSelectChangeEvent(): void {
      this.selectedOptions = [];
      let option: SelectableOptionInterface | null;
      this.selectedOptionsModel.forEach((value: string) => {
         option = this.allAvailableOptions.findByValue(value);
         if (option !== null) {
            this.selectedOptions.push(option);
         }
      });

      this.updateEvent.emit(this.selectedOptions);
   }
}
