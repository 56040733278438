import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectableOptionCollection} from '../../../form-reusable-components/dropdown-boxes/type/selectable-option-collection';
import {SelectableOption} from '../../../form-reusable-components/dropdown-boxes/type/selectable-option';
import {ProjectTemplateInterface} from '../../../service/api-client/search-pattern/interface/project-template-interface';
import {AbstractPalette} from '../../../service/api-client/search-pattern/type/palette/abstract-palette';
import {PaletteFactory} from '../../../service/api-client/search-pattern/type/palette/palette-factory';
import {SelectableOptionInterface} from '../../../form-reusable-components/dropdown-boxes/interface/selectable-option-interface';
import {ProjectTemplateService} from '../../../service/api-client/project-template/project-template.service';
import {StandardApiErrorResponse} from '../../../service/api-client/xstitch-api-gateway/type/standard-api-error-response';
import {PrimaryActionComponent} from '../../../form-reusable-components/buttons/primary-action/primary-action.component';
import {DMC} from '../../../service/api-client/search-pattern/type/palette/dmc';
import {Anchor} from '../../../service/api-client/search-pattern/type/palette/anchor';
import {Madeira} from '../../../service/api-client/search-pattern/type/palette/madeira';
import {CancelActionComponent} from '../../../form-reusable-components/buttons/cancel-action/cancel-action.component';
import {ModalComponent} from '../../../form-reusable-components/modal/modal/modal.component';

@Component({
   // tslint:disable-next-line:component-selector
   selector: '[app-template-row]',
   templateUrl: './template-row.component.html',
   styleUrls: ['./template-row.component.scss']
})
export class TemplateRowComponent implements OnInit {

   confirmTemplateDeleteModalId = 'confirm_template_delete_modal_id';
   @Input() template: ProjectTemplateInterface;
   @ViewChild('update_template_button') updateTemplateButton: PrimaryActionComponent;
   @ViewChild('confirmDeleteTemplatetButton') deleteTemplateButton: CancelActionComponent;
   @ViewChild('confirm_template_delete_modal_id') confirmTemplateDeleteModal: ModalComponent;
   @Output() updateError: EventEmitter<StandardApiErrorResponse> = new EventEmitter<StandardApiErrorResponse>();
   @Output() deleteTemplateSuccess: EventEmitter<void> = new EventEmitter<void>();

   constructor(private projectTemplateService: ProjectTemplateService) {
   }

   ngOnInit(): void {
   }

   getAllAvailablePalettes(): SelectableOptionCollection {
      return new SelectableOptionCollection([
         new SelectableOption('0', 'DMC'),
         new SelectableOption('1', 'Anchor'),
         new SelectableOption('2', 'Madeira')
      ]);
   }

   getPaletteCollection(template: ProjectTemplateInterface): Array<AbstractPalette> {
      return PaletteFactory.collectionFromInterfaces(template.palettes);
   }

   updatePalettes(selected: Array<SelectableOptionInterface>): void {
      this.template.palettes = [];

      selected.forEach((a: SelectableOptionInterface) => {
         this.template.palettes.push({
            id: parseInt(a.getValue(), 10),
            colorCount: 0 // Color count not actually important
         });
      });
   }

   updateTemplate(): void {
      this.updateTemplateButton.setBusy(true);
      this.projectTemplateService.updateTemplate(this.template).subscribe(() => {
         this.updateTemplateButton.setBusy(false);
      }, (e: StandardApiErrorResponse) => {
         this.updateTemplateButton.setBusy(false);
         this.updateError.emit(e);
      });
   }

   deleteTemplate(): void {
      this.deleteTemplateButton.setBusy(true);

      this.projectTemplateService.deleteTemplate(this.template.templateId).subscribe(() => {
         this.confirmTemplateDeleteModal.hide();
         this.deleteTemplateButton.setBusy(false);
         this.deleteTemplateSuccess.emit();
      }, (e: StandardApiErrorResponse) => {
         this.confirmTemplateDeleteModal.hide();
         this.deleteTemplateButton.setBusy(false);
         this.updateError.emit(e);
      });
   }
}
