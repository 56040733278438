import {UserCollectionItemResponseApiInterface} from '../interface/user-collection-response-api-interface';
import {UnknownCheckoutTypeError} from '../error/unknown-checkout-type-error';
import {CheckoutTransactionDetails} from './checkout-transaction-details';
import {DownloadPdfDetails} from './download-pdf-details';
import {AbstractPdfGenerationStatus} from './PdfGenerationStatus/abstract-pdf-generation-status';
import {PdfGenerationStatusFactory} from './PdfGenerationStatus/pdf-generation-status-factory';

export enum CheckoutType {Free, PayPal}

export class UserCollectionItem {

   public static constructFromInterface(i: UserCollectionItemResponseApiInterface): UserCollectionItem {
      let checkoutType: CheckoutType;

      if (i.checkoutType === 'free') {
         checkoutType = CheckoutType.Free;
      } else if (i.checkoutType === 'paypal') {
         checkoutType = CheckoutType.PayPal;
      } else {
         throw new UnknownCheckoutTypeError();
      }

      let downloadDetails: DownloadPdfDetails | undefined;

      if ((i.downloadDetails !== null) && (i.downloadDetails !== undefined)) {
         downloadDetails = DownloadPdfDetails.constructFromInterface(i.downloadDetails);
      }

      let generationStatus: AbstractPdfGenerationStatus | undefined;

      if ((i.generationStatus !== undefined) && (i.generationStatus !== null)) {
         generationStatus = PdfGenerationStatusFactory.constructFromInterface(i.generationStatus);
      }

      return new UserCollectionItem(
         i.projectId,
         i.templateId,
         i.originalListingUrl,
         checkoutType,
         i.templateShowcase,
         i.projectImage,
         i.title,
         // Transaction details are not actually being received from the API. Reason being, we don't want to make this
         // information readily available so users could easily track variations on the prices, which in turn could cause
         // negative criticism. The transaction information is already on their PayPal / bank account anyway. I didn't clean
         // this up in case we want to revert this, or to include other transaction-specific information
         CheckoutTransactionDetails.constructFromInterface(i.transactionDetails),
         i.downloadToken,
         downloadDetails,
         generationStatus
      );
   }

   public constructor(
      private projectId: number,
      private templateId: number,
      private originalListingUrl: string,
      private checkoutType: CheckoutType,
      private templateShowcase: string,
      private projectImage: string,
      private title: string,
      private transactionDetails: CheckoutTransactionDetails,
      private downloadToken: string,
      private downloadDetails?: DownloadPdfDetails,
      private generationStatus?: AbstractPdfGenerationStatus
   ) {
   }

   public getCheckoutType(): CheckoutType {
      return this.checkoutType;
   }

   public getUrlToOriginalListing(): string {
      return this.originalListingUrl;
   }

   public getTemplateShowCase(): string {
      return this.templateShowcase;
   }

   public getProjectImage(): string {
      return this.projectImage;
   }

   public getTitle(): string {
      return this.title;
   }

   public getTransactionDetails(): CheckoutTransactionDetails {
      return this.transactionDetails;
   }

   public getDownloadDetails(): DownloadPdfDetails | undefined {
      return this.downloadDetails;
   }

   public getGenerationStatus(): AbstractPdfGenerationStatus | undefined {
      return this.generationStatus;
   }

   public getDownloadToken(): string {
      return this.downloadToken;
   }

   public getTemplateId(): number {
      return this.templateId;
   }

   public getProjectId(): number {
      return this.projectId;
   }
}
