import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/api-client/user/user.service';
import {LoginFirstToAddFreeTemplatePopupComponent} from '../login-first-popup/login-first-to-add-free-template-popup.component';
import {PatternDetailComponent} from '../pattern-detail.component';
import {FreeCheckoutService} from '../../service/checkout/free-checkout/free-checkout.service';
import {GenericErrorModalComponent} from '../../form-reusable-components/modal/generic-error-modal/generic-error-modal.component';
import {ApiClientUtilsService} from '../../service/api-client/api-client-utils/api-client-utils.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {DomUtils} from '../../utils/dom/dom-utils';
import * as bootstrap from 'bootstrap';

@Component({
   selector: 'app-add-free-pattern',
   templateUrl: './add-free-pattern.component.html',
   styleUrls: ['./add-free-pattern.component.scss']
})
export class AddFreePatternComponent implements OnInit {

   @ViewChild(LoginFirstToAddFreeTemplatePopupComponent) logInFirstPopUp: LoginFirstToAddFreeTemplatePopupComponent;
   @ViewChild(GenericErrorModalComponent) genericerrorModalComponent: GenericErrorModalComponent;

   checkoutSuccessModalId = 'free-checkout-success';

   currentPageStatus = 0;

   addFreePatternComponentModalId = 'add-free-pattern-component-modal-id';
   genericErrorModalId = 'generic-error-modal-id';

   templateId: number;
   loggedIn: boolean;
   logInUrl: string;

   availablePageStatuses = {
      loading: 0,
      templateAdded: 1
   };

   translationSheet: TranslationSheet;

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private userLoggedIn: UserService,
      private freeCheckoutService: FreeCheckoutService,
      private apiClientUtils: ApiClientUtilsService,
      private staticDictionary: StaticDictionaryService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      const templateId = this.activatedRoute.snapshot.paramMap.get('template-id');

      if (templateId === null) {
         this.router.navigateByUrl(environment.frontEndRoutes.home).then(() => {
         });
      } else {
         this.templateId = parseInt(templateId, 10);


         this.userLoggedIn.getCurrentUser().subscribe(() => {
            this.loggedIn = true;
            this.freeCheckoutService.freeCheckoutTemplate(this.templateId).then(() => {
               this.currentPageStatus = this.availablePageStatuses.templateAdded;
               this.showSuccessModal();
            }, (error) => {
               this
                  .apiClientUtils
                  .logCodeSearchableFrontEndGenericError(
                     'RARB3EZnoPJcRpbN',
                     JSON.stringify(error, null, 2)).then(() => {
                  }
               );
               this.genericerrorModalComponent.show();
            });
         }, () => {
            this.loggedIn = false;
            this.logInUrl = PatternDetailComponent.getLogInSuccessRouteWithSuccessRedirectUrl(this.templateId);
            this.logInFirstPopUp.show();
         });
      }
   }

   showSuccessModal(): void {
      const modalComponent = DomUtils.getNullSafeHtmlElementById(this.checkoutSuccessModalId);
      const modal = new bootstrap.Modal(modalComponent);
      modal.show();
   }

   getBranding(): string {
      return environment.branding.companyName;
   }

   modalClosed(): void {

      this.currentPageStatus = this.availablePageStatuses.loading;

      this.router.navigateByUrl(environment.frontEndRoutes.accountHome).then(() => {
      });
   }
}
