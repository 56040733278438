import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';

@Component({
   selector: 'app-newsletter-dropdownbox',
   templateUrl: './newsletter-dropdownbox.component.html',
   styleUrls: ['./newsletter-dropdownbox.component.scss']
})
export class NewsletterDropdownboxComponent implements OnInit {

   translationSheet: TranslationSheet;

   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   private static getNewsLetterFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('newsletter-feedback');
   }

   private static getNewsLetterControl(): HTMLSelectElement {
      return DomUtils.getNullSafeHtmlSelectElementById('news-letter-control');
   }

   private static isNewsLetterValid(): boolean {
      const newsLetterControl = NewsletterDropdownboxComponent.getNewsLetterControl();
      const value = newsLetterControl.value;

      return value !== '';
   }

   constructor(private staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   validateNewsLetter(): void {
      const isNewsletterValid = NewsletterDropdownboxComponent.isNewsLetterValid();
      const newsLetterControl = NewsletterDropdownboxComponent.getNewsLetterControl();
      const newsLetterFeedback = NewsletterDropdownboxComponent.getNewsLetterFeedback();

      if (isNewsletterValid) {
         FormCommonFunctionality.setFormComponentValid(newsLetterControl);
         FormCommonFunctionality.showHideElement(newsLetterFeedback, false);
      } else {
         FormCommonFunctionality.setFormComponentInvalid(newsLetterControl);
         FormCommonFunctionality.showHideElement(newsLetterFeedback, true);
      }

      this.validationEvent.emit(isNewsletterValid);
   }

   getValidatedNewsletterValue(): number {

      const newsLetterControl = NewsletterDropdownboxComponent.getNewsLetterControl();

      return newsLetterControl.selectedIndex;
   }

   resetValidation(): void {
      const newsLetterControl = NewsletterDropdownboxComponent.getNewsLetterControl();
      FormCommonFunctionality.resetFormComponentValidation(newsLetterControl);
   }

   setFromApiValue(apiValue: number): void {
      const newsLetterControl = NewsletterDropdownboxComponent.getNewsLetterControl();
      switch (apiValue) {
         case 1:
            newsLetterControl.value = 'never';
            break;
         case 2:
            newsLetterControl.value = 'weekly';
            break;
         case 3:
            newsLetterControl.value = 'monthly';
            break;
         case 4:
            newsLetterControl.value = 'each-new-publication';
            break;
         default:
            newsLetterControl.value = '';
      }

      this.resetValidation();
   }
}
