import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {BasicProjectInfo} from './type/basic-project-info';
import {environment} from '../../../../environments/environment';
import {BasicProjectDataInterface} from './interface/basic-project-data-interface';
import {HttpErrorResponse} from '@angular/common/http';
import {StandardApiErrorResponse} from '../xstitch-api-gateway/type/standard-api-error-response';
import {LocalisedProjectDataInterface} from './interface/localised-project-data-interface';
import {ProjectFullInfoInterface} from './interface/project-full-info-interface';
import {PayloadNamingRepo as PNR} from '../xstitch-api-gateway/payload-naming-repo';
import {ProjectFullInfo} from './type/project-full-info';

@Injectable({
   providedIn: 'root'
})
export class ProjectService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public getProjectListForDropdownSelector(): Observable<BasicProjectInfo[]> {
      return new Observable<BasicProjectInfo[]>((s: Subscriber<BasicProjectInfo[]>) => {
         const endpoint = environment.xstitchApiEndpoints.admin.project.getProjectListForDropdownSelector;
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<BasicProjectDataInterface[]>(endpoint);

         response.subscribe((r: BasicProjectDataInterface[]) => {
            const result: BasicProjectInfo[] = [];
            r.forEach((i: BasicProjectDataInterface) => {
               result.push(BasicProjectInfo.constructFromInterface(i));
            });
            s.next(result);
         }, (error: HttpErrorResponse) => {
            s.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   public getProjectFullInfo(projectId: number): Observable<ProjectFullInfoInterface> {
      return new Observable<ProjectFullInfoInterface>((s: Subscriber<ProjectFullInfoInterface>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.project.getAndDeleteProject;
         endpoint = endpoint.replace('{projectId}', projectId.toString());

         const result = this.xstitchApiGateway.getAsJsonRelativeRoute<LocalisedProjectDataInterface>(endpoint);

         result.subscribe((r: ProjectFullInfoInterface) => {
            s.next(r);
         }, (error: HttpErrorResponse) => {
            s.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   public deleteProject(projectId: number): Observable<void> {
      return new Observable<void>((s: Subscriber<void>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.project.getAndDeleteProject;
         endpoint = endpoint.replace('{projectId}', projectId.toString());

         const response = this.xstitchApiGateway.deleteRelativeRoute(endpoint);
         response.subscribe(() => {
            s.next();
         }, (error: HttpErrorResponse) => {
            s.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   public updateProject(data: ProjectFullInfoInterface): Observable<ProjectFullInfoInterface> {
      return new Observable<ProjectFullInfoInterface>((s: Subscriber<ProjectFullInfoInterface>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.project.getAndDeleteProject;
         endpoint = endpoint.replace('{projectId}', data.projectId.toString());
         const dataAsObject = ProjectFullInfo.constructFromInterface(data);
         const payload = dataAsObject.serializeForProjectUpdate();

         const response = this.xstitchApiGateway.putWithJsonBodyRelativeRoute<ProjectFullInfoInterface>(endpoint, payload);

         response.subscribe((r: ProjectFullInfoInterface) => {
            s.next(r);
         }, (error: HttpErrorResponse) => {
            s.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });

      });
   }
}
