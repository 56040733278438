import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';

@Component({
   selector: 'app-simple-ellipsize-toggle',
   templateUrl: './simple-ellipsize-toggle.component.html',
   styleUrls: ['./simple-ellipsize-toggle.component.scss']
})
export class SimpleEllipsizeToggleComponent implements OnInit, AfterViewInit{

   private static  chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

   containerId: string;
   textId: string;
   buttonText: string;

   tranlationSheet: TranslationSheet;

   private static randomString(length): string {
      let result = '';
      for (let i = length; i > 0; --i) {
         result += SimpleEllipsizeToggleComponent.chars[Math.floor(Math.random() * SimpleEllipsizeToggleComponent.chars.length)];
      }
      return result;
   }

   constructor(private staticDictionary: StaticDictionaryService) {
      this.containerId = SimpleEllipsizeToggleComponent.randomString(15);
      this.textId = SimpleEllipsizeToggleComponent.randomString(15);
      this.tranlationSheet = staticDictionary.getTranslationSheet();
      this.buttonText = this.tranlationSheet.ellipsizedToggleComponent.readMore;
   }

   ngOnInit(): void {
   }

   ngAfterViewInit(): void {
      this.toggleClip();
   }

   toggleClip(): void {
      const container = document.getElementById(this.containerId);
      const text = document.getElementById(this.textId);

      if (container === null || container === undefined || text === null || text === undefined) {
         return;
      }

      if (container.classList.contains('clipped')) {
         container.classList.remove('clipped');
         text.classList.remove('clipped');
         text.style.height = 'auto';
         container.style.height = text.clientHeight.toString() + 'px';
         this.buttonText = this.tranlationSheet.ellipsizedToggleComponent.readLess;
      } else {
         container.classList.add('clipped');

         container.style.height = '50px';

         this.buttonText = this.tranlationSheet.ellipsizedToggleComponent.readMore;

         setTimeout(() => {
            text.classList.add('clipped');
            text.style.height = '50px';
         }, 600);
      }
   }
}
