import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a4-social-media',
  templateUrl: './c10a4-social-media.component.html'
})
export class C10a4SocialMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
