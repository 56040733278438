import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AdminProjectTag} from '../../../service/api-client/project-tag/type/project-tag/admin-project-tag';
import {ProjectTagService} from '../../../service/api-client/project-tag/project-tag.service';
import {PrimaryActionComponent} from '../../../form-reusable-components/buttons/primary-action/primary-action.component';
import {ModalComponent} from '../../../form-reusable-components/modal/modal/modal.component';

@Component({
   // As seen in https://medium.com/javascript-everyday/when-to-use-an-attribute-selector-for-angular-components-7e788ba1bfe7
   // Observe that the selector name is enclosed in square brackets. This technique seems to be deprecated
   // tslint:disable-next-line:component-selector
   selector: '[app-tag-row]',
   templateUrl: './tag-row.component.html',
   styleUrls: ['./tag-row.component.scss']
})
export class TagRowComponent implements OnInit {

   @Input() tagInfo: AdminProjectTag | null;
   @Input() allTags: AdminProjectTag[];
   @Output() updateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   @ViewChild(PrimaryActionComponent) actionButton: PrimaryActionComponent; // Can be either "create" or "update"
   @ViewChild(ModalComponent) confirmDeletionModal: ModalComponent;
   @ViewChild('confirmDeleteButton') confirmDeleteButton: PrimaryActionComponent;

   id: number;
   titleEnglish: string;
   titleSpanish: string;
   titleFrench: string;
   cssClass: string;
   showHomepage = false;
   homeImgLocation: string | null;
   parentId: any | null;
   projectsRelatedTag = 0;

   constructor(private projectTagService: ProjectTagService) {
   }

   ngOnInit(): void {
      if (this.tagInfo) {
         this.id = this.tagInfo.getId();
         this.titleEnglish = this.tagInfo.getDescriptionEnglish();
         this.titleSpanish = this.tagInfo.getDescriptionSpanish();
         this.titleFrench = this.tagInfo.getDescriptionFrench();
         this.cssClass = this.tagInfo.getCssClass();
         this.showHomepage = this.tagInfo.isShowHomePage();
         this.homeImgLocation = this.tagInfo.getHomePageImg();
         this.parentId = this.tagInfo.getParentId();
         this.projectsRelatedTag = this.tagInfo.getProjectsRelatedTag();
      }
   }

   updateTag(): void {
      this.actionButton.setBusy(true);

      let parentIdSanitized = null;

      if (this.parentId) {
         parentIdSanitized = parseInt(this.parentId, 10);
      }

      this.projectTagService.updateTag(
         this.id,
         this.titleEnglish,
         this.titleSpanish,
         this.titleFrench,
         this.cssClass,
         this.showHomepage,
         this.homeImgLocation,
         parentIdSanitized
      ).subscribe(() => {
         this.actionButton.setBusy(false);
         this.updateEvent.emit(true);
      }, () => {
         this.actionButton.setBusy(false);
         this.updateEvent.emit(false);
      });
   }

   createTag(): void {
      this.actionButton.setBusy(true);

      let parentIdSanitized = null;

      if (this.parentId) {
         parentIdSanitized = parseInt(this.parentId, 10);
      }

      this.projectTagService.createTag(
         this.titleEnglish,
         this.titleSpanish,
         this.titleFrench,
         this.cssClass,
         this.showHomepage,
         this.homeImgLocation,
         parentIdSanitized
      ).subscribe(() => {
         this.actionButton.setBusy(false);
         this.updateEvent.emit(true);
      }, () => {
         this.actionButton.setBusy(false);
         this.updateEvent.emit(false);
      });
   }

   openConfirmForDeleteModal(): void {
      this.confirmDeletionModal.show();
   }

   deleteTag(): void {
      this.confirmDeleteButton.setBusy(true);

      this.projectTagService.deleteTag(this.tagInfo.getId()).subscribe(() => {
         this.confirmDeletionModal.hide();
         this.confirmDeleteButton.setBusy(false);
         this.updateEvent.emit(true);
      }, () => {
         this.confirmDeleteButton.setBusy(false);
      });
   }

}
