import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c04-legitimate-use',
  templateUrl: './c04-legitimate-use.component.html'
})
export class C04LegitimateUseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
