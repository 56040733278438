import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as bootstrap from 'bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {OnlineValidatedPasswordComponent} from '../form-reusable-components/textboxes/online-validated-password/online-validated-password.component';
import {UserService} from '../service/api-client/user/user.service';
import {FormCommonFunctionality} from '../form-reusable-components/form-common-functionality';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {StandardApiResponseDeprecated} from '../service/api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {environment} from '../../environments/environment';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../utils/dom/dom-utils';
import {StandardApiErrorResponse} from '../service/api-client/xstitch-api-gateway/type/standard-api-error-response';

@Component({
   selector: 'app-log-in-page',
   templateUrl: './log-in-page.component.html'
})
export class LogInPageComponent implements OnInit, OnChanges {
   @ViewChild(OnlineValidatedEmailComponent) onlineValidatedEmailComponent: OnlineValidatedEmailComponent;
   @ViewChild(OnlineValidatedPasswordComponent) onlineValidatedPasswordComponent: OnlineValidatedPasswordComponent;
   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent;

   resend = false;
   incorrectCredentials = false;
   unknownError = false;
   emailValidated = false;
   passwordValidated = false;
   translationSheet: TranslationSheet;
   registerRoute = environment.frontEndRoutes.registerRoute;

   private logInModal: bootstrap.Modal;

   constructor(
      private router: Router,
      private userService: UserService,
      public dictionary: StaticDictionaryService,
      private activatedRoute: ActivatedRoute
   ) {
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.logInModal = new bootstrap.Modal(DomUtils.getNullSafeHtmlElementById('log-in-modal-toggle'));
      this.logInModal.show();
   }

   ngOnChanges(changes: SimpleChanges): void {
      this.primaryActionComponent.disabled = !(this.emailValidated && this.passwordValidated);
   }

   cancelLogIn(): void {

      const cancelRedirect = this.activatedRoute.snapshot.queryParamMap.get(environment.queryStringParameterNames.cancelRedirect);

      if ((cancelRedirect === null) || (cancelRedirect === undefined) || (cancelRedirect === '')) {
         this.router.navigate(['/']).then(() => {
            this.logInModal.hide();
         });
      } else {
         this.router.navigate([cancelRedirect]).then(() => {
         });
      }
   }

   resetPasswordAccount(): void {
      this.router.navigate(['/account/forgot-account-password']).then(() => {
         this.logInModal.hide();
      });
   }

   redirectToCookiePolicy(): void {
      this.router.navigate(['/legal/cookie-policy']).then(() => {
         this.logInModal.hide();
      });
   }

   redirectToRegisterPage(): void {
      this.router.navigate([this.registerRoute]).then(() => {
         this.logInModal.hide();
      });
   }

   setEmailValidated(validated: boolean) {
      this.emailValidated = validated;
   }

   setPasswordValidated(validated: boolean) {
      this.passwordValidated = validated;
   }

   async logIn() {

      this.primaryActionComponent.setBusy(true);
      FormCommonFunctionality.disableFormFieldSetById('log-in-form-fieldset');

      this.userService.logIn(
         this.onlineValidatedEmailComponent.getWellFormedEmail(),
         this.onlineValidatedPasswordComponent.getWellFormedPassword()
      ).subscribe(() => {
         FormCommonFunctionality.enableFormFieldSetById('log-in-form-fieldset');
         this.primaryActionComponent.setBusy(false);
         this.logInModal.hide();

         const successRedirect = this.activatedRoute.snapshot.queryParamMap.get(environment.queryStringParameterNames.successRedirect);

         if ((successRedirect === null) || (successRedirect === undefined) || (successRedirect === '')) {
            this.router.navigate([environment.frontEndRoutes.accountHome]).then(() => {
            });
         } else {
            this.router.navigate([successRedirect]).then(() => {
            });
         }

      }, (error: StandardApiErrorResponse) => {
         FormCommonFunctionality.enableFormFieldSetById('log-in-form-fieldset');
         this.primaryActionComponent.setBusy(false);

         const errorCode = error.getCode();

         switch (errorCode) {
            case environment.xstitch_api_error_codes.users.must_verify_account_before_log_in:
               this.resend = true;
               this.incorrectCredentials = false;
               this.unknownError = false;
               return;
            case environment.xstitch_api_error_codes.users.incorrect_username_or_password:
               this.incorrectCredentials = true;
               this.resend = false;
               this.unknownError = false;
               return;
            default:
               this.unknownError = true;
         }
      });
   }

   resendAccountVerificationEmail() {
      this.logInModal.hide();
      this.router.navigate(['user/account/resend-verification-email']).then(() => {
      });
   }
}
