import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c02-a2-when-you-register-for-our-services',
  templateUrl: './c02-a2-when-you-register-for-our-services.component.html'
})
export class C02A2WhenYouRegisterForOurServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
