import {ShoppingCartItem} from './shopping-cart-item';

export class LocalBrowserShoppingCartContainer {
   private static readonly ITEMS_ENTRY = 'i';

   private activeItemsIndexedByProjectAndTemplate: Map<string, ShoppingCartItem>;
   private inactiveItemsIndexedByProjectAndTemplate: Map<string, ShoppingCartItem>;

   private static computeItemFullIndexFromItem(item: ShoppingCartItem): string {
      const projectId = item.getProjectId();
      const templateId = item.getTemplateId();
      return LocalBrowserShoppingCartContainer.computeItemFullIndexFromIds(projectId, templateId);
   }

   private static computeItemFullIndexFromIds(projectId: number, templateId: number): string {
      return projectId.toString() + '-' + templateId.toString();
   }

   private static computeItemPartialIndexFromProjectId(projectId: number): string {
      return projectId.toString() + '-';
   }

   private static computeItemPartialIndexFromTemplateId(templateId: number): string {
      return '-' + templateId.toString();
   }

   static unserialize(serialized: string): LocalBrowserShoppingCartContainer {
      const result = new LocalBrowserShoppingCartContainer();

      const unserialized = JSON.parse(serialized);

      if (!((unserialized === null) || (unserialized === undefined))) {

         const items = unserialized.i;

         items.forEach((object) => {
            const itm = ShoppingCartItem.unserialize(object);

            if ((itm !== null) && (itm !== undefined)) {
               result.addItem(itm);
            }
         });
      }

      return result;
   }

   constructor() {
      this.activeItemsIndexedByProjectAndTemplate = new Map<string, ShoppingCartItem>();
      this.inactiveItemsIndexedByProjectAndTemplate = new Map<string, ShoppingCartItem>();
   }

   getItemsByProjectId(projectId: number): Array<ShoppingCartItem> {
      const result: Array<ShoppingCartItem> = [];
      const index = LocalBrowserShoppingCartContainer.computeItemPartialIndexFromProjectId(projectId);

      this.activeItemsIndexedByProjectAndTemplate.forEach((value: ShoppingCartItem, key: string) => {
         if (key.includes(index)) {
            result.push(value);
         }
      });

      return result;
   }

   getItemByIds(projectId: number, templateId: number): ShoppingCartItem | null {
      const index = LocalBrowserShoppingCartContainer.computeItemFullIndexFromIds(projectId, templateId);

      if (this.activeItemsIndexedByProjectAndTemplate.has(index)) {
         const result = this.activeItemsIndexedByProjectAndTemplate.get(index);
         if ((result === null) || (result === undefined)) {
            return null;
         } else {
            return result;
         }
      }

      return null;
   }

   addItem(item: ShoppingCartItem): void {
      const index = LocalBrowserShoppingCartContainer.computeItemFullIndexFromItem(item);
      const isInactive = this.inactiveItemsIndexedByProjectAndTemplate.has(index);
      const isActive = this.activeItemsIndexedByProjectAndTemplate.has(index);

      if (!isInactive && !isActive) {
         item.setNotDeleted();
         this.activeItemsIndexedByProjectAndTemplate.set(index, item);
      } else if (!isInactive && isActive) {
         const itm = this.inactiveItemsIndexedByProjectAndTemplate.get(index);
         if (!((itm === null) || (itm === undefined))) {
            // Not actually needed, but just in case
            itm.setNotDeleted();
         }
      } else if (isInactive && !isActive) {
         const itm = this.inactiveItemsIndexedByProjectAndTemplate.get(index);
         if (!((itm === null) || (itm === undefined))) {
            this.activeItemsIndexedByProjectAndTemplate.set(index, itm);
         }
         this.inactiveItemsIndexedByProjectAndTemplate.delete(index);
      } else if (isInactive && isActive) {
         // Should not really happen.
         const itm = this.activeItemsIndexedByProjectAndTemplate.get(index);
         if (!((itm === null) || (itm === undefined))) {
            itm.setNotDeleted();
         }
         this.inactiveItemsIndexedByProjectAndTemplate.delete(index);
      }
   }

   /**
    * We don't physically remove the item from the collection, we just 'soft delete' it by flagging it as 'deleted'. The
    * reason is, there may be user notes associated to it that we don't want to lose.
    */
   removeItemByIds(projectId: number, templateId: number): void {
      const index = LocalBrowserShoppingCartContainer.computeItemFullIndexFromIds(projectId, templateId);
      const isInactive = this.inactiveItemsIndexedByProjectAndTemplate.has(index);
      const isActive = this.activeItemsIndexedByProjectAndTemplate.has(index);

      if (!isInactive && isActive) {
         const itm = this.activeItemsIndexedByProjectAndTemplate.get(index);
         if (!((itm === null) || (itm === undefined))) {
            itm.setDeleted();
            this.inactiveItemsIndexedByProjectAndTemplate.set(index, itm);
         }
         this.activeItemsIndexedByProjectAndTemplate.delete(index);
      } else if (isInactive && !isActive) {
         const itm = this.inactiveItemsIndexedByProjectAndTemplate.get(index);
         // Not needed, but just in case
         if (!((itm === null) || (itm === undefined))) {
            itm.setDeleted();
         }
      } else if (isInactive && isActive) {
         // Should not really happen.
         const itm = this.inactiveItemsIndexedByProjectAndTemplate.get(index);
         if (!((itm === null) || (itm === undefined))) {
            itm.setDeleted();
         }
         this.activeItemsIndexedByProjectAndTemplate.delete(index);
      }
   }

   removeItemsByProjectId(projectId: number): void {
      const associatedTemplates = this.getItemsByProjectId(projectId);
      associatedTemplates.forEach((itm: ShoppingCartItem) => {
         this.removeItemByIds(itm.getProjectId(), itm.getTemplateId());
      });
   }

   serialize(): object {
      const result = {};

      result[LocalBrowserShoppingCartContainer.ITEMS_ENTRY] = [];

      this.activeItemsIndexedByProjectAndTemplate.forEach((v: ShoppingCartItem) => {
         result[LocalBrowserShoppingCartContainer.ITEMS_ENTRY].push(v.serialize());
      });

      return result;
   }

   countActiveItems(): number {
      return this.activeItemsIndexedByProjectAndTemplate.size;
   }

   getActiveItems(): ShoppingCartItem[] {

      const result: ShoppingCartItem[] = [];

      this.activeItemsIndexedByProjectAndTemplate.forEach((item: ShoppingCartItem) => {
         result.push(item);
      });

      return result;
   }
}
