export class ShoppingCartItem {
   private static readonly projectIdEntry = 'p';
   private static readonly templateIdEntry = 't';
   private static readonly itemQuantityEntry = 'q';
   private static readonly itemNotesEntry = 'n';
   private static readonly itemDeletedEntry = 'd';

   private readonly projectId: number;
   private readonly templateId: number;
   private itemQuantity: number;
   private itemNotes: string;
   private itemDeleted: boolean;

   static unserialize(serialized: object): ShoppingCartItem | null {

      const projectId = parseInt(serialized[ShoppingCartItem.projectIdEntry], 10);
      const templateId = parseInt(serialized[ShoppingCartItem.templateIdEntry], 10);
      const itemQuantity = parseInt(serialized[ShoppingCartItem.itemQuantityEntry], 10);
      const itemNotes = serialized[ShoppingCartItem.itemNotesEntry];
      const itemDeleted = serialized[ShoppingCartItem.itemDeletedEntry];

      if ((projectId > 0) && (templateId > 0) && (itemQuantity > 0)) {
         let sanitizedNotes;
         let sanitizedDeletedFlag;

         if (itemNotes === null || itemNotes === undefined) {
            sanitizedNotes = '';
         } else {
            sanitizedNotes = itemNotes.trim();
         }

         if (itemDeleted === true || itemDeleted === false) {
            sanitizedDeletedFlag = itemDeleted;
         } else {
            sanitizedDeletedFlag = false;
         }

         return new ShoppingCartItem(projectId, templateId, itemQuantity, sanitizedNotes, sanitizedDeletedFlag);
      }

      return null;
   }

   constructor(projectId: number, templateId: number, itemQuantity: number, itemNotes: string, deleted: boolean) {
      this.projectId = projectId;
      this.templateId = templateId;
      this.itemQuantity = itemQuantity;
      this.itemNotes = itemNotes;
      this.itemDeleted = deleted;
   }

   serialize(): object {
      const result = {};

      result[ShoppingCartItem.projectIdEntry] = this.projectId;
      result[ShoppingCartItem.templateIdEntry] = this.templateId;
      result[ShoppingCartItem.itemQuantityEntry] = this.itemQuantity;
      result[ShoppingCartItem.itemNotesEntry] = this.itemNotes;
      result[ShoppingCartItem.itemDeletedEntry] = this.itemDeleted;

      return result;
   }

   changeQuantity(quantity: number): void {
      this.itemQuantity = quantity;
   }

   setNotes(notes: string): void {
      this.itemNotes = notes;
   }

   getNotes(): string | null {
      const cleaned = (this.itemNotes + '').trim();
      if (cleaned === '') {
         return null;
      }
      return cleaned;
   }

   getProjectId(): number {
      return this.projectId;
   }

   getTemplateId(): number {
      return this.templateId;
   }

   isDeleted(): boolean {
      return this.itemDeleted;
   }

   setDeleted(): void {
      this.itemDeleted = true;
   }

   setNotDeleted(): void {
      this.itemDeleted = false;
   }
}
