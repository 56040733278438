import {UserApiInterface} from '../interface/user-api-interface';

export class User {

   private readonly userId: number;
   private readonly userName: string;
   private readonly newsletterFrequencyId: number;
   private readonly isAdmin: boolean;

   public constructor(userId: number, userName: string, newsletterFrequencyId: number, isAdmin: boolean) {
      this.userId = userId;
      this.userName = userName;
      this.newsletterFrequencyId = newsletterFrequencyId;
      this.isAdmin = isAdmin;
   }

   public static constructFromInterface(i: UserApiInterface): User {
      return new User(i.user_id, i.user_name, i.newsletter_frequency_id, i.isAdmin);
   }

   public getUserName(): string {
      return this.userName;
   }

   public getNewsletterFrequencyId(): number {
      return this.newsletterFrequencyId;
   }

   public checkIsAdmin(): boolean {
      return this.isAdmin;
   }
}
