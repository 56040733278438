import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../service/api-client/user/user.service';
import {UserCollection} from '../../service/api-client/user/type/user-collection';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {CommonSearchBarComponent} from '../../top-navigation-bar/common-search-bar/common-search-bar.component';
import {SearchRequest} from '../../service/api-client/search-pattern/type/search-request';
import {PrimaryActionComponent} from '../../form-reusable-components/buttons/primary-action/primary-action.component';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-collection',
   templateUrl: './collection.component.html',
   styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

   @ViewChild('generic_search_button') primaryActionComponent: PrimaryActionComponent;

   @Input() userCollection: UserCollection | undefined = undefined;

   private initialized = false;

   public translationSheet: TranslationSheet;

   constructor(private userService: UserService, staticDictionary: StaticDictionaryService, private router: Router) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      if (!this.initialized && (this.userCollection !== undefined)) {
         this.initialized = true;
      }
   }

   genericSearch(): void {
      this.primaryActionComponent.setBusy(true);
      const searchRequest = new SearchRequest([], [], []);
      const route = environment.frontEndRoutes.searchResults + '?' + searchRequest.serializeAsQueryString();
      this.router.navigateByUrl(route).then(() => {
         CommonSearchBarComponent.searchRequestParametersSubject.next(new SearchRequest([], [], []));
         this.primaryActionComponent.setBusy(false);
      });
   }
}
