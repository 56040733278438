import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a7-security-of-our-websites-and-apps',
  templateUrl: './c10a7-security-of-our-websites-and-apps.component.html'
})
export class C10a7SecurityOfOurWebsitesAndAppsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
