import {Component, OnInit} from '@angular/core';
import {SearchResultsComponent} from '../search-results.component';
import {environment} from '../../../environments/environment';
import {PatternSearchResults} from '../../service/api-client/search-pattern/type/pattern-search-results';
import {ProjectFullInfo} from '../../service/api-client/project/type/project-full-info';
import {PatternFavouritesContainer} from '../../service/favourites-service/type/pattern-favourites-container';
import {Observable} from 'rxjs';
import {SharingPlatforms} from '../../service/api-client/search-pattern/type/sharing-platforms';

@Component({
   selector: 'app-revisit-search-id',
   templateUrl: '../search-results.component.html',
   styleUrls: ['../search-results.component.scss']
})
export class RevisitSearchIdComponent extends SearchResultsComponent implements OnInit {

   ngOnInit(): void {
      this.pageStatus = this.pageStatuses.loading;

      const managingToken = this.activatedRoute.snapshot.paramMap.get(environment.frontEndRoutesPathParameters.managingToken);
      const revisitType = this.activatedRoute.snapshot.paramMap.get(environment.frontEndRoutesPathParameters.revisitType);

      if (managingToken === null || revisitType === null) {
         this.redirectHome();
         return;
      }

      let call: Observable<PatternSearchResults>;

      if (revisitType === 'email') {
         call = this.searchPatternService.revisitSearchEmail(managingToken, SharingPlatforms.email);
      } else if (revisitType === 'social-media') {
         call = this.searchPatternService.revisitSearchEmail(managingToken, SharingPlatforms.facebook);
      } else {
         throw new Error('Not imlemented');
      }


      call.subscribe((searchResultObj: PatternSearchResults) => {

         SearchResultsComponent.resetContainer();
         this.searchResultItems = [];

         this.searchResultObject = searchResultObj;

         searchResultObj.getPatternsFound().forEach((a: ProjectFullInfo) => {
            this.searchResultItems.push(a);
         });

         this.initBricksInstance();

         if (this.searchResultItems.length === 0) {
            this.pageStatus = this.pageStatuses.emptySearch;
         } else {
            this.pageStatus = this.pageStatuses.ok;
         }

         this.bussy = false;
      }, () => {
         this.redirectHome();
      });

      this.userFavouritesList = null;

      this.favouritesManagerService.getCurrentUserFavouritesList().subscribe((favouritesList: PatternFavouritesContainer) => {
         this.userFavouritesList = favouritesList;
      });
   }

   private redirectHome(): void {
      this.router.navigate([environment.frontEndRoutes.home]).then(() => {
      });
   }
}
