import {Component, Input} from '@angular/core';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import * as bootstrap from 'bootstrap';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {DomUtils} from '../../../utils/dom/dom-utils';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-abstract-self-contained-modal',
  templateUrl: './abstract-self-contained-modal.component.html',
  styleUrls: ['./abstract-self-contained-modal.component.scss']
})
export class AbstractSelfContainedModalComponent {

   environment = environment;

   @Input() id: string;
   @Input() title: string;

   translationSheet: TranslationSheet;

   modalInstance: bootstrap.Modal | null = null;

   constructor(public staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   private static initializePopup(htmlElementId: string, modalReference: bootstrap.Modal | null): bootstrap.Modal | null {

      const element = DomUtils.getHtmlElementById(htmlElementId);

      if ((modalReference === null) && (element !== null)) {
         return new bootstrap.Modal(element, {keyboard: false});
      }

      return modalReference;
   }

   public show(): void {
      if (this.modalInstance === null) {
         this.modalInstance = AbstractSelfContainedModalComponent.initializePopup(
            this.id,
            this.modalInstance
         );
      }

      this.modalInstance?.show();
   }

   public hide(): void {
      this.modalInstance?.hide();
   }

}
