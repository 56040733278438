import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {ActivatedRoute, Router} from '@angular/router';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {EventLoggerService} from '../../service/api-client/events/event-logger.service';
import {environment} from '../../../environments/environment';
import {PrimaryActionComponent} from '../../form-reusable-components/buttons/primary-action/primary-action.component';

@Component({
   selector: 'app-resubscribe-email-share',
   templateUrl: './resubscribe-email-share.component.html',
   styleUrls: ['./resubscribe-email-share.component.scss']
})
export class ResubscribeEmailShareComponent implements OnInit {

   @ViewChild('notify_resubscription') buttonResubscription: PrimaryActionComponent;

   pageStatuses = {
      landing: 0,
      resubscribed: 1
   };

   pageStatus: number;
   translationSheet: TranslationSheet;

   environment = environment;

   constructor(
      public router: Router,
      public dictionary: StaticDictionaryService,
      private eventLoggerService: EventLoggerService,
      private activatedRoute: ActivatedRoute
   ) {
      this.pageStatus = this.pageStatuses.landing;
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   notifyResubscription(): void {
      this.buttonResubscription.setBusy(true);

      const managingToken = this.activatedRoute.snapshot.paramMap.get(environment.frontEndRoutesPathParameters.managingToken);

      if (
         (managingToken === null) ||
         (managingToken === undefined)
      ) {
         this.pageStatus = this.pageStatuses.resubscribed;
         return;
      }

      const observable = this.eventLoggerService.resubscribeEmailShare(managingToken);

      observable.subscribe(
         () => {
            this.buttonResubscription.setBusy(false);
            this.pageStatus = this.pageStatuses.resubscribed;
         }, () => {
            this.buttonResubscription.setBusy(false);
            this.pageStatus = this.pageStatuses.resubscribed;
         }, () => {
            this.buttonResubscription.setBusy(false);
            this.pageStatus = this.pageStatuses.resubscribed;
         });

   }
}
