import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {EventLoggerService} from '../../service/api-client/events/event-logger.service';
import {PrimaryActionComponent} from '../../form-reusable-components/buttons/primary-action/primary-action.component';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-unsubscribe-email-share',
   templateUrl: './unsubscribe-email-share.component.html',
   styleUrls: ['./unsubscribe-email-share.component.scss']
})
export class UnsubscribeEmailShareComponent implements OnInit {

   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent;

   pageStatuses = {
      landing: 0,
      cancelled: 1
   };

   pageStatus: number;
   translationSheet: TranslationSheet;

   environment = environment;

   constructor(
      public router: Router,
      public dictionary: StaticDictionaryService,
      private eventLoggerService: EventLoggerService,
      private activatedRoute: ActivatedRoute
   ) {
      this.pageStatus = this.pageStatuses.landing;
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   notifyCancellation(): void {
      this.primaryActionComponent.setBusy(true);

      const managingToken = this.activatedRoute.snapshot.paramMap.get(environment.frontEndRoutesPathParameters.managingToken);

      if (
         (managingToken === null) ||
         (managingToken === undefined)
      ) {
         this.pageStatus = this.pageStatuses.cancelled;
         return;
      }

      const observable = this.eventLoggerService.unsubscribeEmailShare(managingToken);

      observable.subscribe(
         () => {
            this.primaryActionComponent.setBusy(false);
            this.pageStatus = this.pageStatuses.cancelled;
         }, () => {
            this.primaryActionComponent.setBusy(false);
            this.pageStatus = this.pageStatuses.cancelled;
         }, () => {
            this.primaryActionComponent.setBusy(false);
            this.pageStatus = this.pageStatuses.cancelled;
         });

   }
}
