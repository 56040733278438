import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a-third-party-types',
  templateUrl: './c10a-third-party-types.component.html'
})
export class C10aThirdPartyTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
