import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c11-a3-managing-your-cookie-preferences',
  templateUrl: './c11-a3-managing-your-cookie-preferences.component.html'
})
export class C11A3ManagingYourCookiePreferencesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
