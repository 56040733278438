import {AfterViewChecked, Component, OnInit} from '@angular/core';
import * as bootstrap from 'bootstrap';
import {ShoppingCartStorageService} from '../../service/shopping-cart/shopping-cart-storage.service';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {LanguageDetectorService} from '../../service/locale/language-detector/language-detector.service';
import {Spanish} from '../../service/locale/language-detector/type/spanish';
import {French} from '../../service/locale/language-detector/type/french';
import {English} from '../../service/locale/language-detector/type/english';
import {DomUtils} from '../../utils/dom/dom-utils';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/api-client/user/user.service';
import {Router} from '@angular/router';
import {AbstractLanguage} from '../../service/locale/language-detector/type/abstract-language';

@Component({
   selector: 'app-common-nav-bar-right',
   templateUrl: './common-nav-bar-right.component.html',
   styleUrls: ['./common-nav-bar-right.component.scss']
})
export class CommonNavBarRightComponent implements OnInit, AfterViewChecked {

   shoppingCartRoute = environment.frontEndRoutes.shoppingCartRoute;
   loginRoute = environment.frontEndRoutes.loginRoute;
   translationSheet: TranslationSheet;
   languagePopupModal: bootstrap.Modal | null = null;
   languageModalId = 'language-modal-id';
   beforeLanguageModalTranslationSheet: TranslationSheet;
   beforeLanguageModalLanguage: AbstractLanguage;

   public static updateShoppingCartCount(items: number): void {
      const cartElement = DomUtils.getNullSafeHtmlElementById('shopping-cart-count');
      if (items > 10) {
         cartElement.innerText = '9+';
      } else {
         cartElement.innerText = items.toString();
      }
   }

   constructor(
      private shoppingCartStorage: ShoppingCartStorageService,
      private staticDictionary: StaticDictionaryService,
      private languageDetector: LanguageDetectorService,
      private userServices: UserService,
      private router: Router
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
      this.beforeLanguageModalTranslationSheet = staticDictionary.getTranslationSheet();
      this.beforeLanguageModalLanguage = staticDictionary.getLanguage();
   }

   ngOnInit(): void {
      const shoppingCart = this.shoppingCartStorage.readFromStorage();
      CommonNavBarRightComponent.updateShoppingCartCount(shoppingCart.countActiveItems());

   }

   ngAfterViewChecked(): void {
      this.initializeLanguageModal();
   }

   private initializeLanguageModal(): void {
      const element = DomUtils.getHtmlElementById(this.languageModalId);
      if ((this.languagePopupModal === null) && (element !== null)) {
         this.languagePopupModal = new bootstrap.Modal(element, {keyboard: false});
      }
   }

   closeModal() {
      // The user cancelled the language selection, so let's leave everything as it was

      if (this.languagePopupModal !== null) {

         const chkSpanish = document.getElementById('chk-spanish') as HTMLFormElement;
         const chkFrench = document.getElementById('chk-french') as HTMLFormElement;
         const chkEnglish = document.getElementById('chk-english') as HTMLFormElement;

         if (this.isSpanish()) {
            chkSpanish.checked = true;
         } else if (this.isFrench()) {
            chkFrench.checked = true;
         } else {
            chkEnglish.checked = true;
         }

         this.languagePopupModal.hide();
      }

      this.translationSheet = this.beforeLanguageModalTranslationSheet;
   }

   changeLanguagePopup() {
      if (this.languagePopupModal !== null) {
         this.languagePopupModal.show();
      }
   }

   getFlagClass(): string {
      const lan = this.languageDetector.getLanguage();

      const baseClass = 'nav-flag';

      if (lan instanceof Spanish) {
         return baseClass + ' ' + 'nav-flag-es';
      } else if (lan instanceof French) {
         return baseClass + ' ' + 'nav-flag-fr';
      }

      return baseClass + ' ' + 'nav-flag-gb';
   }

   isSpanish(): boolean {
      // const lan = this.languageDetector.getLanguage();

      // return lan instanceof Spanish;
      return this.beforeLanguageModalLanguage instanceof Spanish;
   }

   isFrench(): boolean {
      // const lan = this.languageDetector.getLanguage();

      // return lan instanceof French;
      return this.beforeLanguageModalLanguage instanceof French;
   }

   isEnglish(): boolean {
      return !(this.isFrench() || this.isSpanish());
   }

   changeLanguage(): void {
      const chkSpanish = document.getElementById('chk-spanish') as HTMLFormElement;
      const chkFrench = document.getElementById('chk-french') as HTMLFormElement;

      if (chkSpanish.checked) {
         this.languageDetector.setLanguage(new Spanish());
      } else if (chkFrench.checked) {
         this.languageDetector.setLanguage(new French());
      } else {
         this.languageDetector.setLanguage(new English());
      }

      window.location.reload();
   }

   changeLanguageTemporarily(): void {
      const chkSpanish = document.getElementById('chk-spanish') as HTMLFormElement;
      const chkFrench = document.getElementById('chk-french') as HTMLFormElement;

      if (chkSpanish.checked) {
         this.translationSheet = this.staticDictionary.getSpanishTranslationSheet();
      } else if (chkFrench.checked) {
         this.translationSheet = this.staticDictionary.getFrenchTranslationSheet();
      } else {
         this.translationSheet = this.staticDictionary.getEnglishTranslationSheet();
      }
   }

   goUserDashboard(): void {
      this.userServices.getCurrentUser().subscribe(() => {
         this.router.navigateByUrl(environment.frontEndRoutes.accountHome).then();
      }, () => {
         this.router.navigateByUrl(this.loginRoute).then();
      });
   }
}
