import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {DeadlineCalculatorResponseApi} from './object/deadline-calculator-response-api';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {HttpResponse} from '@angular/common/http';

@Injectable({
   providedIn: 'root'
})
export class CalculatorsService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   calculateWorkload(
      templateId: number,
      stitchCount: number,
      stitchesPerHour: number,
      hoursPerDay: number,
      daysPerWeek: number
   ): Observable<DeadlineCalculatorResponseApi> {
      const endpoint =
         environment.xstitchApiEndpoints.calculators.time_schedule_calculator +
         '?template_id=' + templateId +
         '&pattern_full_stitches_count=' + stitchCount.toString() +
         '&stitches_per_hour=' + stitchesPerHour.toString() +
         '&working_hours_per_day=' + hoursPerDay.toString() +
         '&working_days_per_week=' + daysPerWeek.toString()
      ;

      return new Observable<DeadlineCalculatorResponseApi>((subscriber: Subscriber<DeadlineCalculatorResponseApi>) => {

         const response = this.xstitchApiGateway.getDeprecated(endpoint);
         return response.subscribe((observer2: HttpResponse<any>) => {
            subscriber.next(observer2.body as DeadlineCalculatorResponseApi);
         }, error => {
            subscriber.error(error);
         });
      });
   }

   logDimensionsUsed(templateId: number, lengthUnitsId: number, clothCount: number): void {
      const endpoint = environment.xstitchApiEndpoints.events.dimensionsCalculatorUse;
      const payload = new Map<string, any>();

      payload.set('templateId', templateId);
      payload.set('lengthUnitsId', lengthUnitsId);
      payload.set('clothCount', clothCount);

      this.xstitchApiGateway.postWithJsonBodyDeprecated(endpoint, payload).subscribe();
   }
}
