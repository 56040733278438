import {Component, OnInit} from '@angular/core';
import {ApiClientUtilsService} from '../service/api-client/api-client-utils/api-client-utils.service';
import {ProjectTagService} from '../service/api-client/project-tag/project-tag.service';
import {AdminProjectTag} from '../service/api-client/project-tag/type/project-tag/admin-project-tag';

@Component({
   selector: 'app-admin',
   templateUrl: './admin.component.html',
   styleUrls: [
      './admin.component.scss',
      '../user-home-page/user-home-page.component.scss'
   ]
})
export class AdminComponent implements OnInit {

   availableTabs = {
      apiStatus: 1,
      tagAdmin: 2,
      projectAdmin: 3
   };

   activeTab = this.availableTabs.apiStatus;

   apiStatusDump: object = {};
   apiStatusDumpFormatted: string;

   filteredTags: AdminProjectTag[] | null = null;
   allTags: AdminProjectTag[] | null = null;

   filterKeyword: '';

   constructor(private backendApi: ApiClientUtilsService, private projectTagService: ProjectTagService) {
   }

   ngOnInit(): void {
   }

   refreshApiStatus(): void {
      this.apiStatusDump = null;
      this.apiStatusDumpFormatted = null;

      this.backendApi.getApiStatus().subscribe((s: object) => {
         this.apiStatusDump = s;
         this.apiStatusDumpFormatted = JSON.stringify(s, null, 3);
      });
   }

   getAllTags(): void {
      this.filteredTags = null;
      this.allTags = null;
      // Gets tags that match the filter, plus all tags to populate the parent tag dropdown box
      this.projectTagService.getAllTagsForAdmin(this.filterKeyword).subscribe((t: AdminProjectTag[]) => {
         this.projectTagService.getAllTagsForAdmin('').subscribe((all: AdminProjectTag[]) => {
            this.filteredTags = t;
            this.allTags = all;
         });
      });
   }

   updateTagsTable(success: boolean): void {
      if (success) {
         this.getAllTags();
      }
   }
}
