import {ProjectFavouritesEntry} from './project-favourites-entry';

export class PatternFavouritesContainer {
   private readonly favouritesIndexedByProjectId: Map<number, ProjectFavouritesEntry>;

   static unserialize(serialized: string): PatternFavouritesContainer {
      const result = new PatternFavouritesContainer();

      const unserialized = JSON.parse(serialized);

      if (!((unserialized === null) || (unserialized === undefined))) {
         unserialized.forEach((object) => {
            const itm = ProjectFavouritesEntry.unserializeFromObject(object);
            if ((itm !== null) && (itm !== undefined)) {
               result.add(itm);
            }
         });
      }

      return result;
   }

   constructor() {
      this.favouritesIndexedByProjectId = new Map<number, ProjectFavouritesEntry>();
   }

   add(project: ProjectFavouritesEntry): void {
      if (!this.favouritesIndexedByProjectId.has(project.getProjectId())) {
         this.favouritesIndexedByProjectId.set(project.getProjectId(), project);
      }
   }

   remove(project: ProjectFavouritesEntry): void {
      if (this.favouritesIndexedByProjectId.has(project.getProjectId())) {
         this.favouritesIndexedByProjectId.delete(project.getProjectId());
      }
   }

   serializeAsObject(): object {
      const result: Array<object> = [];

      this.favouritesIndexedByProjectId.forEach((p: ProjectFavouritesEntry) => {
         result.push(p.serializeAsObject());
      });

      return result;
   }

   serializeAsArrayOfIds(): Array<number> {
      const result = new Array<number>();

      this.favouritesIndexedByProjectId.forEach((p: ProjectFavouritesEntry) => {
         result.push(p.getProjectId());
      });

      return result;
   }

   count(): number {
      return this.favouritesIndexedByProjectId.size;
   }

   clear(): void {
      this.favouritesIndexedByProjectId.clear();
   }

   has(projectId: number): boolean {
      return this.favouritesIndexedByProjectId.has(projectId);
   }
}
