import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminProjectTag} from '../../service/api-client/project-tag/type/project-tag/admin-project-tag';

@Component({
   selector: 'app-tag-admin',
   templateUrl: './tag-admin.component.html',
   styleUrls: ['./tag-admin.component.scss']
})
export class TagAdminComponent implements OnInit {

   @Input() filteredTags: AdminProjectTag[] = [];
   @Input() allTags: AdminProjectTag[] = [];
   @Output() updateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   constructor() {
   }

   ngOnInit(): void {
   }

   updateTable(success: boolean): void {
      this.updateEvent.emit(success);
   }
}
