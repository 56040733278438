import {PatternSearchResultsInterface} from '../interface/pattern-search-results-interface';
import {ProjectFullInfo} from '../../project/type/project-full-info';
import {ProjectFullInfoInterface} from '../../project/interface/project-full-info-interface';
import {SearchRequest} from './search-request';

export class PatternSearchResults {

   constructor(
      private searchId: number,
      private patternsFound: Array<ProjectFullInfo>,
      private searchRequest: SearchRequest,
      private totalUnpaginatedResultCount: number,
      private totalPageCount: number
   ) {
   }

   static constructFromInterface(i: PatternSearchResultsInterface): PatternSearchResults {
      const patterns: Array<ProjectFullInfo> = new Array<ProjectFullInfo>();

      i.patternsFound.forEach((p: ProjectFullInfoInterface) => {
         patterns.push(ProjectFullInfo.constructFromInterface(p));
      });

      return new PatternSearchResults(
         i.searchId,
         patterns,
         SearchRequest.constructFromInterface(i.searchRequest),
         i.totalUnpaginatedResultCount,
         i.totalPageCount
      );
   }

   getPatternsFound(): Array<ProjectFullInfo> {
      return this.patternsFound;
   }

   getSearchRequest(): SearchRequest {
      return this.searchRequest;
   }

   setNextPage(): boolean {
      return this.searchRequest.setNextPage(this.totalPageCount);
   }

   currentPageIsLastPage(): boolean {
      return this.searchRequest.getZeroBasedCurrentPage() === (this.totalPageCount - 1);
   }

   formatCurrentPageUserFriendly(): string {
      return (this.searchRequest.getZeroBasedCurrentPage() + 1).toString() + ' / ' + this.totalPageCount;
   }

   getSearchId(): number {
      return this.searchId;
   }
}
