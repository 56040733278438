import {Component, Input, OnInit} from '@angular/core';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';
import * as bootstrap from 'bootstrap';

@Component({
   selector: 'app-basic-tooltip',
   templateUrl: './basic-tooltip.component.html',
   styleUrls: ['./basic-tooltip.component.scss']
})
export class BasicTooltipComponent implements OnInit {

   @Input() modalId: string;
   @Input() title: string;

   translationSheet: TranslationSheet;

   constructor(private staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   showModal(): void {
      const element = DomUtils.getNullSafeHtmlElementById(this.modalId);
      const modal = new bootstrap.Modal(element);
      modal.show();
   }
}
