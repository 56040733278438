import {GoogleMerchant} from '../type/sales-channels/google-merchant';
import {SalesChannelFactoryUnmanagedError} from '../exception/sales-channel-factory-unmanaged-error';
import {AbstractSalesChannel} from '../type/sales-channels/abstract-sales-channel';
import {Website} from '../type/sales-channels/website';

export class SalesChannelFactory {
   static constructFromDbId(salesChannelDbId: number): AbstractSalesChannel {
      const google = new GoogleMerchant();
      const website = new Website();

      switch (salesChannelDbId) {
         case google.getDbId():
            return google;
         case website.getDbId():
            return website;
      }

      throw new SalesChannelFactoryUnmanagedError();
   }
}
