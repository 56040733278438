import {NativeDateWrapperUnmanagedError} from './exception/native-date-wrapper-unmanaged-error';
import {UndefinedNull} from '../type-check/undefined-null';

export class NativeDateWrapper {
   private constructor(private date: Date) {
   }

   public static constructFromMySqlFormat(date: string): NativeDateWrapper {

      if (UndefinedNull.isUndefinedOrNull(date)) {
         throw new NativeDateWrapperUnmanagedError('Date string is null');
      }

      if (date === '') {
         throw new NativeDateWrapperUnmanagedError('Date string is empty');
      }

      const dateAux = new Date(date);

      if (NativeDateWrapper.checkDateValid(dateAux)) {
         return new NativeDateWrapper(dateAux);
      }

      throw new NativeDateWrapperUnmanagedError('Not a valid date in SQL format: ' + date);
   }

   private static checkDateValid(date: Date): boolean {
      const timestamp = date.getTime();

      return !isNaN(timestamp);
   }

   public formatAsMySqlDate(): string {
      return this.date.toISOString().substr(0, 10);
   }

   greaterThan(another: NativeDateWrapper): boolean {
      const thisTime = this.date.getTime();
      const otherTime = another.date.getTime();

      return thisTime > otherTime;
   }

   equalsTo(another: NativeDateWrapper): boolean {
      const thisTime = this.date.getTime();
      const otherTime = another.date.getTime();

      return thisTime === otherTime;
   }

   lessThan(another: NativeDateWrapper): boolean {
      const thisTime = this.date.getTime();
      const otherTime = another.date.getTime();

      return thisTime < otherTime;
   }

   lessOrEqualsThan(another: NativeDateWrapper): boolean {
      return this.lessThan(another) || this.equalsTo(another);
   }

   greaterThanOrEqualsTo(another: NativeDateWrapper): boolean {
      return this.greaterThan(another) || this.equalsTo(another);
   }
}
