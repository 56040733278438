import {Injectable} from '@angular/core';
import {LanguageFactory} from './type/language-factory';
import {environment} from '../../../../environments/environment';
import {English} from './type/english';
import {AbstractLanguage} from './type/abstract-language';
import {Spanish} from './type/spanish';
import {French} from './type/french';

@Injectable({
   providedIn: 'root'
})
export class LanguageDetectorService {

   private static usableLanguage(lan: string | null | undefined): boolean {
      return !(
         (lan === null) ||
         (lan === undefined) ||
         (lan.trim() === '')
      );
   }

   getLanguage(): AbstractLanguage {

      const storedCookieLanguagePreference = window.localStorage.getItem(environment.cookie_language_name) ?? '';

      let selectedLanguageLiteral = English.LITERAL;

      if (LanguageDetectorService.usableLanguage(storedCookieLanguagePreference)) {
         selectedLanguageLiteral = storedCookieLanguagePreference;
      } else if (LanguageDetectorService.usableLanguage(navigator.language)){
         selectedLanguageLiteral = navigator.language;
      }

      return LanguageFactory.createFromString(selectedLanguageLiteral);
   }

   setLanguage(lan: AbstractLanguage): void {
      window.localStorage.setItem(environment.cookie_language_name, lan.getApiLiteral());
   }

   currentLanguageIsSpanish(): boolean {
      return this.getLanguage() instanceof Spanish;
   }

   currentLanguageIsFrench(): boolean {
      return this.getLanguage() instanceof French;
   }

   currentLanguageIsEnglish(): boolean {
      return this.getLanguage() instanceof English;
   }
}
