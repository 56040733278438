import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c05a-partners-and-providers',
  templateUrl: './c05a-partners-and-providers.component.html'
})
export class C05aPartnersAndProvidersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
