import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../../api-client/xstitch-api-gateway/xstitch-api-gateway.service';
import {environment} from '../../../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class FreeCheckoutService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public freeCheckoutTemplate(templateId: number): Promise<any> {
      const endpoint = environment.xstitchApiEndpoints.checkout.free.addFreeTemplateToCollection;
      const body: Map<string, any> = new Map<string, any>();
      body.set('templateId', templateId);

      return new Promise<any>((resolve, reject) => {
         this.xstitchApiGateway.postWithJsonBodyRelativeRoute<any>(endpoint, body).subscribe((o: any) => {
            resolve(0);
         }, (error) => reject(error));
      });
   }
}
