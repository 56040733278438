import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c01-what-this-policy-covers',
  templateUrl: './c01-what-this-policy-covers.component.html'
})
export class C01WhatThisPolicyCoversComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
