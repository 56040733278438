import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c06-sharing-personal-data-with-other-organisations',
  templateUrl: './c06-sharing-personal-data-with-other-organisations.component.html'
})
export class C06SharingPersonalDataWithOtherOrganisationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
