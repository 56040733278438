import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';

@Component({
   selector: 'app-button-primary-action',
   templateUrl: './primary-action.component.html',
   styleUrls: ['./primary-action.component.scss']
})
export class PrimaryActionComponent implements OnInit {

   @Input() buttonLegend: string;
   @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
   @Input() disabled = false;
   @Input() busy = false;

   translationSheet: TranslationSheet;

   constructor(staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   setBusy(busy: boolean) {
      this.busy = busy;
   }

   emitClickEvent() {
      if (this.busy || this.disabled) {
         return;
      }
      this.clickEvent.emit();
   }
}
