import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
   name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

   transform(value: unknown, ...args: unknown[]): unknown {
      let aux: string;

      if ((value === null) || (value === undefined)) {
         return '';
      }

      aux = value as string;
      aux = aux.trim();

      if (aux === '') {
         return '';
      }

      return aux[0].toUpperCase() + aux.substr(1).toLowerCase();
   }
}
