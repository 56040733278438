import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-c13-other-data-protection-rights',
  templateUrl: './c13-other-data-protection-rights.component.html'
})
export class C13OtherDataProtectionRightsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
