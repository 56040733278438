import {Component, EventEmitter, Output, Input, AfterViewChecked} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {ApiClientUtilsService} from '../../../service/api-client/api-client-utils/api-client-utils.service';
import {environment} from '../../../../environments/environment';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';
import {AbstractUserNameControlIcons} from './UserNameControlIcons/abstract-user-name-control-icons';
import {UserNameControlShare} from './UserNameControlIcons/user-name-control-share';
import {StandardApiErrorResponse} from '../../../service/api-client/xstitch-api-gateway/type/standard-api-error-response';

@Component({
   selector: 'app-online-validated-username-textbox',
   templateUrl: './online-validated-username-textbox.component.html',
   styleUrls: ['./online-validated-username-textbox.component.scss']
})

export class OnlineValidatedUsernameTextboxComponent implements AfterViewChecked {

   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Input() initialContent = '';
   @Input() placeholder: string;
   @Input() icon: AbstractUserNameControlIcons;
   @Input() controlId = '';
   private adHocCheck = false;
   private wellFormedUsername: string;
   private isWellFormedUserName: boolean;
   private busy = false;
   translationSheet: TranslationSheet;
   private lastTimeout: number | null = null;

   constructor(private apiClientUtils: ApiClientUtilsService, staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngAfterViewChecked() {

      if ((this.initialContent !== '') && (!this.busy) && (!this.adHocCheck)) {
         this.adHocCheck = true;
         this.checkWellFormedUserNameOnline().then(() => {
            this.focusOutValidationMode();
         });
      }
   }

   private getUsernameControl(): HTMLFormElement {
      return DomUtils.getNullSafeHtmlFormElementById(this.controlId + '_user-name-control');
   }

   private getUsernameFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById(this.controlId + '_username-feedback');
   }

   async checkWellFormedUserNameOnline() {

      const usernameControl = this.getUsernameControl();
      const usernameFeedback = this.getUsernameFeedback();

      const usernameValue = usernameControl.value;
      FormCommonFunctionality.resetFormComponentValidation(usernameControl);

      if ((usernameValue === null) || (usernameValue === undefined)) {
         usernameFeedback.innerText = this.translationSheet.usernameTextboxComponent.theUsernameCompulsory;
         this.isWellFormedUserName = false;
         this.validationEvent.emit(false);
      } else {
         this.busy = true;

         if (this.lastTimeout !== null) {
            clearTimeout(this.lastTimeout);
         }

         this.lastTimeout = setTimeout(() => {
            this.apiClientUtils.checkUsernameCorrectStructure(usernameValue).subscribe(() => {
               FormCommonFunctionality.setFormComponentValid(usernameControl);
               this.busy = false;
               this.validationEvent.emit(true);
               this.isWellFormedUserName = true;
               this.wellFormedUsername = usernameValue.trim();
            }, (error: StandardApiErrorResponse) => {
               this.busy = false;
               this.validationEvent.emit(false);
               this.isWellFormedUserName = false;

               if (error.getCode() === environment.xstitch_api_error_codes.utils.username_too_short) {
                  usernameFeedback.innerText = this.translationSheet.usernameTextboxComponent.tooShort;
               } else if (error.getCode() === environment.xstitch_api_error_codes.utils.username_too_long) {
                  usernameFeedback.innerText = this.translationSheet.usernameTextboxComponent.tooLong;
               } else {
                  // This shouldn't really happen.
                  usernameFeedback.innerText = this.translationSheet.usernameTextboxComponent.usernameInvalid;
               }
            });
         }, environment.continuous_online_validation_request_buffer_millis);
      }
   }

   focusOutValidationMode(): void {
      const usernameControl = this.getUsernameControl();
      if (!this.isWellFormedUserName) {
         FormCommonFunctionality.setFormComponentInvalid(usernameControl);
      }
   }

   resetUsernameValidation(): void {
      const usernameControl = this.getUsernameControl() as HTMLFormElement;
      FormCommonFunctionality.resetFormComponentValidation(usernameControl);
   }

   getWellFormedUsername(): string {
      return this.wellFormedUsername;
   }

   getIconClass(): string {
      if (this.busy) {
         return 'spinner-border spinner-border-sm';
      }

      if (this.icon instanceof UserNameControlShare) {
         return 'share-icon-image-black';
      }

      // Default
      return 'sign-in-icon-image-black';
   }

   private resetText(): void {
      const usernameControl = this.getUsernameControl();
      usernameControl.value = null;
      this.checkWellFormedUserNameOnline().then(() => {
      });
   }

   resetControl(): void {
      this.resetUsernameValidation();
      this.resetText();
   }
}
