export abstract class AbstractBasicProjectData {
   protected constructor(
      private readonly projectId: number,
      private readonly projectTitleEnglish: string) {
   }

   getPatternId(): number {
      return this.projectId;
   }

   getProjectTitleEnglish(): string {
      return this.projectTitleEnglish;
   }
}
