import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import * as bootstrap from 'bootstrap';
import {ShoppingCartStorageService} from '../../service/shopping-cart/shopping-cart-storage.service';
import {DomUtils} from '../../utils/dom/dom-utils';
import {ProjectTemplate} from '../../service/api-client/search-pattern/type/project-template';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {PrimaryActionComponent} from '../../form-reusable-components/buttons/primary-action/primary-action.component';

@Component({
   selector: 'app-shopping-cart-item',
   templateUrl: './shopping-cart-item.component.html',
   styleUrls: ['./shopping-cart-item.component.scss']
})
export class ShoppingCartItemComponent implements OnInit {

   @Input() unsecuredImgSrc: string;
   @Input() projectId: number;
   @Input() templateId: number;
   @Input() priceGbpIncludingTax: number;
   @Input() patternComments: string;
   @Input() patternTitle: string;
   @Input() templateObject: ProjectTemplate;

   @ViewChild('remove_comment_button_id') removeFromShoppingCartAction: PrimaryActionComponent;

   safeImage: SafeUrl;
   translationSheet: TranslationSheet;

   constructor(
      private shoppingCartStorageService: ShoppingCartStorageService,
      private domSanitizer: DomSanitizer,
      private staticDictionary: StaticDictionaryService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.safeImage = this.domSanitizer.bypassSecurityTrustUrl(this.unsecuredImgSrc);
   }

   removeFromShoppingCartHandler(): void {
      const el = DomUtils.getNullSafeHtmlElementById('confirm_delete_modal_' + this.projectId.toString());
      const myModal = new bootstrap.Modal(el, {});
      myModal.show();
   }

   removeItemAndRefresh(): void {
      this.removeFromShoppingCartAction.setBusy(true);
      const shoppingCartContainer = this.shoppingCartStorageService.readFromStorage();
      shoppingCartContainer.removeItemByIds(this.projectId, this.templateId);
      this.shoppingCartStorageService.writeToStorage(shoppingCartContainer);
      window.location.reload();
   }

   public getShoppingCartModalId(): string {
      return 'confirm_delete_modal_' + this.projectId.toString();
   }
}
