import {Injectable} from '@angular/core';
import {UserAdvancedSearchParamsObject} from '../../object/user-advanced-search-params-object';

@Injectable({
   providedIn: 'root'
})
export class AdvancedSearchStorageService {

   private static readonly advancedSearchStorageKey = 'L2VBzwpzDJ';

   constructor() {
   }

   readFromStorage(): UserAdvancedSearchParamsObject {

      const t = window.localStorage.getItem(AdvancedSearchStorageService.advancedSearchStorageKey);

      return UserAdvancedSearchParamsObject.buildFromJsonString(t);
   }

   writeToStorage(advancedSearch: UserAdvancedSearchParamsObject): void {
      const s = advancedSearch.convertToJsonString();
      window.localStorage.setItem(AdvancedSearchStorageService.advancedSearchStorageKey, s);
   }
}
