import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../../api-client/xstitch-api-gateway/xstitch-api-gateway.service';
import {VerificationDetails} from './type/verification-details';
import {Observable, Subscriber} from 'rxjs';
import {VerificationDetailsInterface} from './interface/verification-details-interface';
import {StandardApiResponseDeprecated} from '../../api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {environment} from '../../../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class VerificationService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public getVerificationDetails(downloadToken: string, pdfFormat: number, pdfLanguage: number): Observable<VerificationDetails> {
      return new Observable<VerificationDetails>((s: Subscriber<VerificationDetails>) => {
         let backendUrl = environment.xstitchApiEndpoints.checkout.pdfVerification;

         backendUrl = backendUrl.replace('{download-token}', downloadToken);
         backendUrl = backendUrl.replace('{pdf-format}', pdfFormat.toString());
         backendUrl = backendUrl.replace('{pdf-language}', pdfLanguage.toString());

         const response = this
            .xstitchApiGateway
            .getAsJsonRelativeRoute<VerificationDetailsInterface>(backendUrl);

         response.subscribe((o: VerificationDetailsInterface) => {
            s.next(VerificationDetails.constructFromInterface(o));
         }, error => {
            s.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }
}
