import {TranslationSheet} from './translation-sheet';

export abstract class AbstractDictionary {

   protected translationSheet: TranslationSheet;

   protected constructor() {
   }

   getTranslationSheet(): TranslationSheet {
      return this.translationSheet;
   }
}
