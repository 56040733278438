import {English} from './english';
import {French} from './french';
import {Spanish} from './spanish';
import {AbstractLanguage} from './abstract-language';
import {LanguageFactoryUnmanagedError} from '../exception/language-factory-unmanaged-error';

export class LanguageFactory {

   public static createFromDbId(dbId: number): AbstractLanguage {
      switch (dbId) {
         case 1:
            return new English();
         case 2:
            return new Spanish();
         case 3:
            return new French();
      }

      throw new LanguageFactoryUnmanagedError('Unrecognized language db id: ' + dbId.toString());
   }

   public static createFromString(locale: string): AbstractLanguage {
      const lanPrefs = locale.split(',');

      if (lanPrefs.length === 0) {
         return new English();
      }

      const mostPreferred = lanPrefs[0].trim();

      if (LanguageFactory.isEnglish(mostPreferred)) {
         return new English();
      }

      if (LanguageFactory.isFrench(mostPreferred)) {
         return new French();
      }

      if (LanguageFactory.isSpanish(mostPreferred)) {
         return new Spanish();
      }

      return new English();
   }

   private static isEnglish(locale: string): boolean {
      return (/en/.test(locale) || /en-*/.test(locale));
   }

   private static isFrench(locale: string): boolean {
      return (/fr/.test(locale) || /fr-*/.test(locale));
   }

   private static isSpanish(locale: string): boolean {
      return (/es/.test(locale) || /es-*/.test(locale));
   }
}
