import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../service/api-client/user/user.service';
import {OnlineValidatedUsernameTextboxComponent} from '../form-reusable-components/textboxes/online-validated-username-textbox/online-validated-username-textbox.component';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {EmailSubjectComponent} from '../form-reusable-components/textboxes/email-subject/email-subject.component';
import {EmailPlainTextBodyComponent} from '../form-reusable-components/textboxes/email-plain-text-body/email-plain-text-body.component';
import {ActivatedRoute, Router} from '@angular/router';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';

@Component({
   selector: 'app-contact-us',
   templateUrl: './contact-us.component.html',
   styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent implements OnInit {

   @ViewChild(OnlineValidatedUsernameTextboxComponent) onlineValidatedUsernameTextboxComponent: OnlineValidatedUsernameTextboxComponent;
   @ViewChild(OnlineValidatedEmailComponent) onlineValidatedEmailComponent: OnlineValidatedEmailComponent;
   @ViewChild(EmailSubjectComponent) emailSubject: EmailSubjectComponent;
   @ViewChild(EmailPlainTextBodyComponent) emailPlainTextBody: EmailPlainTextBodyComponent;

   contactUsPageStatuses = {
      landing_page: 0,
      success: 1,
      error: 2
   };

   contactUsPageStatus: number;

   nameValid = false;
   emailValid = false;
   subjectValid = false;
   messageValid = false;

   storedName = '';
   storedEmail = '';
   storedSubject = '';
   storedBody = '';

   translationSheet: TranslationSheet;

   contactEmail: string;

   constructor(
      public router: Router,
      private userService: UserService,
      staticDictionary: StaticDictionaryService,
      private route: ActivatedRoute
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.contactUsPageStatus = this.contactUsPageStatuses.landing_page;
      this.contactEmail = environment.email_addresses_imported.default_email_address;

      const subject = this.route.snapshot.queryParamMap.get(environment.queryStringParameterNames.contactUsSubject);
      const body = this.route.snapshot.queryParamMap.get(environment.queryStringParameterNames.contactUsBody);

      if (subject !== null) {
         this.storedSubject = subject;
      }

      if (body !== null) {
         this.storedBody = body;
      }
   }

   sendMessage() {
      this.storedName = this.onlineValidatedUsernameTextboxComponent.getWellFormedUsername();
      this.storedEmail = this.onlineValidatedEmailComponent.getWellFormedEmail();
      this.storedSubject = this.emailSubject.getWellFormedSubject();
      this.storedBody = this.emailPlainTextBody.getWellFormedEmailBody();

      this.userService.sendContactUsMessage(
         this.storedName,
         this.storedEmail,
         this.storedSubject,
         this.storedBody
      ).subscribe(() => {
         this.contactUsPageStatus = this.contactUsPageStatuses.success;
         this.storedName = '';
         this.storedEmail = '';
         this.storedSubject = '';
         this.storedBody = '';
      }, () => {
         this.contactUsPageStatus = this.contactUsPageStatuses.error;
      });
   }

   restoreContent() {
      this.contactUsPageStatus = this.contactUsPageStatuses.landing_page;
      this.nameValid = false;
      this.emailValid = false;
      this.subjectValid = false;
      this.messageValid = false;
   }
}
