import {AfterContentChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {HomePageService} from '../service/api-client/home-page/home-page.service';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {AppComponent} from '../app.component';
import {Event, Router, RouterEvent} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DomUtils} from '../utils/dom/dom-utils';
import {CommonSearchBarComponent} from '../top-navigation-bar/common-search-bar/common-search-bar.component';

@Component({
   selector: 'app-home-page',
   templateUrl: './home-page.component.html',
   styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit, AfterContentChecked, OnDestroy {

   pageStatuses = {
      loading: 0,
      success: 1
   };

   pageStatus: number;

   translationSheet: TranslationSheet;

   private routerSubscription: Subscription;

   readonly VIDEO_ALL_COMBINATIONS = 'all_format_combination';
   readonly VIDEO_GRID_FORMATS = 'grid_formats';
   readonly VIDEO_PDF_FORMATS = 'pdf_formats';
   readonly VIDEO_COMBINED_PALETTES = 'combined_palettes';
   readonly VIDEO_UNLIMITED_COLORS = 'unlimited_colors';
   readonly VIDEO_NO_SYMBOLS = 'no_symbols';
   readonly VIDEO_CHOOSE_LANGUAGE = 'choose_language';
   readonly VIDEO_REALISTIC_PREVIEW = 'realistic_preview';

   constructor(
      public rootComponent: AppComponent,
      private homePageService: HomePageService,
      public languageDetectorService: LanguageDetectorService,
      private staticDictionaryService: StaticDictionaryService,
      private router: Router
   ) {
      this.translationSheet = staticDictionaryService.getTranslationSheet();

      this.routerSubscription = router.events.pipe(
         filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
      ).subscribe((e: RouterEvent) => {
         if (e.url === '/') {
            this.homePageService.logHomePageVisit();
         }
      });
   }

   ngOnInit(): void {
      const searchBar = DomUtils.getNullSafeHtmlFormElementById(CommonSearchBarComponent.MAIN_SEARCH_BAR_ID);
      searchBar.classList.remove('hidden'); // It was set on the advanced search page, or there would be two search bars
      this.pageStatus = this.pageStatuses.loading;
   }

   ngAfterContentChecked(): void {
      if (this.rootComponent.mainCategories.length > 0) {
         this.pageStatus = this.pageStatuses.success;
      }
   }

   ngOnDestroy(): void {
      this.routerSubscription.unsubscribe();
   }

   getVideoAssetPath(type: string): string {
      return this.translationSheet.videoPromo.videoAsset.expand([{name: 'videoType', value: type}]);
   }
}
