import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c07-how-we-protect-your-personal-data',
  templateUrl: './c07-how-we-protect-your-personal-data.component.html'
})
export class C07HowWeProtectYourPersonalDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
