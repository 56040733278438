import {Component, OnInit} from '@angular/core';
import {UserService} from '../service/api-client/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FavouritesManagerService} from '../service/favourites-service/favourites-manager.service';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';
import {User} from '../service/api-client/user/type/user';
import {UserCollection} from '../service/api-client/user/type/user-collection';

@Component({
   selector: 'app-user-home-page',
   templateUrl: './user-home-page.component.html',
   styleUrls: ['./user-home-page.component.scss']
})
export class UserHomePageComponent implements OnInit {

   localFavouritesSynced = false;
   translationSheet: TranslationSheet;

   dashboardPageStatuses = {
      userAccountDetails: {
         loading: 0,
         loaded: 1,
         error: 2
      },
      collectionDetails: {
         loading: 0,
         loaded: 1,
         error: 2
      }
   };

   availableTabs = {
      userAccountDetails: 1,
      collectionDetails: 2,
      adminDetails: 3
   };

   activeTab = this.availableTabs.collectionDetails;

   userAccountDetailsPageStatus = this.dashboardPageStatuses.userAccountDetails.loading;
   userAccountCollectionPageStatus = this.dashboardPageStatuses.collectionDetails.loading;

   accountDetails: User | null = null;
   userCollection: UserCollection | null = null;

   constructor(
      private userService: UserService,
      private router: Router,
      private favouritesManager: FavouritesManagerService,
      private staticDictionary: StaticDictionaryService,
      private route: ActivatedRoute
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      const activeSection = this
         .route
         .snapshot
         .queryParamMap.get(environment.queryStringParameterNames.usserAccountActiveSection);

      if ((activeSection !== null) && (activeSection !== undefined)) {
         if (activeSection === 'collection') {
            this.activeTab = this.availableTabs.collectionDetails;
         }

         if (activeSection === 'account') {
            this.activeTab = this.availableTabs.userAccountDetails;
         }

         if (activeSection === 'admin') {
            this.activeTab = this.availableTabs.adminDetails;
         }
      }

      this.userService.getCurrentUser().subscribe((user: User) => {
         this.favouritesManager.syncLocalStorageFavourites().subscribe((synced: boolean) => {
            this.localFavouritesSynced = synced;
         });

         this.userService.getUserAccountDetails().subscribe((accountDetails: User) => {
            this.userAccountDetailsPageStatus = this.dashboardPageStatuses.userAccountDetails.loaded;
            this.accountDetails = accountDetails;
         });

         this.userService.getUserCollection().subscribe((userCollection: UserCollection) => {
            this.userAccountCollectionPageStatus = this.dashboardPageStatuses.collectionDetails.loaded;
            this.userCollection = userCollection;
         });
      }, () => {
         this.router.navigate([environment.frontEndRoutes.loginRoute]).then();
      });
   }
}
