import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c11a-all-choices',
  templateUrl: './c11a-all-choices.component.html'
})
export class C11aAllChoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
