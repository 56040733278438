import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c02-a1-types-of-data',
  templateUrl: './c02-a1-types-of-data.component.html'
})
export class C02A1TypesOfDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
