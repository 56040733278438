import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomUtils} from '../../../../utils/dom/dom-utils';
import {StaticDictionaryService} from '../../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../../service/locale/static-dictionary/type/translation-sheet';
import {FormCommonFunctionality} from '../../../form-common-functionality';

@Component({
   selector: 'app-plain-text-max-length',
   templateUrl: './plain-text-max-length.component.html',
   styleUrls: ['./plain-text-max-length.component.scss']
})
export class PlainTextMaxLengthComponent implements OnInit {

   @Input() controlId = 'max-length-text-control-id';
   @Input() maxLength = 300;
   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   feedbackControlId = 'max-length-text-body-feedback';

   translationSheet: TranslationSheet;

   currentLength = 0;

   private isWellFormedText: boolean;
   private wellFormedText: string;

   constructor(staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   private getTextAreaControl(): HTMLFormElement {
      return DomUtils.getNullSafeHtmlFormElementById(this.controlId);
   }

   private getTextAreaFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById(this.feedbackControlId);
   }

   validateTextAreaContent(silent: boolean): void {
      const textAreaControl = this.getTextAreaControl();
      const textAreaFeedback = this.getTextAreaFeedback();

      const commentText = textAreaControl.value;

      if ((commentText === null) || (commentText === undefined)) {
         this.currentLength = 0;
         this.isWellFormedText = false;
         this.validationEvent.emit(false);
         if (!silent) {
            textAreaFeedback.innerText = this.translationSheet.maxLengthTextAreaComponent.pleaseWrite;
            FormCommonFunctionality.setFormComponentInvalid(textAreaControl);
         }
      } else {
         const sanitized = commentText.trim();
         this.currentLength = sanitized.length;

         this.isWellFormedText = true;

         if (this.currentLength === 0) {
            this.isWellFormedText = false;
            textAreaFeedback.innerText = this.translationSheet.maxLengthTextAreaComponent.pleaseWrite;
         } else if (this.currentLength < 5) {
            this.isWellFormedText = false;
            textAreaFeedback.innerText =
               this.translationSheet.maxLengthTextAreaComponent.tooShort +
               ' 5 ' +
               this.translationSheet.maxLengthTextAreaComponent.chars;
         } else if (this.currentLength > this.maxLength) {
            this.isWellFormedText = false;
            textAreaFeedback.innerText =
               this.translationSheet.maxLengthTextAreaComponent.tooLong +
               ' ' + this.maxLength + ' ' +
               this.translationSheet.maxLengthTextAreaComponent.chars;
         }

         if (this.isWellFormedText) {
            FormCommonFunctionality.setFormComponentValid(textAreaControl);
            this.validationEvent.emit(true);
            this.wellFormedText = sanitized;
         } else {
            if (!silent) {
               FormCommonFunctionality.setFormComponentInvalid(textAreaControl);
            }

            this.validationEvent.emit(false);
         }
      }
   }

   resetTextAreaContentValidation(): void {
      this.validationEvent.emit(false);
      const textAreaControl = this.getTextAreaControl();
      const textAreaFeedback = this.getTextAreaFeedback();
      textAreaFeedback.innerText = '';
      FormCommonFunctionality.resetFormComponentValidation(textAreaControl);
   }

   getWellFormedText(): string {
      return this.wellFormedText;
   }
}
