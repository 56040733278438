import {AbstractLanguage} from './abstract-language';

export class Spanish extends AbstractLanguage {

   public static readonly LITERAL = 'es-ES';

   getApiLiteral(): string {
      return Spanish.LITERAL;
   }

   getIso639Dash1Locale(): string {
      return 'es';
   }

   getDbId(): number {
      return 2;
   }

   isEnglish(): boolean {
      return false;
   }

   isFrench(): boolean {
      return false;
   }

   isSpanish(): boolean {
      return true;
   }
}
