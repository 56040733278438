import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../service/api-client/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {UserCollectionItem} from '../service/api-client/user/type/user-collection-item';
import {StandardApiResponseDeprecated} from '../service/api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {GenericErrorModalComponent} from '../form-reusable-components/modal/generic-error-modal/generic-error-modal.component';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';

@Component({
   selector: 'app-common-download-area',
   templateUrl: './common-download-area.component.html',
   styleUrls: ['./common-download-area.component.scss']
})
export class CommonDownloadAreaComponent implements OnInit {

   notFoundModalId = 'not_found_modal';

   @ViewChild(GenericErrorModalComponent) genericerrorModalComponent: GenericErrorModalComponent;

   pageStatuses = {
      loading: 0,
      loaded: 1
   };

   currentPageStatus = this.pageStatuses.loading;
   translationSheet: TranslationSheet;

   constructor(
      private userService: UserService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private staticDictionary: StaticDictionaryService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   downloadDetails: UserCollectionItem | undefined;

   ngOnInit(): void {
      const downloadToken = this.activatedRoute.snapshot.paramMap.get('download-token');

      if (downloadToken === null) {
         this.redirectHome();
      } else {
         this.userService.getDownloadDetailsForDownloadToken(downloadToken).subscribe((s: UserCollectionItem) => {
            this.currentPageStatus = this.pageStatuses.loaded;
            this.downloadDetails = s;
         }, (error: StandardApiResponseDeprecated) => {
            if ((error.getHttpStatusCode() === 404) || (error.getHttpStatusCode() === 403)) {
               // Either not found, or it belongs to another user
               this.genericerrorModalComponent.show();
            }

            if (error.getHttpStatusCode() === 401) {
               const currentRoute = this.router.url;

               const redirection = environment.frontEndRoutes.loginRoute + '?' +
                  environment.queryStringParameterNames.successRedirect +
                  '=' + currentRoute;

               this.router.navigateByUrl(redirection).then(() => {
               });
            }
         });
      }
   }

   private redirectHome(): void {
      this.router.navigate([environment.frontEndRoutes.home]).then(() => {
      });
   }
}
