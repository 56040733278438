import {Component, OnInit, ViewChild} from '@angular/core';
import {ProjectTemplateService} from '../../service/api-client/project-template/project-template.service';
import {EmptyTemplateCreationDetails} from '../../service/api-client/project-template/type/empty-template-creation-details';
import {PrimaryActionComponent} from '../../form-reusable-components/buttons/primary-action/primary-action.component';
import {StandardApiErrorResponse} from '../../service/api-client/xstitch-api-gateway/type/standard-api-error-response';
import {ProjectTagService} from '../../service/api-client/project-tag/project-tag.service';
import {AdminProjectTag} from '../../service/api-client/project-tag/type/project-tag/admin-project-tag';
import {BasicProjectInfo} from '../../service/api-client/project/type/basic-project-info';
import {ProjectService} from '../../service/api-client/project/project.service';
import {ModalComponent} from '../../form-reusable-components/modal/modal/modal.component';
import {ProjectFullInfoInterface} from '../../service/api-client/project/interface/project-full-info-interface';
import {ProjectFullInfo} from '../../service/api-client/project/type/project-full-info';
import {AdminProjectTagCollection} from '../../service/api-client/project-tag/type/admin-project-tag-collection';

@Component({
   selector: 'app-project-admin',
   templateUrl: './project-admin.component.html',
   styleUrls: ['./project-admin.component.scss']
})

export class ProjectAdminComponent implements OnInit {

   confirmProjectDeleteModalId = 'confirm-project-delete-modal-id';
   standardApiErrorModalId = 'standard_api_error_modal_id';
   emptyTemplateProjectId = '';
   emptyTemplateCreationDetails: EmptyTemplateCreationDetails | null;
   emptyTemplateCreationError: StandardApiErrorResponse | null = null;
   editProjectDropdownSelected: string;
   allTags: AdminProjectTagCollection;
   projectSelectOptions: BasicProjectInfo[] = [];
   selectedProjectInfo: ProjectFullInfoInterface | null = null;
   apiErrorResponse: StandardApiErrorResponse | null = null;

   projectTagsSelectedModel: Array<number> = [];

   @ViewChild('createEmptyTemplateButton') createEmptyTemplateButton: PrimaryActionComponent | undefined;
   @ViewChild('confirm_project_delete_modal_id') confirmDeleteProjectModal: ModalComponent | undefined;
   @ViewChild('confirmDeleteProjectButton') confirmDeleteProjectButton: PrimaryActionComponent | undefined;
   @ViewChild('standard_api_error_modal_id') standardApiErrorModal: ModalComponent | undefined;
   @ViewChild('update_project_button_id') updateProjectButton: PrimaryActionComponent | undefined;
   @ViewChild('add_empty_template_selected_project_button') addEmptyTemplateSelectedProjectButton: PrimaryActionComponent | undefined;

   constructor(
      private readonly projectTemplateService: ProjectTemplateService,
      private readonly projectTagService: ProjectTagService,
      private readonly projectService: ProjectService
   ) {
   }

   ngOnInit(): void {
      this.reloadProjectSelectDropdown();
   }

   reloadProjectSelectDropdown(): void {
      this.projectTagService.getAllTagsForAdmin(null).subscribe((t: AdminProjectTag[]) => {
         this.allTags = new AdminProjectTagCollection(t);
      });

      this.projectService.getProjectListForDropdownSelector().subscribe((r: BasicProjectInfo[]) => {
         this.projectSelectOptions = r;
         this.editProjectDropdownSelected = '';
         this.selectedProjectInfo = null;
      });
   }

   addEmptyTemplateForSelectedProject() {
      this.addEmptyTemplateSelectedProjectButton?.setBusy(true);

      this.projectTemplateService.createEmptyTemplate(this.selectedProjectInfo.projectId).subscribe((r: EmptyTemplateCreationDetails) => {
         this.emptyTemplateCreationDetails = r;
         this.createEmptyTemplateButton?.setBusy(false);
         this.reloadProjectSelectDropdown();
      }, (error: StandardApiErrorResponse) => {
         this.createEmptyTemplateButton?.setBusy(false);
         this.emptyTemplateCreationError = error;
      });
   }

   createEmptyTemplate(): void {
      this.createEmptyTemplateButton?.setBusy(true);
      this.emptyTemplateCreationError = null;
      this.emptyTemplateCreationDetails = null;

      let sanitizedProject: number | null;

      if (this.emptyTemplateProjectId.trim() === '') {
         sanitizedProject = null;
      } else {
         sanitizedProject = parseInt(this.emptyTemplateProjectId, 10);

         if (isNaN(sanitizedProject)) {
            this.emptyTemplateCreationError = new StandardApiErrorResponse(
               'notAnInteger', 'Project id \'' + this.emptyTemplateProjectId + '\' cannot be parsed as integer'
            );
            this.createEmptyTemplateButton?.setBusy(false);
            return;
         }
      }

      this.projectTemplateService.createEmptyTemplate(sanitizedProject).subscribe((r: EmptyTemplateCreationDetails) => {
         this.emptyTemplateCreationDetails = r;
         this.createEmptyTemplateButton?.setBusy(false);
         this.reloadProjectSelectDropdown();
      }, (error: StandardApiErrorResponse) => {
         this.createEmptyTemplateButton?.setBusy(false);
         this.emptyTemplateCreationError = error;
      });
   }

   editProjectDropdownChange(): void {
      this.reloadProjectSelectDropdown();
      this.apiErrorResponse = null;
      if (!this.editProjectDropdownSelected) {
         return;
      }

      const convertedProject = parseInt(this.editProjectDropdownSelected, 10);
      if (isNaN(convertedProject)) {
         return;
      }

      this.selectedProjectInfo = null;

      this.projectService.getProjectFullInfo(convertedProject).subscribe((p: ProjectFullInfoInterface) => {
         this.selectedProjectInfo = p;
         const seletedProjectInfoClass = ProjectFullInfo.constructFromInterface(p);
         this.projectTagsSelectedModel = seletedProjectInfoClass.getTagIds();
      });
   }

   deleteProject(): void {
      this.reloadProjectSelectDropdown();
      if ((this.selectedProjectInfo === null) || (this.selectedProjectInfo === undefined)) {
         return;
      }

      this.confirmDeleteProjectButton?.setBusy(true);

      this.projectService.deleteProject(this.selectedProjectInfo.projectId).subscribe(() => {
         this.confirmDeleteProjectButton?.setBusy(false);
         this.confirmDeleteProjectModal?.hide();
         this.selectedProjectInfo = null;
         this.reloadProjectSelectDropdown();

      }, (error: StandardApiErrorResponse) => {
         this.confirmDeleteProjectButton?.setBusy(false);
         this.confirmDeleteProjectModal?.hide();
         this.apiErrorResponse = error;
         this.standardApiErrorModal?.show();
      });
   }

   showDeleteProjectConfirmationModal(): void {
      this.confirmDeleteProjectButton?.setBusy(false);
      this.confirmDeleteProjectModal?.show();
   }

   public updateProjectDetails(): void {
      this.updateProjectButton?.setBusy(true);
      this.projectService.updateProject(this.selectedProjectInfo).subscribe((u: ProjectFullInfoInterface) => {
         this.selectedProjectInfo = u;
         this.editProjectDropdownSelected = u.projectId.toString();
         this.updateProjectButton?.setBusy(false);
      }, (e: StandardApiErrorResponse) => {
         this.apiErrorResponse = e;
         this.updateProjectButton?.setBusy(false);
         this.standardApiErrorModal?.show();
      });
   }

   projectTagsSelectedChange(): void {
      this.selectedProjectInfo.tags = [];
      let tagObject: AdminProjectTag | null;
      this.projectTagsSelectedModel.forEach((tagId: number) => {
         tagObject = this.allTags.getById(tagId);
         if (tagObject !== null) {
            this.selectedProjectInfo.tags.push(tagObject.getAsSimpleProjectTagInterface());
         }
      });
   }
}
