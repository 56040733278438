import {AbstractPalette} from './abstract-palette';
import {palettes} from './palette-interface';

export class Anchor extends AbstractPalette {

   getCssClass(): string {
      return 'anchor';
   }

   getUserFriendlyDescriptor(): string {
      return 'Anchor';
   }

   getValue(): string {
      return palettes.ANCHOR.toString();
   }
}
