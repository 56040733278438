import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c05-sharing-personal-data-with-providers',
  templateUrl: './c05-sharing-personal-data-with-providers.component.html'
})
export class C05SharingPersonalDataWithProvidersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
