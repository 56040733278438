import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c09a-cookie-purposes',
  templateUrl: './c09a-cookie-purposes.component.html'
})
export class C09aCookiePurposesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
