import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {UserApiInterface} from './interface/user-api-interface';
import {environment} from '../../../../environments/environment';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {StandardApiResponseDeprecated} from '../xstitch-api-gateway/type/standard-api-response-deprecated';
import {User} from './type/user';
import {UserCollection} from './type/user-collection';
import {UserCollectionItemResponseApiInterface, UserCollectionResponseApiInterface} from './interface/user-collection-response-api-interface';
import {UserCollectionItem} from './type/user-collection-item';
import {PayloadNamingRepo as PNR} from '../xstitch-api-gateway/payload-naming-repo';
import {StandardApiErrorResponse} from '../xstitch-api-gateway/type/standard-api-error-response';

@Injectable({
   providedIn: 'root'
})

export class UserService {
   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   registerNewUser(
      userName: string,
      email: string,
      passwordClearText: string,
      newsLetterFrequencyId: number
   ): Observable<UserApiInterface> {
      const payload: Map<string, any> = new Map<string, any>();
      payload.set(PNR.USER_NAME, userName);
      payload.set(PNR.EMAIL, email);
      payload.set(PNR.PASSWORD, passwordClearText);
      payload.set(PNR.NEWSLETTER_FREQUENCY_ID, newsLetterFrequencyId);

      return new Observable<UserApiInterface>((subscriber: Subscriber<UserApiInterface>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(
            environment.xstitchApiEndpoints.user.registerNewUser,
            payload
         );

         response.subscribe((observer: HttpResponse<any>) => {
            subscriber.next(observer.body as UserApiInterface);
         }, error => {
            subscriber.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   verifyAccount(emailAddress: string, validationToken: string): Observable<boolean> {
      const url = environment.xstitchApiEndpoints.user.verifyUserAccount + emailAddress + '/' + validationToken;

      return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute(url);

         response.subscribe(() => {
            subscriber.next(true);
         }, () => {
            subscriber.error(false);
         });
      });
   }

   changeUserName(newUserName: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.changeUserName;
      const payload = new Map<string, string>();
      payload.set('newName', newUserName);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);

         response.subscribe(() => {
            subscriber.next();
         }, () => {
            subscriber.error();
         });
      });
   }

   changeAccountPassword(newPassword: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.changeAccountPassword;
      const payload = new Map<string, string>();
      payload.set('newPassword', newPassword);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);

         response.subscribe(() => {
            subscriber.next();
         }, () => {
            subscriber.error();
         });
      });
   }

   changeNewsletterFrequency(newsletterNewFrequency: number): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.changeNewsletterFrequency;
      const payload = new Map<string, number>();
      payload.set('newsletterFrequency', newsletterNewFrequency);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);

         response.subscribe(() => {
            subscriber.next();
         }, () => {
            subscriber.error();
         });
      });
   }

   getUserAccountDetails(): Observable<User> {
      const url = environment.xstitchApiEndpoints.user.userAccountDetails;

      return new Observable<User>((subscriber: Subscriber<User>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<UserApiInterface>(url);

         response.subscribe((a: UserApiInterface) => {
            subscriber.next(User.constructFromInterface(a));
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   getDownloadDetailsForDownloadToken(downloadToken: string): Observable<UserCollectionItem> {
      let url = environment.xstitchApiEndpoints.user.downloadDetailsByToken;
      url = url.replace('{download-token}', downloadToken);

      return new Observable<UserCollectionItem>((subscriber: Subscriber<UserCollectionItem>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<UserCollectionItemResponseApiInterface>(url);

         response.subscribe((a: UserCollectionItemResponseApiInterface) => {
            subscriber.next(UserCollectionItem.constructFromInterface(a));
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   getUserCollection(): Observable<UserCollection> {
      const url = environment.xstitchApiEndpoints.user.userCollection;

      return new Observable<UserCollection>((subscriber: Subscriber<UserCollection>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<UserCollectionResponseApiInterface>(url);

         response.subscribe((a: UserCollectionResponseApiInterface) => {
            subscriber.next(UserCollection.constructFromInterface(a));
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   logIn(emailAddress: string, clearTextPassword: string): Observable<User> {
      const url = environment.xstitchApiEndpoints.user.logInUser;
      const payload = new Map<string, any>();
      payload.set(PNR.EMAIL, emailAddress);
      payload.set(PNR.CLEAR_TEXT_PASSWORD, clearTextPassword);

      return new Observable<User>((subscriber: Subscriber<User>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<UserApiInterface>(url, payload);

         response.subscribe((u: UserApiInterface) => {
            subscriber.next(User.constructFromInterface(u));
         }, (error: HttpErrorResponse) => {
            subscriber.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   public getCurrentUser(): Observable<User> {
      const url = environment.xstitchApiEndpoints.user.userAccountDetails;

      return new Observable((subscriber: Subscriber<any>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<UserApiInterface>(url);
         response.subscribe((e: UserApiInterface) => {
            subscriber.next(User.constructFromInterface(e));
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   resendVerificationEmail(email: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.resendVerificationEmail;

      return new Observable((subscriber: Subscriber<any>) => {
         const payload = new Map<string, any>();
         payload.set('email', email);
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);

         response.subscribe(() => {
            subscriber.next(null);
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   forgotPasswordRequest(email: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.forgotPasswordRequest;
      return new Observable((subscriber: Subscriber<any>) => {
         const payload = new Map<string, any>();
         payload.set('email', email);
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);
         response.subscribe((e: object) => {
            const report = StandardApiResponseDeprecated.constructFromHttpSuccessResponse(new HttpResponse(e));
            if (report.containsErrorItems()) {
               subscriber.error(report);
            } else {
               subscriber.next();
            }
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   resetForgottenPassword(clearTextPassword: string, resetPasswordToken: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.resetForgottenPassword;

      return new Observable<void>((subscriber: Subscriber<any>) => {
         const payload = new Map<string, any>([
            ['reset_token', resetPasswordToken],
            ['clear_text_password', clearTextPassword]
         ]);

         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);

         response.subscribe((e: object) => {
            const report = StandardApiResponseDeprecated.constructFromHttpSuccessResponse(new HttpResponse(e));
            if (report.containsErrorItems()) {
               subscriber.error(report);
            } else {
               subscriber.next(null);
            }
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   checkResetAccountPasswordTokenIsValid(resetToken: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.check_reset_password_account_token_valid + '/' + resetToken;

      return new Observable<void>((subscriber: Subscriber<any>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute(url);

         response.subscribe((e: object) => {
            const report = StandardApiResponseDeprecated.constructFromHttpSuccessResponse(new HttpResponse(e));

            if (report.containsErrorItems()) {
               subscriber.error(report);
            } else {
               subscriber.next(null);
            }
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   sendContactUsMessage(name: string, email: string, subject: string, message: string): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.contactUsMessage;

      return new Observable((subscriber: Subscriber<any>) => {
         const payload = new Map<string, any>([
            ['user_name', name],
            ['email', email],
            ['contact-us-subject', subject],
            ['contact-us-body', message]
         ]);

         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(url, payload);

         response.subscribe(() => {
            subscriber.next(null);
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   leaveCommentForProject(
      projectId: number,
      comment: string,
      showUsername: boolean,
      isPublic: boolean
   ): Observable<void> {
      const url = environment.xstitchApiEndpoints.user.leaveCommentForProject;
      const payload = new Map<string, any>();

      payload.set('projectId', projectId);
      payload.set('comment', comment);
      payload.set('showUsername', showUsername);
      payload.set('public', isPublic);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const request = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<void>(url, payload);
         request.subscribe(() => {
            subscriber.next();
         }, (error) => {
            subscriber.error(error);
         });
      });
   }
}
