import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c11-a2-mobile-app',
  templateUrl: './c11-a2-mobile-app.component.html'
})
export class C11A2MobileAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
