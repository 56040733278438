import {ShareFacebookInterface} from '../../interface/social-media-share/share-facebook-interface';
import {AbstractLanguage} from '../../../../locale/language-detector/type/abstract-language';

export class ShareFacebook {
   public constructor(
      protected shareSpanishUrl: string,
      protected shareFrenchUrl: string,
      protected shareEnglishUrl: string
   ) {
   }

   public static constructFromInterface(i: ShareFacebookInterface): ShareFacebook {
      return new ShareFacebook(
         i.shareSpanishUrl, i.shareFrenchUrl, i.shareEnglishUrl
      );
   }

   public getShareSpanishUrl(): string {
      return this.shareSpanishUrl;
   }

   public getShareFrenchUrl(): string {
      return this.shareFrenchUrl;
   }

   public getShareEnglishUrl(): string {
      return this.shareEnglishUrl;
   }

   getShareUrlForLanguage(l: AbstractLanguage): string {
      if (l.isFrench()) {
         return this.shareFrenchUrl;
      }

      if (l.isSpanish()) {
         return this.shareSpanishUrl;
      }

      return this.shareEnglishUrl;
   }
}
