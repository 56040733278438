import {DownloadPdfDetailsApiInterface} from '../interface/download-pdf-details-api-interface';
import {DownloadPdfDetail} from './download-pdf-detail';

export class DownloadPdfDetails {
   public static constructFromInterface(i: DownloadPdfDetailsApiInterface): DownloadPdfDetails {
      return new DownloadPdfDetails(
         DownloadPdfDetail.constructFromApiInterface(i.summarizedByColor.mobile),
         DownloadPdfDetail.constructFromApiInterface(i.summarizedByColor.tablet),
         DownloadPdfDetail.constructFromApiInterface(i.summarizedByColor.desktop),
         DownloadPdfDetail.constructFromApiInterface(i.summarizedByGrid.mobile),
         DownloadPdfDetail.constructFromApiInterface(i.summarizedByGrid.tablet),
         DownloadPdfDetail.constructFromApiInterface(i.summarizedByGrid.desktop),
      );
   }

   public constructor(
      private byColorMobile: DownloadPdfDetail,
      private byColorTablet: DownloadPdfDetail,
      private byColorDesktop: DownloadPdfDetail,
      private byGridMobile: DownloadPdfDetail,
      private byGridTablet: DownloadPdfDetail,
      private byGridDesktop: DownloadPdfDetail
   ) {
   }

   public getByColorMobile(): DownloadPdfDetail {
      return this.byColorMobile;
   }

   public getByColorTablet(): DownloadPdfDetail {
      return this.byColorTablet;
   }

   public getByColorDesktop(): DownloadPdfDetail {
      return this.byColorDesktop;
   }

   public getByGridMobile(): DownloadPdfDetail {
      return this.byGridMobile;
   }

   public getByGridTablet(): DownloadPdfDetail {
      return this.byGridTablet;
   }

   public getByGridDesktop(): DownloadPdfDetail {
      return this.byGridDesktop;
   }
}
