import {PublicProjectCommentInterface} from '../interface/public-project-comment-interface';
import {ApiDateTimeSimplified} from '../../type/api-date-time-simplified';

export class PublicProjectComment {

   private readonly commentId: number;
   private readonly comment: string;
   private readonly userName: string | null;
   private readonly commentDate: ApiDateTimeSimplified;

   public constructor(commentId: number, comment: string, userName: string | null, commentDate: ApiDateTimeSimplified) {
      this.commentId = commentId;
      this.comment = comment;
      this.userName = userName;
      this.commentDate = commentDate;
   }

   public static constructFromInterface(i: PublicProjectCommentInterface): PublicProjectComment {
      const date = ApiDateTimeSimplified.constructFromApi(i.commentDate);
      return new PublicProjectComment(
         i.commentId,
         i.comment,
         i.userName,
         date
      );
   }

   public getCommentId(): number {
      return this.commentId;
   }

   public getComment(): string {
      return this.comment;
   }

   public getUserName(): string | null {
      return this.userName;
   }

   public getCommentDate(): ApiDateTimeSimplified {
      return this.commentDate;
   }
}
