import {HomePageCategoryConfigurationInterface} from '../interface/home-page-category-configuration.interface';
import {TagDetailsObject} from './tag-details.object';
import {TagDetailsInterface} from '../interface/tag-details.interface';

export class HomePageCategoryConfigurationObject {

   public constructor(private mainCategories: TagDetailsObject[]) {
   }

   static constructFromApi(i: HomePageCategoryConfigurationInterface): HomePageCategoryConfigurationObject {

      const mainCategories: TagDetailsObject[] = [];

      i.mainCategories.forEach((c: TagDetailsInterface) => {
         if (c.countProjectsWithSameTag !== 0) {
            mainCategories.push(TagDetailsObject.constructFromApi(c));
         }
      });

      return new HomePageCategoryConfigurationObject(mainCategories);
   }

   getCategories(): TagDetailsObject[] {
      return this.mainCategories;
   }
}
