import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {CommonSearchBarComponent} from '../../top-navigation-bar/common-search-bar/common-search-bar.component';
import {SearchRequest} from '../../service/api-client/search-pattern/type/search-request';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-home-page-card',
   templateUrl: './home-page-card.component.html',
   styleUrls: ['../home-page.component.scss', './home-page-card.component.scss']
})
export class HomePageCardComponent implements OnInit {

   @Input() tagId: number;
   @Input() cardTitle: string;
   @Input() unsafeImgCategoryUrl: string;
   @Input() countProjectsWithSameTag: number;

   translationSheet: TranslationSheet;

   constructor(
      private router: Router,
      public domSanitizer: DomSanitizer,
      public staticDictionary: StaticDictionaryService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   searchInCategory(): void {
      const searchCriteria = new SearchRequest([], [], []);
      const route = environment.frontEndRoutes.searchResults + '?' + searchCriteria.serializeAsQueryString();

      this.router.navigateByUrl(route).then(() => {

         searchCriteria.includeTagId(this.tagId);
         CommonSearchBarComponent.searchRequestParametersSubject.next(searchCriteria);
      });
   }
}
