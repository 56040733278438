import {SimpleProjectTagInterface} from '../../interface/project-tag-interface/simple-project-tag-interface';
import {AbstractProjectTag} from './abstract-project-tag';

export class SimpleProjectTag extends AbstractProjectTag {

   private readonly countProjectsWithSameTag: number;

   public static constructFromInterface(ti: SimpleProjectTagInterface): SimpleProjectTag {
      return new SimpleProjectTag(
         ti.id,
         ti.descriptionEnglish,
         ti.descriptionSpanish,
         ti.descriptionFrench,
         ti.cssSubclass,
         ti.countProjectsWithSameTag
      );
   }

   public constructor(
      id: number,
      descriptionEnglish: string,
      descriptionSpanish: string,
      descriptionFrench: string,
      cssSubclass: string,
      countProjectWithSameTag: number
   ) {
      super(id, descriptionEnglish, descriptionSpanish, descriptionFrench, cssSubclass);
      this.countProjectsWithSameTag = countProjectWithSameTag;
   }

   getCountProjectsWithSameTag(): number {
      return this.countProjectsWithSameTag;
   }
}
