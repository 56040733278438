import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c09a2-improve-performance',
  templateUrl: './c09a2-improve-performance.component.html'
})
export class C09a2ImprovePerformanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
