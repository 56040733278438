import {Component, Input, OnInit} from '@angular/core';
import {AbstractSelfContainedModalComponent} from '../abstract-self-contained-modal/abstract-self-contained-modal.component';
import {StandardApiErrorResponse} from '../../../service/api-client/xstitch-api-gateway/type/standard-api-error-response';

@Component({
   selector: 'app-standard-api-error-modal',
   templateUrl: './standard-api-error-modal.component.html',
   styleUrls: ['./standard-api-error-modal.component.scss']
})
export class StandardApiErrorModalComponent extends AbstractSelfContainedModalComponent implements OnInit {

   @Input() apiErrorResponse: StandardApiErrorResponse;

   ngOnInit(): void {
   }

}
