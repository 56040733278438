import {TagDetailsObject} from './tag-details.object';
import {TagWithChildrenInterface} from '../interface/tag-details.interface';

export class TagDetailsWithChildrenObject extends TagDetailsObject {
   constructor(
      protected tagId: number,
      protected english: string,
      protected spanish: string,
      protected french: string,
      protected imgUrl: string,
      protected countProjectsWithSameTag: number,
      protected cssSubclass: string,
      private children: TagDetailsWithChildrenObject[]
   ) {
      super(
         tagId,
         english,
         spanish,
         french,
         imgUrl,
         countProjectsWithSameTag,
         cssSubclass
      );
   }

   static constructFromApi(i: TagWithChildrenInterface): TagDetailsWithChildrenObject {
      const chld: TagDetailsWithChildrenObject[] = [];
      i.children.forEach((c: TagWithChildrenInterface) => {
         chld.push(TagDetailsWithChildrenObject.constructFromApi(c));
      });


      return new TagDetailsWithChildrenObject(
         i.tagId,
         i.tagName.english,
         i.tagName.spanish,
         i.tagName.french,
         i.imgUrl,
         i.countProjectsWithSameTag,
         i.cssSubclass,
         chld
      );
   }

   getChildren(): TagDetailsWithChildrenObject[] {
      return this.children;
   }
}
