import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';

@Component({
   selector: 'app-terms-and-conditions-checkbox',
   templateUrl: './terms-and-conditions-checkbox.component.html',
   styleUrls: ['./terms-and-conditions-checkbox.component.scss']
})
export class TermsAndConditionsCheckboxComponent implements OnInit {

   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   translationSheet: TranslationSheet;

   private static getAgreeTermsAndConditionsControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('agree-terms-and-conditions');
   }

   private static isTermsAndConditionsValid(): boolean {
      const termsAndConditionsControl = TermsAndConditionsCheckboxComponent.getAgreeTermsAndConditionsControl() as HTMLFormElement;

      return termsAndConditionsControl.checked;
   }

   constructor(private staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   validateTermsAndConditions(): void {

      const isTermsAndConditionsValid = TermsAndConditionsCheckboxComponent.isTermsAndConditionsValid();
      const termsAndConditionsControl = TermsAndConditionsCheckboxComponent.getAgreeTermsAndConditionsControl() as HTMLFormElement;

      if (isTermsAndConditionsValid) {
         FormCommonFunctionality.setFormComponentValid(termsAndConditionsControl);
      } else {
         FormCommonFunctionality.setFormComponentInvalid(termsAndConditionsControl);
      }

      this.validationEvent.emit(isTermsAndConditionsValid);
   }
}
