import {AbstractLanguage} from './abstract-language';

export class French extends AbstractLanguage {
   public static readonly LITERAL = 'fr-FR';

   getApiLiteral(): string {
      return French.LITERAL;
   }

   getIso639Dash1Locale(): string {
      return 'fr';
   }

   getDbId(): number {
      return 3;
   }

   isEnglish(): boolean {
      return false;
   }

   isFrench(): boolean {
      return true;
   }

   isSpanish(): boolean {
      return false;
   }
}
