export class ProjectFavouritesEntry {
   private static readonly patternIdEntry = 'p';
   private readonly projectId: number;

   static unserializeFromObject(serialized: object): ProjectFavouritesEntry | null {
      const projectId = parseInt(serialized[ProjectFavouritesEntry.patternIdEntry], 10);

      if (projectId > 0) {
         return new ProjectFavouritesEntry(projectId);
      }

      return null;
   }

   constructor(projectId: number) {
      this.projectId = projectId;
   }

   getProjectId(): number {
      return this.projectId;
   }

   serializeAsObject(): object {
      const result = {};

      result[ProjectFavouritesEntry.patternIdEntry] = this.projectId;

      return result;
   }
}
