import {Injectable} from '@angular/core';
import {PatternFlagsContainer} from './object/pattern-flags-container';

@Injectable({
   providedIn: 'root'
})
export class PatternClientFlagsService {

   private static readonly PATTERN_FLAGS_STORAGE_ENTRY = 'pf';

   constructor() {
   }

   readFromStorage(): PatternFlagsContainer {
      const storage = localStorage.getItem(PatternClientFlagsService.PATTERN_FLAGS_STORAGE_ENTRY);

      if ((storage === '') || (storage === null) || (storage === undefined)) {
         return new PatternFlagsContainer();
      }

      return PatternFlagsContainer.unserialize(storage);
   }

   writeToStorage(patternFlagsContainer: PatternFlagsContainer): void {
      const serialized = patternFlagsContainer.serialize();
      localStorage.setItem(PatternClientFlagsService.PATTERN_FLAGS_STORAGE_ENTRY, JSON.stringify(serialized));
   }
}
