import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a6-delivering-ads-for-our-retail-partners',
  templateUrl: './c10a6-delivering-ads-for-our-retail-partners.component.html'
})
export class C10a6DeliveringAdsForOurRetailPartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
