import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';

@Component({
   selector: 'app-email-plain-text-body',
   templateUrl: './email-plain-text-body.component.html',
   styleUrls: ['./email-plain-text-body.component.scss']
})

export class EmailPlainTextBodyComponent implements AfterViewChecked {
   @Input() initialContent = '';
   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   private isWellFormedSubject: boolean;
   private wellFormedBody: string;
   translationSheet: TranslationSheet;

   private static getEmailTextBodyControl(): HTMLFormElement {
      return DomUtils.getNullSafeHtmlFormElementById('email-text-body-control');
   }

   private static getEmailTextBodyFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('email-text-body-feedback');
   }

   constructor(staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngAfterViewChecked(): void {
      if (this.initialContent !== '') {
         this.validateEmailTextBody(false);
      }
   }

   validateEmailTextBody(silent: boolean): void {
      const emailPlainTextBodyControl = EmailPlainTextBodyComponent.getEmailTextBodyControl();
      const emailTextBodyFeedback = EmailPlainTextBodyComponent.getEmailTextBodyFeedback();

      const subjectValue = emailPlainTextBodyControl.value;

      if ((subjectValue === null) || (subjectValue === undefined)) {
         this.isWellFormedSubject = false;
         this.validationEvent.emit(false);
         if (!silent) {
            emailTextBodyFeedback.innerText = 'Please leave us a message';
            FormCommonFunctionality.setFormComponentInvalid(emailPlainTextBodyControl);
         }
      } else {
         const sanitized = subjectValue.trim();

         this.isWellFormedSubject = true;
         if (sanitized.length === 0) {
            this.isWellFormedSubject = false;
            emailTextBodyFeedback.innerText = 'Please leave us a message';
         } else if (sanitized.length < 10) {
            this.isWellFormedSubject = false;
            emailTextBodyFeedback.innerText = 'Email body too short';
         } else if (sanitized.length > 1000) {
            emailTextBodyFeedback.innerText = 'Email body too long. Consider send us a regular email';
         }

         if (this.isWellFormedSubject) {
            FormCommonFunctionality.setFormComponentValid(emailPlainTextBodyControl);
            this.validationEvent.emit(true);
            this.wellFormedBody = sanitized;
         } else {
            if (!silent) {
               FormCommonFunctionality.setFormComponentInvalid(emailPlainTextBodyControl);
            }

            this.validationEvent.emit(false);
         }
      }
   }

   resetEmailTextBodyValidation() {
      this.validationEvent.emit(false);
      const emailTextBodyControl = EmailPlainTextBodyComponent.getEmailTextBodyControl();
      const emailTextBodyFeedback = EmailPlainTextBodyComponent.getEmailTextBodyFeedback();

      emailTextBodyFeedback.innerText = '';
      FormCommonFunctionality.resetFormComponentValidation(emailTextBodyControl);
   }

   getWellFormedEmailBody(): string {
      return this.wellFormedBody;
   }
}
