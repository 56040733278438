import {Component, EventEmitter, Output, Input, AfterViewChecked} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';

@Component({
   selector: 'app-email-subject',
   templateUrl: './email-subject.component.html',
   styleUrls: ['./email-subject.component.scss']
})
export class EmailSubjectComponent implements AfterViewChecked {

   @Input() initialContent = '';
   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   private isWellFormedSubject: boolean;
   private wellFormedSubject: string;

   translationSheet: TranslationSheet;

   private static getEmailSubjectControl(): HTMLFormElement {
      return DomUtils.getNullSafeHtmlFormElementById('email-subject-control');
   }

   private static getEmailFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlFormElementById('email-subject-feedback');
   }

   constructor(staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngAfterViewChecked(): void {
      if (this.initialContent !== '') {
         this.validateEmailSubject(false);
      }
   }

   validateEmailSubject(silent: boolean): void {
      const emailSubjectControl = EmailSubjectComponent.getEmailSubjectControl();
      const emailSubjectFeedback = EmailSubjectComponent.getEmailFeedback();
      const validationMessage = 'Please indicate the reason for contacting us';

      const subjectValue = emailSubjectControl.value;

      if ((subjectValue === null) || (subjectValue === undefined)) {
         this.isWellFormedSubject = false;
         if (!silent) {
            emailSubjectFeedback.innerText = validationMessage;
            FormCommonFunctionality.setFormComponentInvalid(emailSubjectControl);
         }
         this.validationEvent.emit(this.isWellFormedSubject);
      } else {
         const sanitized = subjectValue.trim();
         this.isWellFormedSubject = true;

         if (sanitized.length === 0) {
            emailSubjectFeedback.innerText = 'Please provide the email subject';
            this.isWellFormedSubject = false;
         } else if (sanitized.length > 100) {
            emailSubjectFeedback.innerText = 'Email subject too long';
            this.isWellFormedSubject = false;
         } else if (sanitized.length < 3) {
            emailSubjectFeedback.innerText = 'Email subject too short';
            this.isWellFormedSubject = false;
         }

         if (this.isWellFormedSubject) {
            FormCommonFunctionality.setFormComponentValid(emailSubjectControl);
            this.validationEvent.emit(true);
            this.wellFormedSubject = sanitized;
         } else {
            if (!silent) {
               FormCommonFunctionality.setFormComponentInvalid(emailSubjectControl);
            }

            this.validationEvent.emit(false);
         }
      }
   }

   resetEmailSubjectValidation() {
      this.validationEvent.emit(false);
      const emailSubjectControl = EmailSubjectComponent.getEmailSubjectControl();
      const emailSubjectFeedback = EmailSubjectComponent.getEmailFeedback();

      emailSubjectFeedback.innerText = '';
      FormCommonFunctionality.resetFormComponentValidation(emailSubjectControl);
   }

   getWellFormedSubject(): string {
      return this.wellFormedSubject;
   }
}
