import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {StandardApiErrorResponse} from '../xstitch-api-gateway/type/standard-api-error-response';

@Injectable({
   providedIn: 'root'
})

export class ApiClientUtilsService {
   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   getApiStatus(): Observable<object> {
      return new Observable<object>((s: Subscriber<any>) => {
         const endpoint = environment.xstitchApiEndpoints.admin.apiStatus;
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute<any>(endpoint);
         response.subscribe((a: any) => {
            s.next(a);
         }, () => {
            s.error();
         });
      });
   }

   checkPasswordCorrectStructure(passwordToCheck: string): Observable<void> {
      const endpoint =
         environment.xstitchApiEndpoints.client_utils.password_structure_checker +
         '?password-to-check=' + passwordToCheck;

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute(endpoint);
         return response.subscribe(() => {
            subscriber.next();
         }, error => {
            subscriber.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   checkEmailCorrectStructure(emailToCheck: string): Observable<void> {
      const endpoint =
         environment.xstitchApiEndpoints.client_utils.email_structure_checker +
         '?email-to-check=' + emailToCheck;

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute(endpoint);

         return response.subscribe(() => {
            subscriber.next();
         }, error => {
            subscriber.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   checkUsernameCorrectStructure(usernameToCheck: string): Observable<void> {
      const endpoint =
         environment.xstitchApiEndpoints.client_utils.username_structure_checker +
         '?user-name-to-check=' + usernameToCheck;

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.getAsJsonRelativeRoute(endpoint);

         return response.subscribe(() => {
            subscriber.next();
         }, error => {
            subscriber.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   logCodeSearchableFrontEndGenericError(codeLocationReference: string, message: string): Promise<void> {
      const endpoint = environment.xstitchApiEndpoints.client_utils.logFrontEndError;

      const payload: Map<string, string> = new Map<string, string>();

      payload.set('frontEndLocationReference', codeLocationReference);
      payload.set('errorDescription', message);

      return new Promise((resolve) => {
         const subs = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<void>(endpoint, payload);

         subs.subscribe(() => {
            resolve();
         });
      });
   }
}
