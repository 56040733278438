import {Component, Input, OnInit} from '@angular/core';

@Component({
   selector: 'app-sharing-bussy',
   templateUrl: './sharing-bussy.component.html',
   styleUrls: ['./sharing-bussy.component.scss']
})
export class SharingBussyComponent implements OnInit {

   @Input() id: string;

   constructor() {
   }

   ngOnInit(): void {
   }

}
