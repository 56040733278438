import {ApiDateTimeSimplifiedInterface} from '../interface/api-date-time-simplified-interface';

export class ApiDateTimeSimplified {

   public static constructFromApi(i: ApiDateTimeSimplifiedInterface): ApiDateTimeSimplified {
      return new ApiDateTimeSimplified(i.formatted.longDate);
   }

   public constructor(private formattedLongDate: string) {
   }

   public getFormattedLongDate(): string {
      return this.formattedLongDate;
   }
}
