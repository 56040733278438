import {PaletteInterface, palettes} from './palette-interface';
import {Anchor} from './anchor';
import {DMC} from './dmc';
import {Madeira} from './madeira';
import {throwError} from 'rxjs';
import {AbstractPalette} from './abstract-palette';

export class PaletteFactory {
   public static collectionFromInterfaces(si: PaletteInterface[]): AbstractPalette[] {
      const result: AbstractPalette[] = [];

      si.forEach((i: PaletteInterface) => {
         switch (i.id) {
            case palettes.ANCHOR:
               result.push(new Anchor(i.colorCount));
               break;
            case palettes.DMC :
               result.push(new DMC(i.colorCount));
               break;
            case palettes.MADEIRA:
               result.push(new Madeira(i.colorCount));
               break;
            default:
               throwError('Palette not recognized: ' + i.id);
         }
      });

      return result;
   }
}
