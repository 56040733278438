import {Component, EventEmitter, Output, Input, AfterViewChecked} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {ApiClientUtilsService} from '../../../service/api-client/api-client-utils/api-client-utils.service';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {DomUtils} from '../../../utils/dom/dom-utils';
import {environment} from '../../../../environments/environment';


@Component({
   selector: 'app-online-validated-email',
   templateUrl: './online-validated-email.component.html',
   styleUrls: ['./online-validated-email.component.scss']
})

export class OnlineValidatedEmailComponent implements AfterViewChecked {
   private validatedEmailAddress: string;
   private isWellFormedEmailCheck: boolean;
   private busy = false;
   private adHocCheck = false;
   public translationSheet: TranslationSheet;

   private lastTimeout: number | null = null;

   @Input() initialContent = '';
   @Input() controlId = '';
   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   constructor(private apiClientUtils: ApiClientUtilsService, staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngAfterViewChecked(): void {
      if ((this.initialContent !== '') && (!this.busy) && (!this.adHocCheck)) {
         this.adHocCheck = true;
         this.checkWellFormedEmailOnline(false).then(() => {
         });
      }
   }

   private getEmailControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById(this.controlId + '_email-control');
   }

   private getEmailFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById(this.controlId + '_email-feedback');
   }

   async checkWellFormedEmailOnline(silent: boolean) {
      const email = this.getEmailControl() as HTMLFormElement;
      const emailFeedback = this.getEmailFeedback();

      FormCommonFunctionality.resetFormComponentValidation(email);

      const aux = email.value;
      let emailValue: string;

      if (aux === null || aux === undefined) {
         emailValue = '';
      } else {
         emailValue = (email.value + '').trim();
      }

      if (emailValue === '') {
         this.notValidEmailAddress(emailValue, silent, emailFeedback, email);
         return;
      }

      this.busy = true;

      if (this.lastTimeout !== null) {
         clearTimeout(this.lastTimeout);
      }

      this.lastTimeout = setTimeout(() => {
         this.apiClientUtils.checkEmailCorrectStructure(emailValue).subscribe(() => {

            FormCommonFunctionality.setFormComponentValid(email);
            this.validatedEmailAddress = emailValue;
            this.isWellFormedEmailCheck = true;
            this.validationEvent.emit(true);
            this.busy = false;
         }, () => {
            this.notValidEmailAddress(emailValue, silent, emailFeedback, email);
         });
      }, environment.continuous_online_validation_request_buffer_millis);

   }

   private notValidEmailAddress(
      emailValue: string,
      silent: boolean,
      emailFeedback: HTMLElement,
      email: HTMLFormElement
   ): void {
      this.validatedEmailAddress = emailValue;
      if (!silent) {

         if (emailValue.trim() === '') {
            emailFeedback.innerText = this.translationSheet.emailAddressFormComponent.emailCompulsory;
         } else {
            emailFeedback.innerText = this.translationSheet.emailAddressFormComponent.invalidFormat;
         }

         FormCommonFunctionality.setFormComponentInvalid(email);
      }
      this.isWellFormedEmailCheck = false;
      this.validationEvent.emit(false);
      this.busy = false;
   }

   resetEmailValidation(): void {
      this.validationEvent.emit(false);
      const email = this.getEmailControl() as HTMLFormElement;
      const emailFeedback = this.getEmailFeedback();
      emailFeedback.innerText = '';

      FormCommonFunctionality.resetFormComponentValidation(email);
   }

   setExternalErrorAsHtml(message: string): void {
      const email = this.getEmailControl() as HTMLFormElement;
      const emailFeedback = this.getEmailFeedback();
      FormCommonFunctionality.resetFormComponentValidation(email);

      if (!((message === null) || (message === undefined))) {
         emailFeedback.innerHTML = message;
         FormCommonFunctionality.setFormComponentInvalid(email);
      }
   }

   getWellFormedEmail(): string {
      return this.validatedEmailAddress;
   }

   isWellFormedEmail(): boolean {
      if ((this.isWellFormedEmailCheck === null) || (this.isWellFormedEmailCheck === undefined)) {
         return false;
      }

      return this.isWellFormedEmailCheck;
   }

   isBusy(): boolean {
      return this.busy;
   }

   getIconClass(): string {
      if (this.busy) {
         return 'spinner-border spinner-border-sm';
      }

      return 'fas fa-at';
   }

   private resetText(): void {
      const usernameControl = this.getEmailControl() as HTMLFormElement;
      usernameControl.value = null;
      this.checkWellFormedEmailOnline(true).then(() => {
      });
   }

   resetControl(): void {
      this.resetEmailValidation();
      this.resetText();
   }
}
