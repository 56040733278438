import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
   selector: 'app-c14-how-to-contact-us',
   templateUrl: './c14-how-to-contact-us.component.html'
})
export class C14HowToContactUsComponent implements OnInit {

   webmasterEmailAddress = environment.email_addresses_imported.default_email_address;

   constructor() {
   }

   ngOnInit(): void {
   }

}
