import {Component, OnInit} from '@angular/core';
import {PrimaryActionComponent} from '../primary-action/primary-action.component';

@Component({
   selector: 'app-cancel-action',
   templateUrl: './cancel-action.component.html',
   styleUrls: ['./cancel-action.component.scss']
})
export class CancelActionComponent extends PrimaryActionComponent implements OnInit {
   ngOnInit(): void {
   }

   emitClickEvent() {
      this.clickEvent.emit();
   }
}
