import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
   selector: 'app-c15-our-data-protection-officer',
   templateUrl: './c15-our-data-protection-officer.component.html'
})
export class C15OurDataProtectionOfficerComponent implements OnInit {

   webmasterAddress = environment.email_addresses_imported.default_email_address;

   constructor() {
   }

   ngOnInit(): void {
   }

}
