import {CheckoutTransactionDetailsApiInterface} from '../interface/checkout-transaction-details-api-interface';
import {ApiDateTimeSimplified} from '../../type/api-date-time-simplified';

export class CheckoutTransactionDetails {
   public static constructFromInterface(i: CheckoutTransactionDetailsApiInterface): CheckoutTransactionDetails {
      return new CheckoutTransactionDetails(
         ApiDateTimeSimplified.constructFromApi(i.transactionDate),
         i.receipt,
         i.soldByGbpIncludingTax
      );
   }

   public constructor(
      private date: ApiDateTimeSimplified,
      private receipt?: string,
      private soldByGbpIncludingTax?: number
   ) {
   }

   public getDate(): ApiDateTimeSimplified {
      return this.date;
   }

   public getReceipt(): string | undefined {
      return this.receipt;
   }

   public getSoldByGbpIncludingTax(): number | undefined {
      return this.soldByGbpIncludingTax;
   }
}
