import {ProjectTemplate} from '../../service/api-client/search-pattern/type/project-template';
import {AbstractLanguage} from '../../service/locale/language-detector/type/abstract-language';
import {SharePinterest} from '../../service/api-client/search-pattern/type/social-media-share/share-pinterest';

export class SocialMediaShareUtils {

   public static openFacebookSharePopup(publicAssetUrl: string): void {
      const sharer = 'https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=' +
         publicAssetUrl + '#' + // The hash is intended to override FB cache
         '&display=popup&ref=plugin&src=share_button';
      window.open(sharer, '_blank', 'noreferrer,noopener');
   }

   public static openPinterestSharePopupForProjectTemplate(
      templateObject: ProjectTemplate,
      lan: AbstractLanguage
   ): void {
      const sharer = 'https://www.pinterest.co.uk/pin/create/button/?' +
         'guid=' + templateObject.getPinterestGuiNonce() + '&' +
         'media=' + templateObject.getPinterestShareThumbnail() + '&' +
         'url=' + templateObject.getPinterestShareForLanguage(lan);

      window.open(sharer, '_blank', 'noreferrer,noopener');
   }

   public static openPinterestSharePopup(
      share: SharePinterest,
      lan: AbstractLanguage
   ): void {
      const sharer = 'https://www.pinterest.co.uk/pin/create/button/?' +
         'guid=' + share.getGuiNonce() + '&' +
         'media=' + share.getThumbnailUrl() + '&' +
         'url=' + share.getShareUrlForLanguage(lan);

      window.open(sharer, '_blank', 'noreferrer,noopener');
   }
}
