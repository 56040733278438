import {AbstractDictionary} from './abstract-dictionary';
import {ParametrizedTranslation} from './parametrized-translation';

export class EnglishDictionary extends AbstractDictionary {

   constructor() {
      super();

      this.translationSheet = {
         yes: 'Yes',
         no: 'No',
         errorReceivingMsg: 'There was an error receiving your message.',
         tryAgainContactUs: 'Please try again. If the error persist, please send us an email instead to',
         whoopsDots: 'Whoops...',
         messageReceived: 'We\'ve successfully received your message.',
         thanks: 'Thank you',
         weWillContactYou: 'A member of our staff will contact you shortly.',
         send: 'Send',
         pleaseWaitDots: 'Please wait...',
         signUpFree: 'Sign up for free!',
         noAccountQuestionMark: 'No account?',
         privacyAndCookies: 'Privacy and Cookies Notice',
         signingNotice: 'By signing-in you agree to the XStitch Library\'s Conditions of Use & Sale. Please see our',
         cancel: 'Cancel',
         forgotQuestionMark: 'Forgot?',
         unexpectedLogin: 'Some unexpected error had happen. If the error persists, please',
         contactUsLowercase: 'contact us',
         subscribe: 'Subscribe',
         leaveComment: 'Leave us a comment',
         contactUs: 'Contact us',
         byEmail: 'By email',
         termsOfUse: 'Terms of use',
         cookiePolicy: 'Cookie policy',
         privacyNote: 'Privacy notice',
         followUs: 'Follow us',
         logIn: 'Log in',
         verify: 'You must verify your email address before you can log in. Please check your spam folder for the email',
         resend: 'Resend the verification email',
         noMatch: 'Those log in details don\'t match. Please recheck your email address and password',
         problemInWebsite: 'There seems to be a problem with the site.',
         close: 'Close',
         goToMyCollection: 'Go to my collection',
         genericErrorModal: {
            unexpectedErrorHappen: 'An unexpected error had happened, and we couldn\'t complete this request.',
            sysAdminsNotified: 'Our sys-admins have been already notified and they will look into this issue ASAP.',
            pleaseTryAgain: 'Please try again, and if the error persists, please',
            contactUs: 'contact us directly'

         },
         shoppingCart: {
            shoppingCartEmpty: 'Your shopping cart is empty',
            letStart: 'Let\'s start shopping',
            totalCheckout: 'Total checkout',
            confirm: 'Please confirm',
            remove: 'Remove',
            fromShoopingCartQuestionMark: 'from shopping cart?',
            yesRemove: 'Yes, remove',
            initiateSession: 'Initiate session',
            checkoutAsguest: 'Checkout as guest',
            completeCheckout: 'Complete checkout',
            yourShoppingCart: 'Your shopping cart',
            youAreNotLoggedIn: 'You are not logged in, but you can continue as guest.',
            youCanContinueAsGuest: 'We only require an email address where to send the generated PDFs, and your name to properly address you.',
            sendTo: 'Send to',
            progress: {
               checkingLogInStatus: 'Checking log in status',
               checkingShoppingCartContent: 'Checking cart content',
               loadingPaypalPlugin: 'Loading PayPal',
               inProgress: 'Processing payment'
            },
            optionally: {
               optionallyYouCan: 'Optionally, you can',
               login: 'log in',
               or: 'or',
               register: 'register',
               soLinked: ', so the purchase will be linked to you.'
            },
            errors: {
               paypal: {
                  genericError: 'Whoops... There was an unexpected error using PayPal gateway and the order could not be completed. The sysadmin has been automatically notified and will address the issue shortly. Please try again in few minutes.',
                  paymentDeclined: 'Whoops... PayPal declined this payment method. Please try again by choosing a different one.'
               }
            },
            success: {
               addedFreeTemplateSuccessTitle: 'Added',
               templateInYourCollection: 'The template was successfully added to your collection.',
               successTitle: 'Success',
               checkoutSuccessfullyCompleted: 'The checkout process has been successfully completed.',
               yourPaymentReferenceIs: 'Your payment reference is',
               pdfsGenerating: new ParametrizedTranslation('The PDF files are now being generated, and you will receive soon an email at %emailAddress% with the download links once they are completed.'),
               checkSpamFolder: 'Please check your spam folder if you haven\'t received in 6 hours',
               thanks: 'Thank you once more for trusting us.',
               variableGenerationTime: 'The PDF generation time vary, but it should be done before 24 h.'
            }
         },

         advancedSearch: {
            keywords: 'Keywords',
            separate: 'Separate keywords using commas',
            labels: 'Labels',
            search: 'Search',
            example: 'e.g. monet, woman with parasol',
            reset: 'Reset',
            asTree: 'Tree',
            asList: 'List'
         },

         favourites: {
            favouritesUpdatedTitle: 'Favourites updated',
            localFavouritesSynced: 'Your favourite patterns have been trasferred to your account',
            closeModal: 'Close',
            favouritesUpdatedLocally: 'Your favourite patterns set has been updated to a temporary list in this device.',
            logIn: 'Log in',
            toTransfer: 'to transfer the changes to your user account.'
         },

         topBarNavigation: {
            hello: 'Hello',
            signIn: 'Sign in',
            languageSettings: 'Language settings',
            change: 'Change',
            searchIn: 'Search in',
            categories: {
               all: 'All'
            }
         },

         searchResultsPage: {
            whoopsNoResults: 'Whoops, no results',
            explainNoResults: 'The search criteria did not produce any results.',
            tryAdvanced: 'Try the advanced search',
            notWhatYouLookingForQuestionMark: 'Not what you looking for?',
            contactUs: 'Contact us',
            describingYourIdea: 'describing your idea.',
            scrollDownForMore: 'Scroll down to load more (page',
            shareSearch: 'Share this collection',
            shareSearchDescription: 'Share the results of this search using one of the following methods:'
         },

         emailAddressFormComponent: {
            emailAddress: 'e-Mail',
            emailCompulsory: 'The email is compulsory',
            invalidFormat: 'Not valid email format'
         },
         agreeTermsAndConditionsComponent: {
            iAgree: 'I agree to the terms and conditions and the',
            youMustAgree: 'You must agree before submitting'
         },
         newsletterFormComponent: {
            newsletter: 'Newsletter',
            choose: 'Choose',
            never: 'Never',
            weekly: 'Weekly',
            monthly: 'Monthly',
            eachNew: 'Each new publication',
            explanation: 'Please select how often would you like to receive our newsletter'
         },
         registerComponent: {
            newAccount: 'New account',
            doYouHaveQuestionMark: 'Do you already have an account?',
            tryAgain: 'Please try again. If the error persists, please',
            contactUs2: 'contact us',
            tryAgainCta: 'Try again',
            congratulations: 'Congratulations',
            successfullyRegistered: 'You have successfully set up your account',
            emailSent: 'We\'ve sent you a confirmation email to your email account.',
            followInstructions: 'Please follow the instructions described there before you log in.',
            understand: 'Understand',
            registerCTA: 'Register',
            sameEmailExists: new ParametrizedTranslation('This email address is already registered. If it\'s you, try to <a href="%logInRoute%">log in</a> instead')
         },
         emailBodyComponent: {message: 'Message'},
         maxLengthTextAreaComponent: {
            pleaseWrite: 'Please write a comment',
            tooShort: 'Text is too short. Min allowed:',
            tooLong: 'Text is too long. Max allowed:',
            chars: 'chars'
         },
         emailSubjectComponent: {
            subject: 'Subject',
            indicateAReason: 'Please indicate the reason for contacting us',
            provideEmailSubject: 'Please provide the email subject',
            subjectTooLong: 'Email subject too long',
            subjectTooShort: 'Email subject too short'
         },
         usernameTextboxComponent: {
            yourName: 'your name',
            theUsernameCompulsory: 'The user name is compulsory',
            tooShort: 'The user name is too short',
            tooLong: 'The user name is too long',
            usernameInvalid: 'The user name is invalid'
         },
         contactUsModule: {
            backHome: 'Home page',
            retry: 'Try again',
            otherWays: 'Other ways of contacting',
            sendUsEmail: 'Send us an email to'
         },
         passwordFormComponent: {
            password: 'password',
            repeatPasswordPlaceholder: 'Repeat password',
            passwordIsCompulsory: 'Password is compulsory',
            passwordTooShort: 'Password is too short',
            passwordInvalid: 'Password is invalid',
            repeatSamepassword: 'Please repeat the same password',
            incorrectRepeatedPassword: 'For security reasons, please type again the new password'
         },

         patternActions: {
            shoppingCart: {
               addToShoppingCart: 'Add to basket',
               removeFromShoppingCart: 'Remove from basket'
            },
            sharePinterest: {
               share: 'Share in Pinterest',
               shared: 'Shared in Pinterest'
            },
            withAFriend: {
               share: 'Share with a friend',
               shared: 'Shared with a friend'
            },
            shareInFacebook: {
               share: 'Share in Facebook',
               shared: 'Shared in Facebook'
            },
            completeCheckout: 'Complete checkout',
            favourites: {
               add: 'Add to favourites',
               remove: 'Remove favourites'
            }
         },

         patternDetail: {
            inYourCollection: 'This template is already yours, and it can be downloaded from your private collection.',
            generalDescription: 'Advanced counted cross stitch patterns in PDF Format. Needlework and embroidery crafts for Mobile, Tablet and Laptop',
            pleaseRead: 'Please read the entire page carefully before you buy!',
            notAKit: 'It is NOT a kit. Floss and fabric NOT included. You only purchase an exclusive advanced digital counted cross stitch chart PDF file, suitable to be displayed in mobile devices, tablets and laptops.',
            limitations: 'Due to the limitations and inconsistencies of various display monitors, the colors you see on your screen may not be a totally accurate reproduction of the actual product. Screen images are intended as a guide only and should not be regarded as absolutely correct.',
            printing: 'Although it is entirely possible if you wish to do so, this chart is NOT meant to be printed due to its size and format. You will get a much better experience if you open it from your favourite device. Don\'t forget to choose the your preferred format.',
            aboutThisPattern: 'About this pattern',
            variationsTitle: 'Variations',
            variationsDescription: 'This pattern comes in different variations. Pick the one that best suits you.',
            usesSolid: 'Solid floss colors',
            howLongToComplete: 'How long does it take to complete?',
            weCanCalculate: 'We can calculate the approximate date in which this pattern would be completed if you started today. Use',
            thisCalculator: 'this calculator',
            toFindOut: 'to find out',
            colors: 'Colors',
            changesToThePatternTitle: 'Changes to the pattern',
            changesToThePatternDescription: 'You can request changes to be made to the pattern presented here. Please give us a description of what you need, and they will be processed after the purchase, at no extra cost.',
            changestoThePatternCaption: 'Description',
            relatedSearchTags: 'Related search tags',
            chooseFabric: 'Choose the fabric color',
            itWillHelpYou: 'It will help you to visualize how the final work would look like, depending on the color of the fabric you choose. Please remember, we don\'t provide materials, only digital patterns',
            thisTemplateIsFree: 'This model is',
            freeCapitalized: 'Free',
            freeLowercased: 'free',
            addToCollection: 'Add to collection',
            comments: 'Comments',
            wrote: 'wrote',
            anonymous: 'Anonymous',
            logInToComment: 'Log in to comment',
            leaveAComment: 'Leave a comment',
            public: 'Public',
            showMyName: 'Show my name',
            sendComment: 'Send',
            received: 'Comment received',
            willBeReviewed: 'Thanks for participating. A member of our team will review it shortly before making it public, if you\'ve chosen to do so.',
            loginToAddFreeTemplate: {
               please: 'Please',
               logIn: 'log in',
               toAddThisTemplateToCollection: 'to download the PDFs for this pattern',
            },
            signUpForFree: {
               signUpForFree: 'Sign up for free',
               toGetAccess: 'to get access to all free templates and receive regular updates. It only takes few seconds.'
            }
         },

         common: {
            french: 'French',
            spanish: 'Spanish',
            english: 'English',
            next: 'Next',
            previous: 'Previous',
            vatIncludedShort: 'VAT inc.',
            stitchPluralLowerCase: 'stitches',
            exit: 'Exit',
            back: 'Back',
            showMore: 'More',
            date: 'Date',
            receipt: 'Receipt',
            amount: 'Amount'
         },

         ellipsizedToggleComponent: {
            readMore: 'Read more',
            readLess: 'Read less'
         },
         deadlineCalculator: {
            deadline: 'Deadline calculator',
            yourResultsTitle: 'Your results',
            thisPatternHas: 'This pattern has',
            fullStitches: 'full stitches',
            youCanDo: 'You can do',
            stitchesPerHour: 'stitches per hour, for',
            hoursADay: 'hours a day, working',
            daysAWeek: 'days a week',
            itWillBeFinished: 'It will be finished on',
            recalculate: 'Recalculate',
            whoops: 'Whoops, some kind of error had happened',
            pleaseProvide: 'Please provide the following information',
            howManyStitches: 'How many stitches can you do in an hour?',
            warning01: 'Please introduce a positive number greater than 0 (e.g. 123)',
            howManyHoursForm: 'How many hours a day would you spend on this work',
            warning02: 'Please introduce a positive between 0 and 24 (e.g 4.5)',
            howManyDaysAWeekForm: 'How many days a week would you spend on this work',
            warning03: 'Please introduce a number between 1 and 7 (e.g. 3)',
            calculate: 'Calculate'
         },
         dimensionsCalculator: {
            dimensionsTitle: 'Dimensions',
            thisPatternIs: 'This pattern is',
            stitchesWidth: 'stitches width',
            stitchesHeight: 'stitches height. It covers',
            ofTheFabric: 'of the fabric, with',
            actualStitches: 'actual stitches in total',
            use: 'Use',
            thisCalculator: 'this calculator',
            toFindOut: 'to find out the actual dimensions depending on the fabric count used',
            theBigger: 'The bigger the fabric count, the smaller the final result is, but also the finer the detail',
            inchesShort: 'in.',
            showInCentimetres: 'Show in centimetres',
            showInInches: 'Show in inches',
            inches: 'Inches',
            centimetres: 'Centimetres',
            realDimensions: 'Real dimensions',
            thisModelMeasures: 'measures',
            widthXHeight: '(width x height) stitches. Choose the type of cloth below to know the real final dimensions.',
            chooseYourPreferredUnits: 'Choose your preferred lenght units'
         },
         resendValidationEmail: {
            resendLegendButton: 'Resend',
            resendValidationEmail: 'Resend validation email',
            pleaseGiveUs: 'Please give us the email address you used to register your account.',
            weVeSentAVerificationEmail: 'We\'ve sent a verification email to the provided address.',
            pleaseFollowTheInstructions: 'Please follow the instructions there.',
            backToLogIn: {
               backTo: 'Back to',
               logIn: 'log in',
            },
            anErrorHadHappened: 'An error had happened',
            pleaseTryAgain: {
               please: 'Please',
               tryAgain: 'try again',
               orWait: 'or wait few min.'
            },
            ifErrorPersists: {
               ifError: 'If the error persists, please',
               contactUs: 'contact us'
            }
         },
         shareWithAFriend: {
            placeholder: 'Your friend\'s name',
            shareButtonLegend: 'Share',
            pleaseIntroduceDetails: 'Please introduce the person\'s details you want to share this pattern with',
            modalCaption: 'Share this template',
            shareSuccessCaption: 'Shared',
            shareSuccessDescription: 'Thanks for sharing. We will keep working hard to provide you with interesting contents.',
            closeSuccessModalButtonLegend: 'Close'
         },
         unsubscribeEmailShare: {
            unsubscribe: 'Unsubscribe',
            willStopSending: 'Please confirm you want to stop receiving the following notifications:',
            thanksForNotifying: 'Thanks for notifying us',
            youWillNot: 'You will not receive the following notifications:',
            ifYouWantTo: 'The email you just received contains a link to reactivate those notifications. Please follow it and the change will be reverted.',
            pleaseContactUs: 'please contact us',
            backHome: 'Home',
            templateShare: 'Cross stitch patterns sent to you by your friends',
            collectionShare: 'Cross stitch collections sent to you by your friends',
            didYouChangeYourMind: 'Did you change your mind?'
         },

         resubscribeEmailShare: {
            reSubscribeTitle: 'Resubscribe',
            explanation: 'After confirmation, the system will forward notifications related to:',
            resubscribeButtonLegend: 'Re-subscribe',
            systemsEnabled: 'The following notifications associated with your e-Mail address have been reactivated:',
            welcomeBack: 'Welcome back'
         },

         verifyAccount: {
            welcome: 'Welcome!',
            logIn: 'log in',
            youSuccessfully: 'You\'ve successfully validated your account',
            youCanNow: 'You can now',
            withTheDetails: 'with the details you\'ve provided, or go to the',
            backHome: 'home page',
            apologies: 'Apologies for not being able to verify your account.',
            pleaseTryAgain: 'Please try again by opening the verification email we sent.',
            ifTheError: 'If the error persist, please contact us.',
            contactUs: 'contact us'
         },

         resetPassword: {
            reset: 'Reset',
            resetAccountPassword: 'Reset account password',
            pleaseProvide: 'Please provide the email you used to register your account.',
            resetMyAccountPassword: 'Reset my account password',
            pleaseTypeIn: 'Please type in the new password',
            changePassword: 'Change password',
            checkYourInbox: 'Check your inbox',
            weVeSent: 'We\'ve sent an email to your registered email address.',
            pleaseFollowTheInstructions: 'Please follow the instructions there to continue with the process of resetting your password.',
            ifYouHaventReceiveIt: 'If you haven\'t receive it, please check your spam folder.',
            close: 'Close',
            somethingWentWrongDots: '... something went wrong.',
            pleaseTryAgain: 'Please try again',
            ifTheErrorPersists: 'If the error persists, please',
            tryAgain: 'Try again',
            sucessExclamationMark: 'Success!!!',
            yourPasswordReset: 'Your password has been successfully reset',
            noLongerValid: 'The email you used is no longer valid and you need to request a password reset again.',
            unknownError: 'An unknown error had happen.',
            pleaseTryAgainOrContactUs: 'Please try again. If the error persists, please',
            thereWasAnError: 'There was an error.',
            weCouldnt: 'We couldn\'t reset your password.'
         },

         userDashboard: {
            emptyState: {
               emptyCollection: 'Empty collection',
               letsStart: 'Add projects'
            },
            account: 'Account',
            collection: 'Collection',
            admin: 'Admin',
            originalListing: 'Original listing',
            accountSettings: {
               description: 'In this page you can change the settings of your account',
               changeName: 'Change name',
               changePassword: 'Change password',
               changeNewsletterFrequency: 'Change newsletter',
               changeEmail: 'Change email',
               passwordChanged: 'Password updated',
               passwordResetMessage: 'The password has been successfully reset.',
               redirectionNotice: 'Your current session has been terminated. Please log in again with your new password.'
            }
         },

         downloadDetails: {
            downloadTokenNotFound: 'Not found',
            ready: 'The PDF files have been generated. Choose any of the following options to download',
            downloadInstructions: 'Template ready',
            summarizedByGrid: 'Summarized by grid',
            summarizedByColor: 'Summarized by color',
            mobile: 'Mobile',
            tablet: 'Tablet',
            desktop: 'Desktop',
            download: 'Download',
            regenerateStatus: {
               title: 'Regeneration needed',
               willBeAvailable: 'The PDF files will be available after clicking the Regenerate button.',
               regenerateButtonLegend: 'Regenerate',
               tooltip: {
                  generatedInThePast: 'The PDF files for this template have been generated in the past, but removed recently from the site to help with maintenance tasks.',
                  noWorries: 'No worries! they can be created again at no extra cost.',
                  clickInButton: 'Simply click the Regenerate button and they will be available again.'
               }
            },
            inProgressStatus: {

               inProgressTitle: 'In progress',
               generatingNow: 'The PDF files are being generated now.',
               willReceive: 'You will receive an email notification when ready.',
               eta: 'ETA',
               hourSingular: 'hour',
               hourPlural: 'hours',
               lessThan: 'less than'
            }
         },
         verification: {
            yourPdfShouldBeIn: new ParametrizedTranslation('Your PDF file should be in %language%'),
            language: 'Language',
            verifiedTitle: 'Successfully verified',
            successfullyVerified: 'The QR you scanned has been issued by us. Your PDF should contain the following information',
            detailsTitle: 'Details',
            dimensionDetails: new ParametrizedTranslation('Your project is %stitchesWidth% stitches width X %stitchesHeight% stitches height. It covers the %coveragePercentage%% of the cloth, with %totalStitches% total stitches'),
            pdfFormat: 'PDF format',
            pdfFormatDetails: new ParametrizedTranslation('Your PDF is optimized for %device%. %summaryType%.'),
            somethingNotRight: 'Something not right?',
            quoteTheFollowing: 'and quote the following reference',
            somethingNotRightContactUs: {
               predefinedSubject: 'Re. Verification ref',
               predefinedBody: new ParametrizedTranslation('Dear %branding% team, there is something wrong with my verification token')
            }
         },

         videoPromo: {
            noVideo: 'Your browser does not support the video tag.',
            videoAsset: new ParametrizedTranslation('assets/website_promo/%videoType%/english.mp4'),
            slide: {
               noSymbols: {
                  title: 'Minimalistic grid',
                  body: 'Only one symbol to designate a full stitch. Easier to follow and less eyestrain'
               },
               unlimitedColors: {
                  title: 'Unlimited palette colors',
                  body: 'All threads in a palette can be used without increasing the pattern complexity'
               },
               combinedPalettes: {
                  title: 'Combined palettes',
                  body: 'We can expand the range of available colors by combining different commercial palettes'
               },
               pdfFormats: {
                  title: 'Several formats',
                  body: 'Three formats optimized for your device: Desktop, tablet or mobile'
               },
               gridFormats: {
                  title: 'Two grid types',
                  body: 'Choose between summarized by color or summarized by grid'
               },
               allAvailableCombinations: {
                  title: 'Get all PDF combinations',
                  body: 'With each purchase, you will get access to six PDF files'
               },
               chooseLanguages: {
                  title: 'Choose your language',
                  body: 'The PDFs and website are available in some of the most world wide spoken languages'
               },
               realisticPreview: {
                  title: 'Realistic preview',
                  body: 'Get a very close preview on how the final work would look like'
               }
            }
         }
      };
   }
}
