import {Component, Input, OnInit} from '@angular/core';
import {FavouritesManagerService} from '../../service/favourites-service/favourites-manager.service';
import {AbstractFavouritesStorageType} from '../../service/favourites-service/type/favourites-storage/abstract-favourites-storage-type';
import {DomUtils} from '../../utils/dom/dom-utils';
import * as bootstrap from 'bootstrap';
import {LocalFavouritesStorage} from '../../service/favourites-service/type/favourites-storage/local-favourites-storage';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-manage-favourites',
   templateUrl: './manage-favourites.component.html',
   styleUrls: [
      './manage-favourites.component.scss',
      '../pattern-actions.component.scss'
   ]
})
export class ManageFavouritesComponent implements OnInit {
   @Input() projectId: number;
   @Input() isFavouritePattern: boolean | null; // Although it is an input parameter, it will be modified within this module
   @Input() implicitFormat = true;
   bussy: boolean;
   translationSheet: TranslationSheet;
   loginRoute = environment.frontEndRoutes.loginRoute;
   saveFavouritesTemporaryListModalId = 'save-favourites-temporary-list';

   constructor(
      private favouritesService: FavouritesManagerService,
      private staticDictionary: StaticDictionaryService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   private showModalTemporaryFavouritesListModal(): void {
      const success = DomUtils.getNullSafeHtmlElementById(this.saveFavouritesTemporaryListModalId);
      const successModal = new bootstrap.Modal(success);
      successModal.show();
   }

   ngOnInit(): void {
      this.bussy = false;
   }

   addToFavourites(): void {
      this.bussy = true;

      this.favouritesService.addProjectToFavourites(this.projectId).subscribe(
         (storage: AbstractFavouritesStorageType) => {
            this.bussy = false;

            if (storage instanceof LocalFavouritesStorage) {
               this.showModalTemporaryFavouritesListModal();
            }
         });

      this.isFavouritePattern = true;
   }

   removeFromFavourites(): void {

      this.bussy = true;

      this.favouritesService.removeProjectFromFavourites(this.projectId).subscribe(
         (storage: AbstractFavouritesStorageType) => {
            this.bussy = false;

            if (storage instanceof LocalFavouritesStorage) {
               this.showModalTemporaryFavouritesListModal();
            }
         });

      this.isFavouritePattern = false;
   }
}
