import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c03-why-we-collect-the-data',
  templateUrl: './c03-why-we-collect-the-data.component.html'
})
export class C03WhyWeCollectTheDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
