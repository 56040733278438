export abstract class AbstractLanguage {

   public abstract getDbId(): number;

   public abstract getApiLiteral(): string;

   public abstract getIso639Dash1Locale(): string;

   public abstract isSpanish(): boolean;
   public abstract isFrench(): boolean;
   public abstract isEnglish(): boolean;

   public isSameLanguage(anotherLanguage: AbstractLanguage): boolean {
      return this.getApiLiteral() === anotherLanguage.getApiLiteral();
   }
}

