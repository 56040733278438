import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a2-product-recommendations',
  templateUrl: './c10a2-product-recommendations.component.html'
})
export class C10a2ProductRecommendationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
