import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10a5-commenting',
  templateUrl: './c10a5-commenting.component.html'
})
export class C10a5CommentingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
