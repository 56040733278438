import {Component, OnInit} from '@angular/core';
import {UserService} from '../service/api-client/user/user.service';
import {ActivatedRoute} from '@angular/router';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';

@Component({
   selector: 'app-user-account-verify',
   templateUrl: './user-account-verify.component.html',
   styleUrls: ['./user-account-verify.component.scss']
})
export class UserAccountVerifyComponent implements OnInit {
   isSuccess: boolean;

   busy = true;

   translationSheet: TranslationSheet;

   loginRoute = environment.frontEndRoutes.loginRoute;

   environment = environment;

   constructor(
      private userApiService: UserService,
      private activatedRoute: ActivatedRoute,
      dictionary: StaticDictionaryService
   ) {
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      const emailAddress = this.activatedRoute.snapshot.paramMap.get('email-address');
      const verificationToken = this.activatedRoute.snapshot.paramMap.get('account-verification-token');

      if (
         (emailAddress === null) ||
         (emailAddress === undefined) ||
         (verificationToken === null) ||
         (verificationToken === undefined)
      ) {
         this.isSuccess = false;
         this.busy = false;
         return;
      }

      this.userApiService.verifyAccount(emailAddress, verificationToken).subscribe((success: boolean) => {
         this.isSuccess = success;
         this.busy = false;
      }, () => {
         this.isSuccess = false;
         this.busy = false;
      });
   }
}
