import {Component, OnInit, ViewChild} from '@angular/core';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {UserService} from '../service/api-client/user/user.service';
import {DateAndTimeUtils} from '../utils/date-and-time/date-and-time';
import {FormCommonFunctionality} from '../form-reusable-components/form-common-functionality';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';

@Component({
   selector: 'app-resend-validation-email',
   templateUrl: './resend-validation-email.component.html',
   styleUrls: ['./resend-validation-email.component.scss']
})
export class ResendValidationEmailComponent implements OnInit {


   @ViewChild(OnlineValidatedEmailComponent) onlineValidateEmailComponent: OnlineValidatedEmailComponent;
   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent;

   contentStatus = 0;

   translationSheet: TranslationSheet;
   emailValid = false;
   loginRoute = environment.frontEndRoutes.loginRoute;

   constructor(private userService: UserService, staticDictionaryService: StaticDictionaryService) {
      this.translationSheet = staticDictionaryService.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   private async waitForCurrentValidationsToComplete() {
      while (this.onlineValidateEmailComponent.isBusy()) {
         await DateAndTimeUtils.delay(100);
      }
   }

   async resendVerificationEmail() {
      this.emailValid = false;
      this.primaryActionComponent.setBusy(true);
      FormCommonFunctionality.disableFormFieldSetById('resend-validation-email-form-fieldset');

      await this.waitForCurrentValidationsToComplete();

      if (!this.onlineValidateEmailComponent.isWellFormedEmail()) {
         await this.onlineValidateEmailComponent.checkWellFormedEmailOnline(false);
         await this.waitForCurrentValidationsToComplete();
      }

      if (!this.onlineValidateEmailComponent.isWellFormedEmail()) {
         this.primaryActionComponent.setBusy(false);
         FormCommonFunctionality.enableFormFieldSetById('resend-validation-email-form-fieldset');
         return;
      }

      this.userService.resendVerificationEmail(this.onlineValidateEmailComponent.getWellFormedEmail()).subscribe(() => {
         this.primaryActionComponent.setBusy(false);
         this.contentStatus = 1;
         FormCommonFunctionality.enableFormFieldSetById('resend-validation-email-form-fieldset');
      }, error => {
         this.primaryActionComponent.setBusy(false);
         this.contentStatus = 2;
         FormCommonFunctionality.enableFormFieldSetById('resend-validation-email-form-fieldset');
      });
   }

   tryAgain(): void {
      this.contentStatus = 0;
   }
}
