import {DownloadPdfDetailApiInterface} from '../interface/download-pdf-detail-api-interface';
import {ApiDateTimeSimplified} from '../../type/api-date-time-simplified';

export class DownloadPdfDetail {
   public static constructFromApiInterface(i: DownloadPdfDetailApiInterface): DownloadPdfDetail {

      if ((i.lastDownloadDate === null) || (i.lastDownloadDate === undefined)) {
         return new DownloadPdfDetail(
            i.downloadUrl
         );
      }

      return new DownloadPdfDetail(
         i.downloadUrl,
         ApiDateTimeSimplified.constructFromApi(i.lastDownloadDate)
      );
   }

   public constructor(private downloadUrl: string, private lastDownloadDate?: ApiDateTimeSimplified) {
   }

   public getDownloadUrl(): string {
      return this.downloadUrl;
   }

   public getLastDownloadDate(): ApiDateTimeSimplified | undefined {
      return this.lastDownloadDate;
   }

   public alreadyDownloaded(): boolean {
      return !((this.lastDownloadDate === null) || (this.lastDownloadDate === undefined));
   }
}
