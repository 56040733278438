import {AbstractLanguage} from '../../../locale/language-detector/type/abstract-language';
import {French} from '../../../locale/language-detector/type/french';
import {Spanish} from '../../../locale/language-detector/type/spanish';
import {AbstractBasicProjectData} from './abstract-basic-project-data';
import {LocalisedProjectDataInterface} from '../interface/localised-project-data-interface';

export class LocalisedProjectInfo extends AbstractBasicProjectData {

   public static constructFromInterface(i: LocalisedProjectDataInterface): LocalisedProjectInfo {
      return new LocalisedProjectInfo(
         i.projectId,
         i.projectTitleEnglish,
         i.projectTitleSpanish,
         i.projectTitleFrench,
         i.descriptionEnglish,
         i.descriptionSpanish,
         i.descriptionFrench
      );
   }

   protected constructor(
      projectId: number,
      projectTitleEnglish: string,
      private readonly projectTitleFrench: string,
      private readonly projectTitleSpanish: string,
      private readonly descriptionEnglish: string | null | undefined,
      private readonly descriptionFrench: string | null | undefined,
      private readonly descriptionSpanish: string | null | undefined
   ) {
      super(projectId, projectTitleEnglish);
   }

   public getAsInterface(): LocalisedProjectDataInterface {
      return {
         projectId: this.getPatternId(),
         projectTitleEnglish: this.getProjectTitleEnglish(),
         projectTitleSpanish: this.getProjectTitleSpanish(),
         projectTitleFrench: this.getProjectTitleFrench(),
         descriptionEnglish: this.getDescriptionEnglish(),
         descriptionSpanish: this.getDescriptionSpanish(),
         descriptionFrench: this.getDescriptionFrench()
      };
   }

   getTitleForLanguage(language: AbstractLanguage): string {
      if (language instanceof French) {
         return this.projectTitleFrench;
      }

      if (language instanceof Spanish) {
         return this.projectTitleSpanish;
      }

      return this.getProjectTitleEnglish();
   }

   getDescriptionForLanguage(language: AbstractLanguage): string | null {
      let result: string | null | undefined;

      if (language instanceof French) {
         result = this.descriptionFrench;
      } else if (language instanceof Spanish) {
         result = this.descriptionSpanish;
      } else {
         result = this.descriptionEnglish;
      }

      if ((result === null) || (result === undefined)) {
         return null;
      }

      result = result.trim();

      if (result.length === 0) {
         return null;
      }

      return result.trim();
   }

   public getProjectTitleSpanish(): string {
      return this.projectTitleSpanish;
   }

   public getProjectTitleFrench(): string {
      return this.projectTitleFrench;
   }

   public getDescriptionEnglish(): string {
      return this.descriptionEnglish;
   }

   public getDescriptionSpanish(): string {
      return this.descriptionSpanish;
   }

   public getDescriptionFrench(): string {
      return this.descriptionFrench;
   }
}
