import {Injectable} from '@angular/core';
import {LanguageDetectorService} from '../language-detector/language-detector.service';
import {SpanishDictionary} from './type/spanish-dictionary';
import {EnglishDictionary} from './type/english-dictionary';
import {FrenchDictionary} from './type/french-dictionary';
import {Spanish} from '../language-detector/type/spanish';
import {French} from '../language-detector/type/french';
import {TranslationSheet} from './type/translation-sheet';
import {AbstractLanguage} from '../language-detector/type/abstract-language';

@Injectable({
   providedIn: 'root'
})
export class StaticDictionaryService {

   private readonly spanishDictionary: SpanishDictionary;
   private englishDictionary: EnglishDictionary;
   private frenchDictionary: FrenchDictionary;
   private readonly language: AbstractLanguage;

   constructor(private languageDetectorService: LanguageDetectorService) {
      this.spanishDictionary = new SpanishDictionary();
      this.englishDictionary = new EnglishDictionary();
      this.frenchDictionary = new FrenchDictionary();

      this.language = this.languageDetectorService.getLanguage();
   }

   getTranslationSheet(): TranslationSheet {
      if (this.language instanceof Spanish) {
         return this.spanishDictionary.getTranslationSheet();
      } else if (this.language instanceof French) {
         return this.frenchDictionary.getTranslationSheet();
      }

      // Default
      return this.englishDictionary.getTranslationSheet();
   }

   getLanguage(): AbstractLanguage {
      return this.language;
   }

   getSpanishTranslationSheet(): TranslationSheet {
      return this.spanishDictionary.getTranslationSheet();
   }

   getFrenchTranslationSheet(): TranslationSheet {
      return this.frenchDictionary.getTranslationSheet();
   }

   getEnglishTranslationSheet(): TranslationSheet {
      return this.englishDictionary.getTranslationSheet();
   }
}
