/**
 * @deprecated
 */
export class StandardResponseItemDeprecated {
   private readonly code: number;
   private readonly description: string;

   constructor(code: number, description: string) {
      this.code = code;
      this.description = description;
   }

   /**
    * @deprecated
    */
   static constructFromPlainObject(standardApiErrorItem: object): StandardResponseItemDeprecated {
      // @ts-ignore
      return new StandardResponseItemDeprecated(standardApiErrorItem.code, standardApiErrorItem.description);
   }

   /**
    * @deprecated
    */
   getCode(): number {
      return this.code;
   }

   /**
    * @deprecated
    */
   getDescription(): string {
      return this.description;
   }
}
