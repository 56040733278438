import {EmptyTemplateCreationDetailsInterface} from '../interface/empty-template-creation-details-interface';

export class EmptyTemplateCreationDetails {
   public static constructFromInterface(i: EmptyTemplateCreationDetailsInterface): EmptyTemplateCreationDetails {
      return new EmptyTemplateCreationDetails(
         i.projectId,
         i.templateId,
         i.templateCoreFolder
      );
   }

   public constructor(private readonly projectId: number, private readonly templateId: number, private readonly templateCoreFolder: string) {
   }

   public getProjectId(): number {
      return this.projectId;
   }

   public getTemplateId(): number {
      return this.templateId;
   }

   public getTemplateCoreFolder(): string {
      return this.templateCoreFolder;
   }
}
