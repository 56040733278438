export class DomUtils {

   public static getHtmlFormElementById(id: string): null | HTMLFormElement {
      const result = DomUtils.getHtmlElementById(id);

      if (result instanceof HTMLElement) {
         return result as HTMLFormElement;
      }

      return null;
   }

   public static getHtmlElementById(id: string): null | HTMLElement {
      const result = document.getElementById(id);

      if (result instanceof HTMLElement) {
         return result;
      }

      return null;
   }

   public static getNullSafeHtmlElementById(id: string): HTMLElement {
      const result = document.getElementById(id);

      if ((result === null) || (result === undefined)) {
         throw new Error('Element with ID not found: ' + id);
      }

      return result;
   }

   public static getNullSafeHtmlSelectElementById(id: string): HTMLSelectElement {
      const result = DomUtils.getNullSafeHtmlElementById(id);

      return result as HTMLSelectElement;
   }

   public static getNullSafeHtmlFormElementById(id: string): HTMLFormElement {
      const result = DomUtils.getNullSafeHtmlElementById(id);

      return result as HTMLFormElement;
   }

   public static getEncapsulationTokenForElementId(id: string): string {
      let encapsulationToken = '';

      const element = DomUtils.getHtmlElementById(id);
      if (element === null) {
         return encapsulationToken;
      }

      const attrs = Array.from(element.attributes);


      attrs.forEach((e: Attr) => {
         if (e.nodeName.startsWith('_ngcontent-')) {
            encapsulationToken = e.nodeName;
         }
      });

      return encapsulationToken;
   }
}
