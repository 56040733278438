import {Component, Input, OnInit} from '@angular/core';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';

@Component({
   selector: 'app-centered-spinner',
   templateUrl: './centered-spinner.component.html',
   styleUrls: ['./centered-spinner.component.scss']
})
export class CenteredSpinnerComponent implements OnInit {

   translationSheet: TranslationSheet;

   @Input() legend: string | null = null;

   constructor(staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

}
