import {ShoppingCartItemDetails} from './shopping-cart-item-details';

export class ShoppingCartContainer implements Iterable<ShoppingCartItemDetails> {
   private itemsIndexedByTemplateId: Map<number, ShoppingCartItemDetails> = new Map<number, ShoppingCartItemDetails>();

   public addItem(i: ShoppingCartItemDetails): void {
      if (!this.itemsIndexedByTemplateId.has(i.getTemplateId())) {
         this.itemsIndexedByTemplateId.set(i.getTemplateId(), i);
      }
   }

   public removeItemByTemplateId(templateId: number): void {
      if (this.itemsIndexedByTemplateId.has(templateId)) {
         this.itemsIndexedByTemplateId.delete(templateId);
      }
   }

   public calculateTotalGbp(): number {
      let result = 0;

      this.itemsIndexedByTemplateId.forEach((item: ShoppingCartItemDetails) => {
         result += item.getPriceGbpIncludingTax();
      });

      return result;
   }

   public countItems(): number {
      return this.itemsIndexedByTemplateId.size;
   }

   [Symbol.iterator](): Iterator<ShoppingCartItemDetails> {
      return this.itemsIndexedByTemplateId.values();
   }
}
