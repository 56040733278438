import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';


if (environment.production) {
   enableProdMode();
}

// Perhaps it is better to leave this always enabled to avoid unexpected bugs when moving to production. Some parts of the
// code get acces to the bound attributes, specially in the tree tag search, to inherit css styles and to avoid having to set
// the template encapsulaton to 'none'
// https://stackoverflow.com/questions/43397839/what-does-the-ng-reflect-attribute-do-in-angular2-4
// enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
   .catch(err => console.error(err));

