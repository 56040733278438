import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {LanguageDetectorService} from '../../service/locale/language-detector/language-detector.service';
import {ProjectTemplate} from '../../service/api-client/search-pattern/type/project-template';
import {SearchResultsComponent} from '../search-results.component';

@Component({
   selector: 'app-search-result-card',
   templateUrl: './search-result-card.component.html',
   styleUrls: ['./search-result-card.component.scss']
})

export class SearchResultCardComponent implements OnInit {
   private static readonly imageResizeCorrectionFactorPx = 2;
   @Input() unsecuredImgSrc: string;
   @Input() patternId: number;
   @Input() isFavouritePattern: boolean;
   @Input() patternTitle: string;

   @Input() hasFreeTag: boolean;
   @Input() isUserLoggedIn: boolean;
   @Input() templateObject: ProjectTemplate;
   @Input() showcaseImageWidthPx: number;
   @Input() showcaseImageHeightPx: number;
   @Input() isPrivatePattern: boolean;

   safeImage: SafeUrl;

   constructor(
      private domSanitizer: DomSanitizer,
      public languageDetectorService: LanguageDetectorService
   ) {
   }

   ngOnInit(): void {
      this.safeImage = this.domSanitizer.bypassSecurityTrustUrl(this.unsecuredImgSrc);
   }

   // We need to do this because the masonry layout needs to know upfront the size of the tiles, which can't be done if the
   // image is not fully loaded, or it is a 'fluid' image
   calculateImgTileWidthPx(): number {
      return SearchResultsComponent.searchItemshowcaseTileWidthPx - SearchResultCardComponent.imageResizeCorrectionFactorPx;
   }

   // We need to do this because the masonry layout needs to know upfront the size of the tiles, which can't be done if the
   // image is not fully loaded, or it is a 'fluid' image
   calculateImgTileHeight(): number {
      const widthScale = this.calculateImgTileWidthPx() / this.showcaseImageWidthPx;
      return this.showcaseImageHeightPx * widthScale;
   }
}
