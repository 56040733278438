import {AbstractProjectTag} from './abstract-project-tag';
import {AdminProjectTagInterface} from '../../interface/project-tag-interface/admin-project-tag-interface';
import {SimpleProjectTagInterface} from '../../interface/project-tag-interface/simple-project-tag-interface';

export class AdminProjectTag extends AbstractProjectTag {
   private readonly showHomePage: boolean;
   private readonly homepageImageLocation: string | null;
   private readonly parentId: number;
   private readonly projectsRelatedTag: number;

   public static constructFromInterface(i: AdminProjectTagInterface): AdminProjectTag {
      return new AdminProjectTag(
         i.id,
         i.descriptionEnglish,
         i.descriptionSpanish,
         i.descriptionFrench,
         i.cssSubclass,
         i.showHomepage,
         i.tagImgHomepageLocation,
         i.parentId,
         i.projectsRelatedTag
      );
   }

   public constructor(
      id: number,
      descriptionEnglish: string,
      descriptionSpanish: string,
      descriptionFrench: string,
      cssSubclass: string,
      showHomePage: boolean,
      homepageImageLocation: string | null,
      parentId: number,
      projectsRelatedTag: number
   ) {
      super(id, descriptionEnglish, descriptionSpanish, descriptionFrench, cssSubclass);
      this.showHomePage = showHomePage;
      this.homepageImageLocation = homepageImageLocation;
      this.parentId = parentId;
      this.projectsRelatedTag = projectsRelatedTag;
   }

   public isShowHomePage(): boolean {
      return this.showHomePage;
   }

   public getHomePageImg(): string | null {
      return this.homepageImageLocation;
   }

   public getParentId(): number {
      return this.parentId;
   }

   public getProjectsRelatedTag(): number {
      return this.projectsRelatedTag;
   }

   public getAsSimpleProjectTagInterface(): SimpleProjectTagInterface {
      return {
         countProjectsWithSameTag: this.projectsRelatedTag,
         cssSubclass: this.getCssClass(),
         descriptionEnglish: this.getDescriptionEnglish(),
         descriptionFrench: this.getDescriptionFrench(),
         descriptionSpanish: this.getDescriptionSpanish(),
         id: this.getId()
      };
   }
}
