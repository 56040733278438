import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {FormCommonFunctionality} from '../../form-common-functionality';
import {environment} from '../../../../environments/environment';
import {ApiClientUtilsService} from '../../../service/api-client/api-client-utils/api-client-utils.service';
import {Subscription} from 'rxjs';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {DomUtils} from '../../../utils/dom/dom-utils';
import {StandardApiErrorResponse} from '../../../service/api-client/xstitch-api-gateway/type/standard-api-error-response';

@Component({
   selector: 'app-online-validated-password',
   templateUrl: './online-validated-password.component.html',
   styleUrls: ['./online-validated-password.component.scss']
})
export class OnlineValidatedPasswordComponent implements OnInit {

   constructor(private apiClientUtils: ApiClientUtilsService, staticDictionary: StaticDictionaryService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   private static readonly passwordRepeatedControlId = 'password-repeated-control';

   @Input() enforceRepetition = false;
   @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

   translationSheet: TranslationSheet;

   private isWellFormedPasswordCheck = false;
   private isCorrectlyRepeatedCheck = false;

   private busy = false;
   private request: Subscription | null = null;

   private lastTimeout: number | null = null;

   private static getPasswordControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('password-control');
   }

   private static getRepeatedPasswordControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById(OnlineValidatedPasswordComponent.passwordRepeatedControlId);
   }

   private static getPasswordFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('password-feedback');
   }

   private static getPasswordRepeatedFeedback(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('password-repeated-feedback');
   }

   private static resetText(): void {
      const passwordControl = OnlineValidatedPasswordComponent.getPasswordControl() as HTMLFormElement;
      passwordControl.value = null;
   }

   private static resetRepeatedText(): void {
      const repeatedPasswordControl = DomUtils.getHtmlFormElementById(OnlineValidatedPasswordComponent.passwordRepeatedControlId);
      if (repeatedPasswordControl !== null) {
         repeatedPasswordControl.value = null;
      }
   }

   private static getCurrentPassword(): null | string {
      const onlineValidatedPasswordControl = OnlineValidatedPasswordComponent.getPasswordControl() as HTMLFormElement;
      let value = onlineValidatedPasswordControl.value;

      if (value === null) {
         return null;
      }

      value = value.trim();

      if (value === '') {
         return null;
      }

      return value;

   }

   ngOnInit(): void {
   }

   resetOnlinePasswordValidation(): void {
      const onlineValidatedPasswordControl = OnlineValidatedPasswordComponent.getPasswordControl() as HTMLFormElement;
      FormCommonFunctionality.resetFormComponentValidation(onlineValidatedPasswordControl);
      this.isWellFormedPasswordCheck = false;
      this.emitValidation();
   }

   resetRepeatedPasswordValidation(): void {
      const repeatedPasswordControl = DomUtils.getHtmlFormElementById(OnlineValidatedPasswordComponent.passwordRepeatedControlId);
      if (repeatedPasswordControl !== null) {
         FormCommonFunctionality.resetFormComponentValidation(repeatedPasswordControl);
      }

      this.isCorrectlyRepeatedCheck = false;
      this.emitValidation();
   }

   validateRepeatedPassword(): void {
      const onlineValidatedPasswordControl = OnlineValidatedPasswordComponent.getPasswordControl() as HTMLFormElement;
      const repeatedPasswordControl = OnlineValidatedPasswordComponent.getRepeatedPasswordControl() as HTMLFormElement;

      const onlineValue = onlineValidatedPasswordControl.value;
      const repeatedValue = repeatedPasswordControl.value;
      const empty = (repeatedValue === null) || (repeatedValue === undefined) || (repeatedValue.trim() === '');
      const repetitionMatch = onlineValue === repeatedValue;

      if (repetitionMatch && !empty) {
         this.isCorrectlyRepeatedCheck = true;
         this.emitValidation();
         FormCommonFunctionality.setFormComponentValid(repeatedPasswordControl);
      }

      const passwordRepeatedFeedback = OnlineValidatedPasswordComponent.getPasswordRepeatedFeedback();

      if (empty) {
         this.isCorrectlyRepeatedCheck = false;
         passwordRepeatedFeedback.innerText = this.translationSheet.passwordFormComponent.repeatSamepassword;
      }

      if (!repetitionMatch && !empty) {
         this.isCorrectlyRepeatedCheck = false;
         passwordRepeatedFeedback.innerText = this.translationSheet.passwordFormComponent.incorrectRepeatedPassword;
      }

      if (this.isCorrectlyRepeatedCheck) {
         FormCommonFunctionality.setFormComponentValid(repeatedPasswordControl);
      }
   }

   validateRepeatedPasswordFocusOutMode(): void {
      const repeatedPasswordControl = OnlineValidatedPasswordComponent.getRepeatedPasswordControl() as HTMLFormElement;

      if (!this.isCorrectlyRepeatedCheck) {
         this.emitValidation();
         FormCommonFunctionality.setFormComponentInvalid(repeatedPasswordControl);
      }
   }

   async validatePasswordOnline(silent: boolean): Promise<void> {
      if (this.request !== null) {
         this.request.unsubscribe();
      }

      const passwordControl = OnlineValidatedPasswordComponent.getPasswordControl();
      FormCommonFunctionality.resetFormComponentValidation(passwordControl);

      const passwordControlFeedback = OnlineValidatedPasswordComponent.getPasswordFeedback();

      const currentPassword = OnlineValidatedPasswordComponent.getCurrentPassword();

      if (currentPassword === null) {
         return;
      }

      if (currentPassword === '') {
         passwordControlFeedback.innerText = this.translationSheet.passwordFormComponent.passwordIsCompulsory;
         this.isWellFormedPasswordCheck = false;
         this.emitValidation();
         return;
      }

      this.busy = true;

      if (this.lastTimeout !== null) {
         clearTimeout(this.lastTimeout);
      }

      this.lastTimeout = setTimeout(
         () => {
            this.apiClientUtils.checkPasswordCorrectStructure(currentPassword).subscribe(() => {
               FormCommonFunctionality.setFormComponentValid(passwordControl);
               this.isWellFormedPasswordCheck = true;
               this.emitValidation();
               this.busy = false;
            }, (error: StandardApiErrorResponse) => {
               if (error.getCode() === environment.xstitch_api_error_codes.utils.password_check_too_short) {
                  passwordControlFeedback.innerText = this.translationSheet.passwordFormComponent.passwordTooShort;
               } else {
                  // This shouldn't really happen. The API should now exactly why the password is not valid and the
                  // client should accurately deal with each individual case scenario
                  passwordControlFeedback.innerText = this.translationSheet.passwordFormComponent.passwordInvalid;
               }

               this.busy = false;
               this.isWellFormedPasswordCheck = false;

               if (!silent) {
                  FormCommonFunctionality.setFormComponentInvalid(passwordControl);
               }

               this.emitValidation();
            });
         },
         environment.continuous_online_validation_request_buffer_millis
      );
   }

   focusOutValidationMode(): void {
      const passwordControl = OnlineValidatedPasswordComponent.getPasswordControl();

      if (!this.isWellFormedPasswordCheck && this.isWellFormedPasswordCheck) {
         FormCommonFunctionality.setFormComponentInvalid(passwordControl);
      }
   }

   getWellFormedPassword(): string {
      return OnlineValidatedPasswordComponent.getCurrentPassword() ?? '';
   }

   isWellFormedPassword(): boolean {
      return this.isWellFormedPasswordCheck;
   }

   private emitValidation(): void {
      if (this.enforceRepetition) {
         this.validationEvent.emit(this.isWellFormedPasswordCheck && this.isCorrectlyRepeatedCheck);
      } else {
         this.validationEvent.emit(this.isWellFormedPasswordCheck);
      }
   }

   resetControl(): void {

      const repeatedPasswordControl = DomUtils
         .getHtmlElementById(OnlineValidatedPasswordComponent.passwordRepeatedControlId);

      OnlineValidatedPasswordComponent.resetText();
      this.resetOnlinePasswordValidation();
      this.validatePasswordOnline(true).then();

      if (this.enforceRepetition && (repeatedPasswordControl !== null)) {
         OnlineValidatedPasswordComponent.resetRepeatedText();
         this.resetRepeatedPasswordValidation();
      }
   }

   getIconClass(): string {

      if (this.busy) {
         return 'spinner-border spinner-border-sm';
      }

      return 'fas fa-lock';
   }
}
