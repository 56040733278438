import {Component} from '@angular/core';
import {AbstractSelfContainedModalComponent} from '../abstract-self-contained-modal/abstract-self-contained-modal.component';
import {Router} from '@angular/router';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {environment} from '../../../../environments/environment';
import {French} from '../../../service/locale/language-detector/type/french';
import {Spanish} from '../../../service/locale/language-detector/type/spanish';

@Component({
   selector: 'app-generic-error-modal',
   templateUrl: './generic-error-modal.component.html',
   styleUrls: ['./generic-error-modal.component.scss']
})
export class GenericErrorModalComponent extends AbstractSelfContainedModalComponent {

   environment = environment;

   public constructor(private router: Router, staticDictionary: StaticDictionaryService) {
      super(staticDictionary);
   }

   redirectHome(): void {
      this.router.navigateByUrl(environment.frontEndRoutes.home).then(() => {
         this.hide();
      });
   }

   teamGreetings(): string {

      if (this.staticDictionary.getLanguage() instanceof French) {
         return 'L\'équipe informatique de ' + environment.branding.companyName + '.';
      }

      if (this.staticDictionary.getLanguage() instanceof Spanish) {
         return 'El equipo técnico de ' + environment.branding.companyName + '.';
      }

      return 'The ' + environment.branding.companyName + ' IT team.';
   }
}
