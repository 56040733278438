import {Component, OnInit} from '@angular/core';
import {TagDetailsObject} from './service/api-client/home-page/object/tag-details.object';
import {HomePageCategoryConfigurationObject} from './service/api-client/home-page/object/home-page-category-configuration.object';
import {HomePageService} from './service/api-client/home-page/home-page.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

   public mainCategories: TagDetailsObject[] = [];

   title = 'frontend';

   constructor(private homePageService: HomePageService) {
   }

   ngOnInit(): void {
      this.homePageService.getHomePageCategoryConfiguration().subscribe((r: HomePageCategoryConfigurationObject) => {
         this.mainCategories = r.getCategories();
      });
   }
}
