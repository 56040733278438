import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
   selector: 'app-c12-subject-access-rights',
   templateUrl: './c12-subject-access-rights.component.html'
})
export class C12SubjectAccessRightsComponent implements OnInit {

   webmasterEmailAddress = environment.email_addresses_imported.default_email_address;
   constructor() {
   }

   ngOnInit(): void {
   }

}
