import {Component, Input, OnInit} from '@angular/core';
import {CheckoutType, UserCollectionItem} from '../../../service/api-client/user/type/user-collection-item';
import {English} from '../../../service/locale/language-detector/type/english';
import {French} from '../../../service/locale/language-detector/type/french';
import {Spanish} from '../../../service/locale/language-detector/type/spanish';
import {TranslationSheet} from '../../../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../../../service/locale/static-dictionary/static-dictionary.service';
import {LanguageDetectorService} from '../../../service/locale/language-detector/language-detector.service';

@Component({
   selector: 'app-collection-item',
   templateUrl: './collection-item.component.html',
   styleUrls: [
      './collection-item.component.scss',
      '../collection.component.scss',
      '../../../search-results/search-result-card/search-result-card.component.scss'
   ]
})
export class CollectionItemComponent implements OnInit {

   @Input() item: UserCollectionItem | undefined;

   public translationSheet: TranslationSheet;

   constructor(private dictionary: StaticDictionaryService, private languageService: LanguageDetectorService) {
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   private isFreeCheckout(): boolean {
      if (this.item?.getCheckoutType() === null) {
         return false;
      }

      return this.item?.getCheckoutType() === CheckoutType.Free;
   }

   private isPayPalCheckout(): boolean {
      if (this.item?.getCheckoutType() === null) {
         return false;
      }

      return this.item?.getCheckoutType() === CheckoutType.PayPal;
   }

   public getBannerClass(): string {
      if (this.isPayPalCheckout()) {
         return 'tag paypal';
      }


      if (this.isFreeCheckout()) {
         const lan = this.languageService.getLanguage();
         if (lan instanceof English) {
            return 'tag free free_tag_english';
         }

         if (lan instanceof French) {
            return 'tag free free_tag_french';
         }

         if (lan instanceof Spanish) {
            return 'tag free free_tag_spanish';
         }
      }

      return '';
   }
}
