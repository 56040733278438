export class FormCommonFunctionality {
   public static resetFormComponentValidation(component: HTMLElement): void {
      component.classList.remove('is-valid');
      component.classList.remove('is-invalid');
   }

   public static setFormComponentInvalid(component: HTMLElement): void {
      FormCommonFunctionality.resetFormComponentValidation(component);
      component.classList.add('is-invalid');
   }

   public static setFormComponentValid(component: HTMLElement): void {
      FormCommonFunctionality.resetFormComponentValidation(component);
      component.classList.add('is-valid');
   }

   public static showHideElement(element: HTMLElement, show: boolean): void {
      if (show) {
         element.classList.remove('display-none');
      } else {
         element.classList.add('display-none');
      }
   }

   public static disableFormFieldSetById(fieldsetId: string): void {
      const fieldset = document.getElementById(fieldsetId);

      if ((fieldset === null) || (fieldset === undefined)) {
         return;
      }

      fieldset.setAttribute('disabled', 'true');
   }

   public static enableFormFieldSetById(fieldsetId: string): void {
      const fieldset = document.getElementById(fieldsetId);

      if ((fieldset === null) || (fieldset === undefined)) {
         return;
      }

      fieldset.removeAttribute('disabled');
   }
}
