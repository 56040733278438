import {SelectableOptionInterface} from '../interface/selectable-option-interface';

export class SelectableOption implements SelectableOptionInterface {

   public constructor(private readonly value: string, private readonly label: string) {
   }

   getLabel(): string {
      return this.label;
   }

   getValue(): string {
      return this.value;
   }
}
