import {AfterViewChecked, Component, Input, OnInit, ViewChild} from '@angular/core';
import {User} from '../../service/api-client/user/type/user';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {OnlineValidatedUsernameTextboxComponent} from '../../form-reusable-components/textboxes/online-validated-username-textbox/online-validated-username-textbox.component';
import {NewsletterDropdownboxComponent} from '../../form-reusable-components/dropdown-boxes/newsletter-dropdownbox/newsletter-dropdownbox.component';
import {OnlineValidatedPasswordComponent} from '../../form-reusable-components/textboxes/online-validated-password/online-validated-password.component';
import {UserService} from '../../service/api-client/user/user.service';
import {PrimaryActionComponent} from '../../form-reusable-components/buttons/primary-action/primary-action.component';
import {ModalComponent} from '../../form-reusable-components/modal/modal/modal.component';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'app-account-details',
   templateUrl: './account-details.component.html',
   styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, AfterViewChecked {
   @ViewChild(OnlineValidatedUsernameTextboxComponent) usernameControl: OnlineValidatedUsernameTextboxComponent | undefined;
   @ViewChild(NewsletterDropdownboxComponent) newsletterControl: NewsletterDropdownboxComponent | undefined;
   @ViewChild(OnlineValidatedPasswordComponent) passwordControl: OnlineValidatedPasswordComponent | undefined;

   @ViewChild('password_change_confirmation') passwordChangedSuccessPopup: ModalComponent | undefined;
   @ViewChild('changeNameButton') changeNameButton: PrimaryActionComponent | undefined;
   @ViewChild('changeAccountPasswordButton') changeAccountPasswordButton: PrimaryActionComponent | undefined;
   @ViewChild('changeNewsletterFrequencyButton') changeNewsletterFrequencyButton: PrimaryActionComponent | undefined;

   @Input() userDetails: User | null = null;

   translationSheet: TranslationSheet;

   private newsletterInitialized = false;
   passwordResetSuccessPopupId = 'password-reset-success-id';

   usernameIsValid = false;
   passwordIsValid = false;
   newsletterFrequencyIsValid = false;

   constructor(
      private staticDictionary: StaticDictionaryService,
      private userService: UserService,
      private router: Router
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   ngAfterViewChecked() {
      if ((this.userDetails !== null) && (this.newsletterControl !== undefined) && !this.newsletterInitialized) {
         this.newsletterInitialized = true;
         this.newsletterControl.setFromApiValue(this.userDetails.getNewsletterFrequencyId());
      }
   }

   changeUserName(): void {
      if (this.usernameControl !== undefined) {
         const validName = this.usernameControl.getWellFormedUsername();
         this.changeNameButton?.setBusy(true);
         this.userService.changeUserName(validName).subscribe(() => {
            this.userService.getUserAccountDetails().subscribe((d: User) => {
               this.userDetails = d;
               this.changeNameButton?.setBusy(false);
            });

            this.cancelNameChange(); // Forces to reload the new details and resets the form validation
         });
      }
   }

   changeAccountPassword(): void {
      if (this.passwordControl !== undefined) {
         const validPassword = this.passwordControl.getWellFormedPassword();

         this.changeAccountPasswordButton?.setBusy(true);

         this.userService.changeAccountPassword(validPassword).subscribe(() => {
            this.changeAccountPasswordButton?.setBusy(false);
            window.localStorage.removeItem(environment.server_session_name);
            this.passwordChangedSuccessPopup?.show();
         });
      }
   }

   cancelNameChange(): void {
      setTimeout(() => {
         this.changeNameButton?.setBusy(false);
      }, 3000);

      this.usernameControl?.resetControl();
      this.changeNameButton?.setBusy(true);
   }

   cancelNewsletterChange(): void {
      if ((this.newsletterControl !== undefined) && (this.userDetails !== null)) {
         this.newsletterControl.setFromApiValue(this.userDetails.getNewsletterFrequencyId());
         this.newsletterFrequencyIsValid = false;
      }
   }

   cancelPasswordChange(): void {
      this.passwordControl?.resetControl();
   }

   logOutAndHomeRedirect() {
      this.router.navigateByUrl(environment.frontEndRoutes.loginRoute).then(() => {
         this.passwordChangedSuccessPopup?.hide();
      });
   }

   changeNewsletterFrequency(): void {
      if (this.newsletterControl !== undefined) {
         this.changeNewsletterFrequencyButton?.setBusy(true);
         const newsletterFrequencyValue = this.newsletterControl.getValidatedNewsletterValue();
         this.userService.changeNewsletterFrequency(newsletterFrequencyValue).subscribe(() => {

            this.userService.getUserAccountDetails().subscribe((d: User) => {
               this.userDetails = d;
               this.changeNewsletterFrequencyButton?.setBusy(false);
               this.cancelNewsletterChange(); // Forces to reload the new details and resets the form validation
            });
         });
      }
   }
}
