import {ProjectFullInfo} from '../../service/api-client/project/type/project-full-info';
import {NonConstructibleCheckoutItemDetailsException} from '../exception/non-constructible-checkout-item-details-exception';
import {ProjectTemplate} from '../../service/api-client/search-pattern/type/project-template';
import {AbstractLanguage} from '../../service/locale/language-detector/type/abstract-language';

export class ShoppingCartItemDetails {

   static buildFromSearchResultObj(
      searchResultObject: ProjectFullInfo,
      language: AbstractLanguage,
      projectId: number,
      templateId: number,
      userNotes: string | null
   ): ShoppingCartItemDetails {
      if (searchResultObject.getPatternId() !== projectId) {
         throw new NonConstructibleCheckoutItemDetailsException('Not matching project ids');
      }

      const template: ProjectTemplate | null = searchResultObject.getTemplateById(templateId);

      if (template === null) {
         throw new NonConstructibleCheckoutItemDetailsException('Template ID not found in project');
      }

      return new ShoppingCartItemDetails(
         projectId,
         templateId,
         template.getFirstImageUrl(),
         template.getPriceGbpIncludingTax(),
         userNotes,
         language,
         searchResultObject
      );
   }

   constructor(
      private projectId: number,
      private templateId: number,
      private imageUrl: string,
      private priceGbpIncludingTax: number,
      private userNotes: string | null,
      private language: AbstractLanguage,
      private patternFullObject: ProjectFullInfo
   ) {
   }

   getProjectId(): number {
      return this.projectId;
   }

   getTemplateId(): number {
      return this.templateId;
   }

   getImageUrl(): string {
      return this.imageUrl;
   }

   getPriceGbpIncludingTax(): number {
      return this.priceGbpIncludingTax;
   }

   getUserNotes(): string | null {
      return this.userNotes;
   }

   getTitle(): string {
      return this.patternFullObject.getTitleForLanguage(this.language);
   }

   getTemplateObject(): ProjectTemplate {
      const templateObject = this.patternFullObject.getTemplateById(this.templateId);

      if (templateObject !== null) {
         return templateObject;
      }

      throw Error('Unexpected template not set');
   }

   getProjectShowcaseImageUrl(): string {
      return this.patternFullObject.getProjectShowboxImageUrl();
   }
}
