import {SelectableOptionInterface} from '../../../../../form-reusable-components/dropdown-boxes/interface/selectable-option-interface';

export abstract class AbstractPalette implements SelectableOptionInterface {
   protected colorCount: number;

   constructor(colorCount: number) {
      this.colorCount = colorCount;
   }

   abstract getCssClass(): string;

   getLabel(): string {
      return this.getUserFriendlyDescriptor();
   }

   abstract getValue(): string;

   abstract getUserFriendlyDescriptor(): string;

   public getColorCount(): number {
      return this.colorCount;
   }
}
