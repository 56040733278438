import {HttpErrorResponse} from '@angular/common/http';

export class StandardApiErrorResponse {
   public static constructFromInterface(i: StandardApiErrorResponse): StandardApiErrorResponse {
      return new StandardApiErrorResponse(i.code, i.message);
   }

   public static constructFromHttpErrorResponse(error: HttpErrorResponse): StandardApiErrorResponse {
      const errorBody = error.error as StandardApiErrorResponse;

      return StandardApiErrorResponse.constructFromInterface(errorBody);
   }

   public constructor(private readonly code: string, private readonly message: string) {
   }

   public getCode(): string {
      return this.code;
   }

   public getMessage(): string {
      return this.message;
   }
}
