import {AbstractFavouritesAction} from './abstract-favourites-action';

export class AddToFavourites extends AbstractFavouritesAction {
   private readonly projectId: number;

   public constructor(projectId: number) {
      super();
      this.projectId = projectId;
   }

   public getProjectId(): number {
      return this.projectId;
   }
}
