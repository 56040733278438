import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../../api-client/xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {StandardApiResponseDeprecated} from '../../api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {BlobResponse} from '../../api-client/xstitch-api-gateway/type/blob-response';

@Injectable({
   providedIn: 'root'
})
export class DownloadService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public download(relativeUrlProvidedByTheApi: string): Observable<BlobResponse> {


      return new Observable<BlobResponse>((subscriber: Subscriber<BlobResponse>) => {
         const response = this.xstitchApiGateway.getAsBlobRelativeRoute(relativeUrlProvidedByTheApi, 'downloaded-file.pdf');

         response.subscribe((a: BlobResponse) => {
            subscriber.next(a);
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }
}
