import {ShareFacebook} from './share-facebook';
import {SharePinterestInterface} from '../../interface/social-media-share/share-pinterest-interface';

export class SharePinterest extends ShareFacebook {
   public constructor(
      protected shareSpanishUrl: string,
      protected shareFrenchUrl: string,
      protected shareEnglishUrl: string,
      private guiNonce: string,
      private thumbnailUrl: string
   ) {
      super(shareSpanishUrl, shareFrenchUrl, shareEnglishUrl);
   }

   public static constructFromInterface(i: SharePinterestInterface): SharePinterest {
      return new SharePinterest(
         i.shareSpanishUrl,
         i.shareFrenchUrl,
         i.shareEnglishUrl,
         i.guiNonce,
         i.thumbnail
      );
   }

   public getGuiNonce(): string {
      return this.guiNonce;
   }

   public getThumbnailUrl(): string {
      return this.thumbnailUrl;
   }
}
