import {PdfGenerationStatusApiInterface} from '../../interface/pdf-generation-status-api-interface';
import {AbstractPdfGenerationStatusUnmanagedError} from '../../error/abstract-pdf-generation-status-unmanaged-error';
import {PdfStatusInProgress} from './pdf-status-in-progress';
import {PdfStatusGenerated} from './pdf-status-generated';
import {PdfStatusNeedsRequeue} from './pdf-status-needs-requeue';
import {UnknownPdfGenerationStatus} from '../../error/unknown-pdf-generation-status';
import {AbstractPdfGenerationStatus} from './abstract-pdf-generation-status';

export class PdfGenerationStatusFactory {
   public static constructFromInterface(i: PdfGenerationStatusApiInterface): AbstractPdfGenerationStatus {
      const type = i.type;

      switch (type) {
         case 'in-progress':
            if (i.etaInHours === undefined) {
               throw new AbstractPdfGenerationStatusUnmanagedError('Missing eta time in hours');
            }

            return new PdfStatusInProgress(i.etaInHours);
         case 'done':
            return new PdfStatusGenerated();
         case 'requeue-needed':
            return new PdfStatusNeedsRequeue();
         default:
            throw new UnknownPdfGenerationStatus();
      }
   }
}
