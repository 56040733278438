import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-navigation-bar',
  templateUrl: './top-navigation-bar.component.html',
  styleUrls: ['./top-navigation-bar.component.scss']
})
export class TopNavigationBarComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }
}
