import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c05a1-partners',
  templateUrl: './c05a1-partners.component.html'
})
export class C05a1PartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
