import {PatternFlagsItem} from './pattern-flags-item';

export class PatternFlagsContainer {
   private static readonly ITEMS_ENTRY = 'i';

   private patterFlagItems: PatternFlagsItem[] = [];

   static unserialize(serialized: string): PatternFlagsContainer {
      const result = new PatternFlagsContainer();

      const unserialized = JSON.parse(serialized);

      if (!((unserialized === null) || (unserialized === undefined))) {

         const items = unserialized.i;

         items.forEach((object) => {
            const itm = PatternFlagsItem.unserialize(object);
            if ((itm !== null) && (itm !== undefined)) {
               result.addItem(itm);
            }
         });
      }

      return result;
   }

   addItem(item: PatternFlagsItem): void {
      this.patterFlagItems.push(item);
   }

   getItemByPatternId(patternId: number): PatternFlagsItem | null {
      let result: PatternFlagsItem | null = null;

      this.patterFlagItems.forEach((itm: PatternFlagsItem) => {
         if (itm.getPatternId() === patternId) {
            result = itm;
            return;
         }
      });

      return result;
   }

   serialize(): object {
      const result = {};

      result[PatternFlagsContainer.ITEMS_ENTRY] = [];

      this.patterFlagItems.forEach((item: PatternFlagsItem) => {
         result[PatternFlagsContainer.ITEMS_ENTRY].push(item.serialize());
      });

      return result;
   }
}
