import {SearchRequestInterface} from '../interface/search-request-interface';
import {NativeDateWrapper} from '../../../../utils/date-and-time/native-date-wrapper';

export class SearchRequest {
   private readonly includeTagIdList: Array<number>;
   private readonly includePatternIdList: Array<number>;
   private readonly includeKeywordList: Array<string>;
   private zeroBasedCurrentPage;
   private readonly pageSize;

   private readonly createdFrom: NativeDateWrapper | undefined;
   private readonly createdTo: NativeDateWrapper | undefined;

   constructor(
      includeTagIdList: Array<number>,
      includePatternIdList: Array<number>,
      includeKeywordList: Array<string>,
      createdFrom?: NativeDateWrapper,
      createdTo?: NativeDateWrapper,
      zeroBasedCurrentPage?: number,
      pageSize?: number
   ) {
      this.includeTagIdList = includeTagIdList;
      this.includePatternIdList = includePatternIdList;
      this.includeKeywordList = includeKeywordList;

      if ((zeroBasedCurrentPage === null) || (zeroBasedCurrentPage === undefined) || (zeroBasedCurrentPage < 0)) {
         this.zeroBasedCurrentPage = 0;
      } else {
         this.zeroBasedCurrentPage = zeroBasedCurrentPage;
      }

      if ((pageSize === null) || (pageSize === undefined) || (pageSize < 1)) {
         this.pageSize = 8;
      } else {
         this.pageSize = pageSize;
      }

      this.createdFrom = createdFrom;
      this.createdTo = createdTo;

      if ((createdFrom !== null) && (createdFrom !== undefined) && (createdTo !== null) && (createdTo !== undefined)) {
         if ((createdFrom.greaterThan(createdTo))) {
            this.createdFrom = undefined;
            this.createdTo = undefined;
         }
      }
   }

   public static constructFromJson(parsed: any): SearchRequest {
      const includeTagIds = parsed.includeTagIds ?? [];
      const includePatternIds = parsed.includePatternIds ?? [];
      const includeKeywordList = parsed.includeKeywordList ?? [];
      const zeroBasedCurrentPage = parsed.zeroBasedCurrentPage ?? 0;
      const pageSize = parsed.pageSize ?? 1;

      return new SearchRequest(includeTagIds, includePatternIds, includeKeywordList, undefined, undefined, zeroBasedCurrentPage, pageSize);
   }

   public static constructFromInterface(i: SearchRequestInterface): SearchRequest {

      let createdFrom: NativeDateWrapper | undefined;
      let createdTo: NativeDateWrapper | undefined;

      try {
         createdFrom = NativeDateWrapper.constructFromMySqlFormat(i.createdFrom);
      } catch (e) {
         createdFrom = undefined;
      }

      try {
         createdTo = NativeDateWrapper.constructFromMySqlFormat(i.createdTo);
      } catch (e) {
         createdTo = undefined;
      }

      return new SearchRequest(
         i.includeTagIds,
         i.includePatternIds,
         i.includeKeywordList,
         createdFrom,
         createdTo,
         i.zeroBasedCurrentPage,
         i.pageSize,
      );
   }

   public static constructFromQueryString(qs: string): SearchRequest {

      const includeTagIdList: Array<number> = new Array<number>();
      const includePatternIdList: Array<number> = new Array<number>();
      const includeKeywordList: Array<string> = new Array<string>();
      let zeroBasedCurrentPage: number | undefined;
      let pageSize: number | undefined;
      let createdFrom: NativeDateWrapper | undefined;
      let createdTo: NativeDateWrapper | undefined;

      const qsNoQuestionMark = qs.replace('?', '');

      const params = new URLSearchParams(qsNoQuestionMark);

      let auxString: string | null;
      let auxInteger: number | null;

      if (params.has('includeTagIds')) {
         auxString = params.get('includeTagIds') ?? '';
         auxString.split(',').forEach((id: string) => {
            if (!isNaN(parseInt(id, 10))) {
               includeTagIdList.push(parseInt(id, 10));
            }
         });
      }

      if (params.has('includePatternIds')) {
         auxString = params.get('includePatternIds') ?? '';
         auxString.split(',').forEach((id: string) => {
            if (!isNaN(parseInt(id, 10))) {
               includePatternIdList.push(parseInt(id, 10));
            }
         });
      }

      if (params.has('includeKeywordList')) {
         auxString = params.get('includeKeywordList') ?? '';
         auxString.split(',').forEach((keyword: string) => {
            includeKeywordList.push(keyword);
         });
      }

      if (params.has('zeroBasedCurrentPage')) {
         auxString = params.get('zeroBasedCurrentPage') ?? '';
         auxInteger = parseInt(auxString, 10);

         if ((!isNaN(auxInteger)) && (auxInteger >= 0)) {
            zeroBasedCurrentPage = auxInteger;
         }
      }

      if (params.has('pageSize')) {
         auxString = params.get('pageSize') ?? '';
         auxInteger = parseInt(auxString, 10);

         if ((!isNaN(auxInteger)) && (auxInteger >= 2)) {
            pageSize = auxInteger;
         }
      }

      if (params.has('createdFrom')) {
         auxString = params.get('createdFrom') ?? '';

         try {
            createdFrom = NativeDateWrapper.constructFromMySqlFormat(auxString);
         } catch (e) {
            createdFrom = undefined;
         }
      }

      if (params.has('createdTo')) {
         auxString = params.get('createdTo') ?? '';

         try {
            createdTo = NativeDateWrapper.constructFromMySqlFormat(auxString);
         } catch (e) {
            createdTo = undefined;
         }
      }

      return new SearchRequest(
         includeTagIdList,
         includePatternIdList,
         includeKeywordList,
         createdFrom,
         createdTo,
         zeroBasedCurrentPage,
         pageSize
      );
   }

   public setNextPage(totalPageCount: number): boolean {
      if (this.zeroBasedCurrentPage < (totalPageCount - 1)) {
         this.zeroBasedCurrentPage++;
         return true;
      }

      return false;
   }

   public setPreviousPage(totalPageCount: number): boolean {
      if (this.zeroBasedCurrentPage === 0) {
         return false;
      }

      if (this.zeroBasedCurrentPage > totalPageCount) {
         // The current page is already out of boundaries, even decreasing it by one
         return false;
      }

      this.zeroBasedCurrentPage--;
      return true;

   }

   public getZeroBasedCurrentPage(): number {
      return this.zeroBasedCurrentPage;
   }

   public getPageSize(): number {
      return this.pageSize;
   }

   public includeTagId(tagId: number): void {
      if (tagId >= 1) {
         this.includeTagIdList.push(tagId);
      }
   }

   public includePatternId(patternId: number): void {
      if (patternId >= 1) {
         this.includePatternIdList.push(patternId);
      }
   }

   public includePatternIds(patternIds: Array<number>): void {
      patternIds.forEach((patternId: number): void => {
         this.includePatternId(patternId);
      });
   }

   public includeKeyword(keyword: string): void {
      if (keyword.trim() !== '') {
         this.includeKeywordList.push(keyword.trim());
      }
   }

   public includeCommaSeparatedKeywordList(keywordList: string): void {
      const words = keywordList.split(',');

      words.forEach((w: string): void => {
         this.includeKeyword(w);
      });
   }

   public getIncludeTagIds(): Array<number> {
      return this.includeTagIdList;
   }

   public getIncludePatternIds(): Array<number> {
      return this.includePatternIdList;
   }

   public getIncludeKeywordList(): Array<string> {
      return this.includeKeywordList;
   }

   public serializeAsQueryString(): string {

      const includeTagIds = this.includeTagIdList.join(',');
      const includePatternIds = this.includePatternIdList.join(',');
      const includeKeywordList = this.includeKeywordList.join(',');

      let createdFrom = '';
      let createdTo = '';

      if (this.createdFrom) {
         createdFrom = this.createdFrom.formatAsMySqlDate();
      }

      if (this.createdTo) {
         createdTo = this.createdTo.formatAsMySqlDate();
      }


      return 'includeTagIds=' + includeTagIds +
         '&includePatternIds=' + includePatternIds +
         '&includeKeywordList=' + includeKeywordList +
         '&zeroBasedCurrentPage=' + this.zeroBasedCurrentPage.toString() +
         '&pageSize=' + this.pageSize.toString() +
         '&createdFrom=' + createdFrom +
         '&createdTo=' + createdTo;
   }


   public serializeAsJson(setCurrentPageToZero: boolean): object {

      let createdFromValue = '';
      let createdToValue = '';

      if (this.createdFrom) {
         createdFromValue = this.createdFrom.formatAsMySqlDate();
      }

      if (this.createdTo) {
         createdToValue = this.createdTo.formatAsMySqlDate();
      }

      return {
         includeTagIds: this.includeTagIdList,
         includePatternIds: this.includePatternIdList,
         includeKeywordList: this.includeKeywordList,
         zeroBasedCurrentPage: setCurrentPageToZero ? 0 : this.zeroBasedCurrentPage,
         pageSize: this.pageSize,
         createdFrom: createdFromValue,
         createdTo: createdToValue
      };
   }

   public serializePayloadForRequest(): Map<string, any> {
      let createdFromValue: string | null = null;
      let createdToValue: string | null = null;

      if (this.createdFrom) {
         createdFromValue = this.createdFrom.formatAsMySqlDate();
      }

      if (this.createdTo) {
         createdToValue = this.createdTo.formatAsMySqlDate();
      }

      const payload: Map<string, any> = new Map<string, any>();
      payload.set('includeTagIds', this.getIncludeTagIds());
      payload.set('includePatternIds', this.getIncludePatternIds());
      payload.set('includeKeywordList', this.getIncludeKeywordList());
      payload.set('zeroBasedCurrentPage', this.getZeroBasedCurrentPage());
      payload.set('pageSize', this.getPageSize());
      payload.set('createdFrom', createdFromValue);
      payload.set('createdTo', createdToValue);

      return payload;
   }
}
