import {AbstractDictionary} from './abstract-dictionary';
import {ParametrizedTranslation} from './parametrized-translation';

export class SpanishDictionary extends AbstractDictionary {

   constructor() {
      super();

      this.translationSheet = {
         yes: 'Sí',
         no: 'No',
         errorReceivingMsg: 'Ha ocurrido un error al recibir su mensaje.',
         tryAgainContactUs: 'Por favor, inténtelo otra vez. Si el error periste, envíenos un e-mail en su lugar, a',
         whoopsDots: 'Ooops...',
         messageReceived: 'Hemos recibido su mensaje correctamente.',
         thanks: 'Gracias',
         weWillContactYou: 'Un miembro de nuestro equipo se pondrá en contacto con Vd. en breve.',
         send: 'Enviar',
         pleaseWaitDots: 'Por favor, espere...',
         signUpFree: 'Inscríbase sin coste!',
         noAccountQuestionMark: '¿No tiene cuenta?',
         privacyAndCookies: 'Política de Privacidad y Cookies',
         signingNotice: 'Accediendo a su cuenta, Vd. acepta las condiciones de uso y venta de XStitch Library. Por favor, visite nuestra',
         cancel: 'Cancelar',
         forgotQuestionMark: '¿Se olvidó?',
         unexpectedLogin: 'Ha ocurrido un error inesperado. Si el error persiste, por favor',
         contactUsLowercase: 'contáctenos',
         subscribe: 'Subscribirme',
         leaveComment: 'Déjenos un comentario',
         contactUs: 'Cómo contactar',
         byEmail: 'Por correo electrónico',
         termsOfUse: 'Términos de uso',
         cookiePolicy: 'Políticas de cookies',
         privacyNote: 'Políticas de privacidad',
         followUs: 'Síganos',
         logIn: 'Mi cuenta',
         verify: 'Debe verificar su cuenta de correo antes de accder al área de clientes. Por favor, compruebe el email que le enviamos en su carpeta de correo no deseado',
         resend: 'Reenviar email de verificación',
         noMatch: 'Los detalles proporcionados no se encuentran registrados en la base de datos. Por favor, compruebe su dirección de correo electrónico y su contraseña',
         close: 'Cerrar',
         goToMyCollection: 'Ir a mi colección',

         patternActions: {
            shoppingCart: {
               addToShoppingCart: 'Añadir a la cesta',
               removeFromShoppingCart: 'Retirar de la cesta'
            },
            sharePinterest: {
               share: 'Compartir en Pinterest',
               shared: 'Compartido en Pinterest'
            },
            withAFriend: {
               share: 'Compartir con un amigo',
               shared: 'Compartido con un amigo'
            },
            shareInFacebook: {
               share: 'Compartir en Facebook',
               shared: 'Compartido en Facebook'
            },
            completeCheckout: 'Completar la compra',
            favourites: {
               add: 'Añadir a favoritos',
               remove: 'Quitar de favoritos'
            }
         },

         shoppingCart: {
            shoppingCartEmpty: 'Su carrito está vacío',
            letStart: 'Vayamos de compras',
            totalCheckout: 'Pago total',
            confirm: 'Por favor, confirme',
            remove: 'Eliminar',
            fromShoopingCartQuestionMark: 'del carrito?',
            yesRemove: 'Sí, eliminar',
            initiateSession: 'Iniciar sesión',
            checkoutAsguest: 'Completar como invitado',
            completeCheckout: 'Completar la compra',
            yourShoppingCart: 'Su cesta',
            youAreNotLoggedIn: 'No ha iniciado sesión, pero puede continuar como invitado.',
            youCanContinueAsGuest: 'indique una dirección de correo electrónico a la que enviar los archivos PDF generados, y su nombre para dirigirnos a Vd. correctamente. ',
            sendTo: 'Enviar a',
            optionally: {
               optionallyYouCan: 'Opcionalmente,',
               login: 'inicie sesión',
               or: ', o',
               register: 'cree una cuenta',
               soLinked: ', y así la compra quedará vinculada a Vd.'
            },
            errors: {
               paypal: {
                  genericError: 'Vaya... Ha ocurrido un error imprevisto al utilizar la pasarela de pago PayPal y no se pudo completar el pedido. El administrador del sistema ha sido notificado automáticamente e intentará solucionar el problema lo más pronto posible. Inténtelo de nuevo en unos minutos.',
                  paymentDeclined: 'Vaya... PayPal ha declinado esta forma de pago. Por favor, inténtelo de nuevo eligiendo una distinta'
               }
            },
            progress: {
               checkingLogInStatus: 'Comprobando inicio de sesión',
               checkingShoppingCartContent: 'Comprobando cesta',
               loadingPaypalPlugin: 'Cargando PayPal',
               inProgress: 'Procesando pago'
            },
            success: {
               addedFreeTemplateSuccessTitle: 'Añadida',
               templateInYourCollection: 'La plantilla ha sido añadida a su collección.',
               successTitle: 'Completado',
               checkoutSuccessfullyCompleted: 'La orden de pago ha sido completada correctamente.',
               yourPaymentReferenceIs: 'Su referencia de pago es',
               pdfsGenerating: new ParametrizedTranslation('Los archivos PDF ahora se están generando y pronto recibirá un correo electrónico en %emailAddress% con los enlaces de descarga una vez completados.'),
               checkSpamFolder: 'Por favor revise su carpeta de spam si no lo ha recibido en 6 horas.',
               thanks: 'Gracias una vez más por confiar en nosotros.',
               variableGenerationTime: 'El tiempo de generación del PDF varía, pero se debe realizar antes de las 24 h.'
            }
         },

         advancedSearch: {
            keywords: 'Palabras clave',
            separate: 'Separe las palabras clave usando comas',
            labels: 'Etiquetas',
            search: 'Buscar',
            example: 'ejemplo: monet, mujer con sombrilla',
            reset: 'Reinicializar',
            asTree: 'Árbol',
            asList: 'Lista'
         },

         topBarNavigation: {
            hello: 'Hola',
            signIn: 'Cuenta',
            languageSettings: 'Configuración de lenguaje',
            change: 'Cambiar',
            searchIn: 'Buscar en',
            categories: {
               all: 'Todo'
            }
         },

         searchResultsPage: {
            whoopsNoResults: 'Vaya, no hay resultados',
            explainNoResults: 'El criterio de búsqueda no ha producido ningún resultado.',
            tryAdvanced: 'Prueba la búsqueda avanzada',
            notWhatYouLookingForQuestionMark: 'No es lo que busca?',
            contactUs: 'Póngase en contacto con nosotros',
            describingYourIdea: 'describiendo su idea.',
            scrollDownForMore: 'Deslice hacia abajo para ver más (página',
            shareSearch: 'Compartir esta colección',
            shareSearchDescription: 'Comparta los resultados de esta búsqueda usando uno de los siguientes métodos:'
         },

         favourites: {
            favouritesUpdatedTitle: 'Favoritos actualizados',
            localFavouritesSynced: 'Sus patrones favoritos han sido transferidos a su cuenta',
            closeModal: 'Cerrar',
            favouritesUpdatedLocally: 'Su conjunto de patrones favoritos se ha actualizado en una lista temporal en este dispositivo.',
            logIn: 'Inicie sesión',
            toTransfer: 'para transferir los cambios a su cuenta de usuario.'
         },

         emailAddressFormComponent: {
            emailAddress: 'e-Mail',
            emailCompulsory: 'El correo electrónico es obligatorio',
            invalidFormat: 'Formato de correo inválido'
         },
         agreeTermsAndConditionsComponent: {
            iAgree: 'Acepto los términos y condiciones y la',
            youMustAgree: 'Debe aceptar los términos antes de enviar'
         },
         newsletterFormComponent: {
            newsletter: 'Boletín',
            choose: 'Elegir',
            never: 'Nunca',
            weekly: 'Semanalmente',
            monthly: 'Mensualmente',
            eachNew: 'Cada nueva publicación',
            explanation: 'Por favor, indique con qué frecuencia desearía recibir nuestro boletín informativo'
         },
         problemInWebsite: 'Parece que hay un problema en nuestra web.',
         registerComponent: {
            newAccount: 'Nueva cuenta',
            doYouHaveQuestionMark: 'Ya dispone de cuenta?',
            tryAgain: 'Por favor, inténtelo otra vez. Si el error persiste, por favor',
            contactUs2: 'contáctenos',
            tryAgainCta: 'Reintentar',
            congratulations: 'Enhorabuena',
            successfullyRegistered: 'Su cuenta ha sido creada correctamente.',
            emailSent: 'Hemos enviado un correo electrónico de confirmación a su cuenta de correo.',
            followInstructions: 'Por favor, siga las instrucciones en ese correo antes de acceder a su cuenta en esta web.',
            understand: 'Entiendo',
            registerCTA: 'Registrarme',
            sameEmailExists: new ParametrizedTranslation('Este e-Mail ya está registrado. Si es el suyo, intente <a href="%logInRoute%">iniciar la sesión</a>')
         },
         emailBodyComponent: {message: 'Mensaje'},
         emailSubjectComponent: {
            subject: 'Asunto',
            indicateAReason: 'Por favor, indique la razón',
            provideEmailSubject: 'Por favor, indique el asunto',
            subjectTooLong: 'Asunto demasiado largo',
            subjectTooShort: 'Asunto demasiado corto'
         },
         maxLengthTextAreaComponent: {
            pleaseWrite: 'Por favor, escriba un comentario',
            tooShort: 'El texto es demasiado corto. Mínimo autorizado:',
            tooLong: 'El texto es demasiado largo. Máximo autorizado:',
            chars: 'caracteres'
         },
         usernameTextboxComponent: {
            yourName: 'su nombre',
            theUsernameCompulsory: 'Su nombre es obligatorio',
            tooShort: 'El nombre de usuario es demasiado corto',
            tooLong: 'El nombre de usuario es demasiado largo',
            usernameInvalid: 'El nombre de usuario es inválido'
         },
         contactUsModule: {
            backHome: 'Volver al inicio',
            retry: 'Reintentar',
            otherWays: 'Otras formas de contacto',
            sendUsEmail: 'Envíenos un email a'
         },
         passwordFormComponent: {
            password: 'contraseña',
            repeatPasswordPlaceholder: 'Repita la contraseña',
            passwordIsCompulsory: 'La contraseña es obligatoria',
            passwordTooShort: 'La contraseña es demasiado corta',
            passwordInvalid: 'La contraseña es inválida',
            repeatSamepassword: 'Por favor, reescriba la contraseña',
            incorrectRepeatedPassword: 'Por razones de seguridad, por favor reescriba de nuevo la contraseña'
         },
         patternDetail: {
            inYourCollection: 'Esta plantilla ya es suya y puede ser descargada desde su colección privada.',
            generalDescription: 'Patrones avanzados de punto de cruz en formato PDF. Manualidades de costura y bordado para Móvil, Tablet y Portátil',
            pleaseRead: 'Por favor, lea atentamente antes de comprar!',
            notAKit: 'No es un kit. Hilo y tejido NO incluídos. La compra solo incluye un fichero PDF con un exclusivo y avanzado diagrama de patrón de punto de cruz, adaptado para móviles, tablets, portátiles u ordenadores de sobremesa.',
            limitations: 'Debido a las limitaciones e inconsistencias de varios monitores, es posible que los colores que ve en su pantalla no sean una reproducción totalmente precisa del producto real. Las imágenes de pantalla son solo una guía y no deben considerarse absolutamente correctas.',
            printing: 'Aunque es completamente posible si lo desea, este gráfico NO está destinado a ser impreso debido a su tamaño y formato. Obtendrá una mejor experiencia si lo abre desde su dispositivo favorito. No olvide elegir el formato que prefiera.',
            aboutThisPattern: 'Acerca de este patrón',
            variationsTitle: 'Variantes',
            variationsDescription: 'Existen diferentes variantes para este patrón. Elija el que mejor se adapte a sus necesidades.',
            usesSolid: 'Colores sólidos.',
            howLongToComplete: '¿Cuánto tiempo me llevaría completarlo?',
            weCanCalculate: 'Podemos calcular la fecha aproximada en la que se completaría este patrón si empezase hoy. Utilice',
            thisCalculator: 'esta calculadora',
            toFindOut: 'para averiguar',
            colors: 'Colores',
            changesToThePatternTitle: 'Cambios en el patrón',
            changesToThePatternDescription: 'Puede solicitar cambios en el patrón que se presenta aquí. Indíquenos lo que necesita y se procesarán después de la compra, sin costo adicional.',
            changestoThePatternCaption: 'Descripción de los cambios',
            relatedSearchTags: 'Etiquetas de búsqueda asociadas',
            chooseFabric: 'Elija el color de la tela',
            itWillHelpYou: 'Le ayudará a visualizar cómo quedaría el trabajo final, dependiendo del color de la tela que elija. Recuerde, no proporcionamos materiales, solo patrones digitales.',
            thisTemplateIsFree: 'Este modelo es',
            freeCapitalized: 'Gratis',
            freeLowercased: 'gratis',
            addToCollection: 'Añadir a la colección',
            comments: 'Comentarios',
            wrote: 'escribió',
            anonymous: 'Anónimo',
            logInToComment: 'Inice sesión para comentar',
            leaveAComment: 'Escriba un comentario',
            public: 'Público',
            showMyName: 'Mostrar mi nombre',
            sendComment: 'Enviar',
            received: 'Comentario recibido',
            willBeReviewed: 'Gracias por participar. Un miembro de nuestro equipo lo revisará antes de publicarlo, si ha elegido hacerlo.',
            loginToAddFreeTemplate: {
               please: 'Por favor,',
               logIn: 'inicie sesión',
               toAddThisTemplateToCollection: 'para descargar los PDFs de este patrón'
            },
            signUpForFree: {
               signUpForFree: 'Regístrese gratis',
               toGetAccess: 'para obtener acceso a todas las plantillas gratuitas y recibir actualizaciones periódicas. Sólo lleva unos segundos.'
            }
         },

         genericErrorModal: {
            unexpectedErrorHappen: 'Se produjo un error inesperado y no pudimos completar esta solicitud.',
            sysAdminsNotified: 'Nuestros administradores de sistemas ya han sido notificados e investigarán este problema lo antes posible.',
            pleaseTryAgain: 'Inténtelo de nuevo y, si el error persiste',
            contactUs: 'contáctenos directamente.'
         },

         common: {
            french: 'Francés',
            spanish: 'Español',
            english: 'Inglés',
            next: 'Siguiente',
            previous: 'Anterior',
            vatIncludedShort: 'IVA inc.',
            stitchPluralLowerCase: 'puntadas',
            exit: 'Salir',
            back: 'Atrás',
            showMore: 'Más',
            date: 'Fecha',
            receipt: 'Recibo',
            amount: 'Importe'
         },

         ellipsizedToggleComponent: {
            readMore: 'Leer más',
            readLess: 'Ocultar'
         },
         deadlineCalculator: {
            deadline: 'Calculadora de fecha',
            yourResultsTitle: 'Sus resultados',
            thisPatternHas: 'Este patrón tiene',
            fullStitches: 'puntadas completas',
            youCanDo: 'Vd. puede hacer',
            stitchesPerHour: 'puntadas por hora, durante',
            hoursADay: 'horas al día, dedicando',
            daysAWeek: 'días a la semana',
            itWillBeFinished: 'Será finalizado el',
            recalculate: 'Recalcular',
            whoops: 'Vaya, ha ocurrido algún tipo de error.',
            pleaseProvide: 'Por favor, indíquenos los siguientes datos',
            howManyStitches: '¿Cuántas puntadas puede hacer en una hora?',
            warning01: 'Introduzca un número positivo mayor que 0 (por ejemplo, 123)',
            howManyHoursForm: '¿Cuántas horas al día dedicaría a este trabajo?',
            warning02: 'Introduzca un positivo entre 0 y 24 (por ejemplo, 4.5)',
            howManyDaysAWeekForm: '¿Cuántos días a la semana dedicaría a este trabajo?',
            warning03: 'Introduzca un número entre 1 y 7 (por ejemplo, 3)',
            calculate: 'Calcular'
         },
         dimensionsCalculator: {
            dimensionsTitle: 'Dimensiones',
            thisPatternIs: 'Este patrón tiene',
            stitchesWidth: 'puntadas de largo',
            stitchesHeight: 'puntos de alto. Cubre el',
            ofTheFabric: 'del tejido, con',
            actualStitches: 'puntadas reales en total',
            use: 'Use',
            thisCalculator: 'esta calculadora',
            toFindOut: 'para averiguar las dimensiones reales en función del número de tejido utilizado',
            theBigger: 'Cuanto mayor sea el número del tejido, más pequeño será el resultado final, pero también más fino será el detalle.',
            inchesShort: 'pl.',
            showInCentimetres: 'Mostrar en centímetros',
            showInInches: 'Mostrar en pulgadas',
            inches: 'Pulgadas',
            centimetres: 'Centímetros',
            realDimensions: 'Dimensiones reales',
            thisModelMeasures: 'mide',
            widthXHeight: '(ancho x alto) puntadas. Elija el tipo de tela para calcular las dimensiones reales',
            chooseYourPreferredUnits: 'Elija las unidades de longitud preferidas.'
         },
         resendValidationEmail: {
            resendLegendButton: 'Reenviar',
            resendValidationEmail: 'Reenviar el email de verificación',
            pleaseGiveUs: 'Por favor, proporcione la cuenta de correo que usó para registrarse',
            weVeSentAVerificationEmail: 'Hemos enviado un e-Mail a su cuenta de correo.',
            pleaseFollowTheInstructions: 'Por favor, siga las instrucciones allí descritas',
            backToLogIn: {
               backTo: 'Volver a',
               logIn: 'identificarse',
            },
            anErrorHadHappened: 'Ha ocurrido un error',
            pleaseTryAgain: {
               please: 'Por favor,',
               tryAgain: 'inténtelo de nuevo',
               orWait: 'o espere unos minutos.'
            },
            ifErrorPersists: {
               ifError: 'Si el error persiste, por favor',
               contactUs: 'contacte con nosotros'
            }
         },
         shareWithAFriend: {
            placeholder: 'El nombre de la persona',
            shareButtonLegend: 'Compartir',
            pleaseIntroduceDetails: 'Por favor, introduzca los datos de la persona con quien desea compartir este modelo',
            modalCaption: 'Compartir este modelo',
            shareSuccessCaption: 'Compartido',
            shareSuccessDescription: 'Gracias por compartir. Seguiremos trabajando, para generar contenido de utilidad para Vd.',
            closeSuccessModalButtonLegend: 'Cerrar'
         },
         unsubscribeEmailShare: {
            unsubscribe: 'Darse de baja',
            willStopSending: 'Por favor, confirme que desea dejar de recibir las siguientes notificaciones:',
            thanksForNotifying: 'Gracias por notificarnos',
            youWillNot: 'Vd. dejará de recibir las siguientes notificationes:',
            templateShare: 'Patrones de punto de cruz enviados por sus amigos',
            collectionShare: 'Collecciones de punto de cruz enviados por sus amigos',
            ifYouWantTo: 'El e-Mail que ha recibido contiene un enlace para reactivar las notificaciones. Por favor, haga click en ese enlace para anular este cambio.',
            pleaseContactUs: 'por favor contáctenos',
            backHome: 'Inicio',
            didYouChangeYourMind: '¿Cambió de idea?'
         },

         resubscribeEmailShare: {
            reSubscribeTitle: 'Re-subscribir',
            explanation: 'Una vez confirmado, el sistema le enviará de nuevo emails con el siguiente contenido:',
            resubscribeButtonLegend: 'Re-subscribir',
            systemsEnabled: 'Las siguientes notificaciones relacionadas con su dirección de correo han sido reactivadas:',
            welcomeBack: 'Bienvenido de nuevo'
         },

         verifyAccount: {
            welcome: 'Saludos!',
            logIn: 'identificarse',
            youSuccessfully: 'Su cuenta ha sido validada correctamente',
            youCanNow: 'Vd. podrá ahora',
            withTheDetails: 'con los datos que nos ha proporcionado, o bien ir a la',
            backHome: 'página de inicio',
            apologies: 'No hemos podido validar su cuenta.',
            pleaseTryAgain: 'Por favor, inténtelo de nuevo abriendo el email que le hemos enviado.',
            ifTheError: 'Si el error persiste, sea tan amable de contactarnos',
            contactUs: 'contáctenos'
         },
         resetPassword: {
            reset: 'Cambiar',
            resetAccountPassword: 'Cambiar contraseña',
            pleaseProvide: 'Por favor, introduzca el email que usó para registrar la cuenta',
            resetMyAccountPassword: 'Cambiar mi contraseña',
            pleaseTypeIn: 'Por favor, introduzca la nueva contraseña',
            changePassword: 'Cambiar contraseña',
            checkYourInbox: 'Revise su correo',
            weVeSent: 'Hemos enviado un email a su cuenta de correo.',
            pleaseFollowTheInstructions: 'Por favor, siga las instrucciones que allí se detallan para completar el cambio de contraseña',
            ifYouHaventReceiveIt: 'Si aún no ha recibido el correo, compruebe su carpeta de correo no deseado',
            close: 'Cerrar',
            somethingWentWrongDots: '... ha ocurrido un error imprevisto.',
            pleaseTryAgain: 'Por favor, inténtelo de nuevo',
            ifTheErrorPersists: 'Si el error persiste, por favor',
            tryAgain: 'Intentar de nuevo',
            sucessExclamationMark: 'Excelente!!!',
            yourPasswordReset: 'Su contraseña ha sido cambiada correctamente',
            noLongerValid: 'El correo que ha usado no es válido o ya ha sido usado. Es necesario que solicite de nuevo un cambio de contraseña',
            unknownError: 'Ha ocurrido un error imprevisto.',
            pleaseTryAgainOrContactUs: 'Por favor, inténtelo de nuevo. Si el error persiste, por favor',
            thereWasAnError: 'Ha habido un error',
            weCouldnt: 'No hemos podido cambiar su contraseña'
         },

         userDashboard: {
            emptyState: {
               emptyCollection: 'Collección vacía',
               letsStart: 'Añadir proyectos'
            },
            account: 'Cuenta',
            collection: 'Colección',
            originalListing: 'Listado original',
            admin: 'Admin',
            accountSettings: {
               description: 'En esta página podrá cambiar la configuración de tu cuenta.',
               changeName: 'Cambiar nombre',
               changePassword: 'Cambiar contraseña',
               changeNewsletterFrequency: 'Cambiar boletín',
               changeEmail: 'Cambiar email',
               passwordChanged: 'Contraseña actualizada',
               passwordResetMessage: 'La contraseña se ha restablecido correctamente.',
               redirectionNotice: 'Su sesión actual ha sido cancelada. Por favor inicie sesión con su nueva contraseña.'
            }
         },

         downloadDetails: {
            downloadTokenNotFound: 'No encontrado',
            ready: 'Los archivos PDF han sido generados. Elija cualquiera de las siguientes opciones para descargar',
            downloadInstructions: 'Plantilla lista',
            summarizedByGrid: 'Resumido por rejilla',
            summarizedByColor: 'Resumido por color',
            mobile: 'Móvil',
            tablet: 'Tablet',
            desktop: 'PC',
            download: 'Descargar',
            regenerateStatus: {
               title: 'Regenerar ficheros',
               willBeAvailable: 'Los archivos PDF estarán disponibles después de hacer clic en el botón Regenerar.',
               regenerateButtonLegend: 'Regenerar',
               tooltip: {
                  generatedInThePast: 'Los archivos PDF para esta plantilla se generaron en su momento, pero se eliminaron recientemente para facilitar las tareas de mantenimiento.',
                  noWorries: '¡No es problema! se pueden crear de nuevo sin coste adicional.',
                  clickInButton: 'Simplemente haga clic en el botón Regenerar y estarán disponibles nuevamente.'
               }
            },
            inProgressStatus: {
               inProgressTitle: 'En curso',
               generatingNow: 'Los archivos PDF están siendo generados en este momento.',
               willReceive: 'Vd. recibirá un e-Mail cuando hayan sido completados.',
               eta: 'Estimado',
               hourSingular: 'hora',
               hourPlural: 'horas',
               lessThan: 'menos de'
            }
         },
         verification: {
            yourPdfShouldBeIn: new ParametrizedTranslation('Su fichero PDF debería estar en %language%'),
            language: 'Idioma',
            verifiedTitle: 'Correctamente verificado',
            successfullyVerified: 'El código QR que ha escaneado ha sido emitido por nosotros. Su PDF debería contener la siguiente información',
            detailsTitle: 'Detalles',
            dimensionDetails: new ParametrizedTranslation('Su proyecto tiene un ancho de %stitchesWidth% puntos X un alto de %stitchesHeight% puntos. Cubre el %coveragePercentage%% del tejido, con un total de %totalStitches% puntadas.'),
            pdfFormat: 'Formato del PDF',
            pdfFormatDetails: new ParametrizedTranslation('Su archivo PDF ha sido optimizado para %device%. %summaryType%.'),
            somethingNotRight: '¿Algo no encaja?',
            quoteTheFollowing: 'y mencione la siguiente referencia',
            somethingNotRightContactUs: {
               predefinedSubject: 'En relación con la referencia ',
               predefinedBody: new ParametrizedTranslation('Estimado equipo de %branding%. Existe un error con la referencia de descarga')
            }
         },

         videoPromo: {
            noVideo: 'Su navegador no puede reproducir este vídeo',
            videoAsset: new ParametrizedTranslation('assets/website_promo/%videoType%/spanish.mp4'),
            slide: {
               noSymbols: {
                  title: 'Rejilla minimalista',
                  body: 'Un símbolo para las puntadas. Fácil de leer sin forzar la vista.'
               },
               unlimitedColors: {
                  title: 'Ilimitado número de colores',
                  body: 'Todos los colores de la paleta pueden usarse sin incrementar la complejidad del patrón'
               },
               combinedPalettes: {
                  title: 'Paletas combinadas',
                  body: 'Es posible expandir el rango de colores disponibles combinando varias paletas. Díganos sus preferencias'
               },
               pdfFormats: {
                  title: 'Seleccione el tipo de PDF',
                  body: 'Proporcionamos tres tipos de PDF optimizados para su dispositivo: PC, tablet o móvil'
               },
               gridFormats: {
                  title: 'Dos tipos de rejillas',
                  body: 'Elija entre PDF agrupado por color o por rejilla'
               },
               allAvailableCombinations: {
                  title: 'Todas las combinaciones',
                  body: 'Con cada compra, tendrá acceso seis PDFs en total'
               },
               chooseLanguages: {
                  title: 'Elija su idioma',
                  body: 'Los PDFs y la página web están disponibles en los idiomas más comunes.'
               },
               realisticPreview: {
                  title: 'Vista realista',
                  body: 'Proporcionamos una imagen muy próxima al resultado final'
               }
            }
         }
      };
   }
}
