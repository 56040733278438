import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {EmptyTemplateCreationDetails} from './type/empty-template-creation-details';
import {environment} from '../../../../environments/environment';
import {PayloadNamingRepo as PNR} from '../xstitch-api-gateway/payload-naming-repo';
import {EmptyTemplateCreationDetailsInterface} from './interface/empty-template-creation-details-interface';
import {HttpErrorResponse} from '@angular/common/http';
import {StandardApiErrorResponse} from '../xstitch-api-gateway/type/standard-api-error-response';
import {ProjectTemplateInterface} from '../search-pattern/interface/project-template-interface';
import {PaletteInterface} from '../search-pattern/type/palette/palette-interface';

@Injectable({
   providedIn: 'root'
})
export class ProjectTemplateService {

   constructor(private xstitchApiGatewayService: XstitchApiGatewayService) {
   }

   public createEmptyTemplate(projectId: number | null): Observable<EmptyTemplateCreationDetails> {
      return new Observable<EmptyTemplateCreationDetails>((subscriber: Subscriber<EmptyTemplateCreationDetails>) => {
         const endpoint = environment.xstitchApiEndpoints.admin.projectTemplateAdmin.createEmptyTemplate;

         const payload = new Map<string, any>();
         if (projectId !== null) {
            payload.set(PNR.PROJECT_ID, projectId);
         }

         const response = this
            .xstitchApiGatewayService
            .postWithJsonBodyRelativeRoute<EmptyTemplateCreationDetailsInterface>(endpoint, payload);

         response.subscribe((r: EmptyTemplateCreationDetailsInterface) => {
            subscriber.next(EmptyTemplateCreationDetails.constructFromInterface(r));
         }, (error: HttpErrorResponse) => {
            subscriber.error(StandardApiErrorResponse.constructFromHttpErrorResponse(error));
         });
      });
   }

   public updateTemplate(t: ProjectTemplateInterface): Observable<void> {
      return new Observable<void>((s: Subscriber<void>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.projectTemplateAdmin.updateAndDeleteTemplate;
         endpoint = endpoint.replace('{templateId}', t.templateId.toString());

         const payload = new Map<string, any>();

         const paletteIds: Array<number> = [];

         t.palettes.forEach((p: PaletteInterface) => {
            paletteIds.push(p.id);
         });

         payload
            .set(PNR.TEMPLATE_LIVE, t.isLive)
            .set(PNR.TEMPLATE_GRID_MODULE, parseInt(t.gridModuleId.toString(), 10))
            .set(PNR.TEMPLATE_PRICE_GBP_INCLUDING_TAX, parseFloat(t.priceGbpIncludingTax.toString()))
            .set(PNR.TEMPLATE_PALETTES, paletteIds)
         ;

         const response = this.xstitchApiGatewayService.putWithJsonBodyRelativeRoute(endpoint, payload);

         response.subscribe(() => {
            s.next();
         }, (e: HttpErrorResponse) => {
            s.error(StandardApiErrorResponse.constructFromHttpErrorResponse(e));
         });
      });
   }

   public deleteTemplate(templateId: number): Observable<void> {
      return new Observable<void>((s: Subscriber<void>) => {
         let endpoint = environment.xstitchApiEndpoints.admin.projectTemplateAdmin.updateAndDeleteTemplate;
         endpoint = endpoint.replace('{templateId}', templateId.toString());

         const response = this.xstitchApiGatewayService.deleteRelativeRoute(endpoint);

         response.subscribe(() => {
            s.next();
         }, (e: HttpErrorResponse) => {
            s.error(StandardApiErrorResponse.constructFromHttpErrorResponse(e));
         });
      });
   }
}
