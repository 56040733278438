import {AdminProjectTag} from './project-tag/admin-project-tag';

export class AdminProjectTagCollection {
   private tagsIndexed: Map<number, AdminProjectTag> = new Map<number, AdminProjectTag>();
   private readonly tagsNotIndexed: Array<AdminProjectTag> = [];

   public constructor(a: Array<AdminProjectTag>) {
      a.forEach((t: AdminProjectTag) => {
         this.tagsIndexed.set(t.getId(), t);
      });
      this.tagsNotIndexed = a;
   }

   getTagsAsArray(): Array<AdminProjectTag> {
      return this.tagsNotIndexed;
   }

   getById(tagId: number): AdminProjectTag | null {
      if (this.tagsIndexed.has(tagId)) {
         return this.tagsIndexed.get(tagId);
      }

      return null;
   }
}
