import {Injectable} from '@angular/core';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subscriber} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {LanguageDetectorService} from '../../locale/language-detector/language-detector.service';
import {HttpResponse} from '@angular/common/http';
import {StandardApiResponseDeprecated} from '../xstitch-api-gateway/type/standard-api-response-deprecated';
import {PayloadNamingRepo as PNR} from '../xstitch-api-gateway/payload-naming-repo';
import {SharingPlatforms} from '../search-pattern/type/sharing-platforms';
import {SocialMediaShare} from '../search-pattern/type/social-media-share/social-media-share';
import {SocialMediaShareInterface} from '../search-pattern/interface/social-media-share/social-media-share-interface';

@Injectable({
   providedIn: 'root'
})
export class EventLoggerService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService, private languageDetector: LanguageDetectorService) {
   }

   logTemplateShareFacebookClick(templateId: number): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.facebookOutboundTraffic;

      return this.genericLog(templateId, endpoint);
   }

   logTemplateSharePinterestClick(templateId: number): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.pinterestOutboundTraffic;

      return this.genericLog(templateId, endpoint);
   }

   shareSearchSocialMediaOutbound(searchId: number, platform: SharingPlatforms): Observable<SocialMediaShare> {

      let endpoint: string;

      if (platform === SharingPlatforms.facebook) {
         endpoint = environment.xstitchApiEndpoints.search.shareSearchFacebookOutbound;
      } else if (platform === SharingPlatforms.pinterest) {
         endpoint = environment.xstitchApiEndpoints.search.shareSearchPinterestOutbound;
      } else {
         throw new Error('Not implemented');
      }

      return new Observable<SocialMediaShare>((subs: Subscriber<SocialMediaShare>) => {
         const parameters = new Map<string, any>();
         parameters.set('searchId', searchId);

         const response = this
            .xstitchApiGateway
            .postWithJsonBodyRelativeRoute<SocialMediaShareInterface>(endpoint, parameters);

         response.subscribe((s: SocialMediaShareInterface) => {
            subs.next(SocialMediaShare.constructFromInterface(s));
         }, (e: Error) => {
            subs.error(e);
         });
      });
   }

   shareSearchResultsByEmailOutbound(searchId: number, friendsName: string, friendsEmail: string): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.search.shareSearchByEmailOutbound;
      const parameters = new Map<string, any>();

      parameters.set(PNR.FRIEND_EMAIL, friendsEmail);
      parameters.set(PNR.FRIEND_NAME, friendsName);
      parameters.set(PNR.SEARCH_ID, searchId);

      return new Observable<void>((s: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute(endpoint, parameters);

         response.subscribe(() => {
            s.next();
         }, () => {
            s.error();
         });
      });
   }

   logUserShareTemplateByEmail(templateId: number, friendsName: string, friendsEmail: string): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.shareTemplateByEmail;
      const parameters = new Map<string, any>();

      parameters.set(PNR.FRIEND_EMAIL, friendsEmail);
      parameters.set(PNR.USER_NAME, friendsName);
      parameters.set(PNR.TEMPLATE_ID_DEPRECATED, templateId);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<void>(endpoint, parameters);

         return response.subscribe(() => {
            subscriber.next();
         }, () => {
            // No need to inform the client that something in the backend had happened in this case.
            subscriber.next();
         });
      });
   }

   unsubscribeEmailShare(managingToken: string): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.unsubscribeEmailShare;
      const parameters = new Map<string, any>();

      parameters.set(PNR.MANAGING_TOKEN, managingToken);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<void>(endpoint, parameters);

         return response.subscribe(() => {
            subscriber.next();
         }, () => {
            subscriber.next();
         });
      });
   }

   resubscribeEmailShare(managingToken: string): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.resubscribeEmailShare;
      const parameters = new Map<string, any>();

      parameters.set(PNR.MANAGING_TOKEN, managingToken);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<void>(endpoint, parameters);

         return response.subscribe(() => {
            subscriber.next();
         }, () => {
            subscriber.next();
         });
      });
   }

   triggerAddToFavouriteEvent(projectId: number): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.addTemplateToFavourites;
      return this.manageProjectFavouritesCommon(projectId, endpoint);
   }

   triggerRemoveFromFavouritesEvent(projectId: number): Observable<void> {
      const endpoint = environment.xstitchApiEndpoints.events.removeTemplateFromFavourites;
      return this.manageProjectFavouritesCommon(projectId, endpoint);
   }

   private manageProjectFavouritesCommon(projectId: number, endpoint: string): Observable<void> {

      const parameters = new Map<string, any>();
      parameters.set(PNR.PROJECT_ID, projectId);

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyDeprecated(endpoint, parameters);

         return response.subscribe((a: object) => {
            const r: HttpResponse<object> = new HttpResponse(a);
            const report = StandardApiResponseDeprecated.constructFromHttpSuccessResponse(r);
            if (report.containsErrorItems()) {
               subscriber.error(report);
            } else {
               subscriber.next();
            }
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   private genericLog(templateId: number, apiEndpoint: string): Observable<void> {

      const lan = this.languageDetector.getLanguage().getIso639Dash1Locale();

      const endpoint = apiEndpoint + templateId + '/language/' + lan;

      return new Observable<void>((subscriber: Subscriber<void>) => {
         const response = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<void>(endpoint, new Map<string, any>());

         return response.subscribe(() => {
            subscriber.next();
         }, () => {
            // No need to inform the client that something in the backend had happened in this case.
            subscriber.next();
         });
      });
   }
}
