import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c11-a1-web-browser-cookies',
  templateUrl: './c11-a1-web-browser-cookies.component.html'
})
export class C11A1WebBrowserCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
