export class UserAdvancedSearchParamsObject {

   private includeTagIds: Map<number, string>;
   private includeKeywords: Map<string, string>;

   public constructor(includeTagIds: Array<number>, includeKeywords: Array<string>) {

      this.includeTagIds = new Map<number, string>();
      this.includeKeywords = new Map<string, string>();

      includeTagIds.forEach((a: number) => {
         this.includeTagIds.set(a, '');
      });

      includeKeywords.forEach((a: string) => {
         if (a.trim().length > 0) {
            this.includeKeywords.set(a.trim(), '');
         }
      });
   }

   public static buildFromJsonString(jsonString: string | null | undefined): UserAdvancedSearchParamsObject {
      const empty = new UserAdvancedSearchParamsObject([], []);
      if ((jsonString === null) || (jsonString === undefined)) {
         return empty;
      }

      try {
         const parsedSearchParams = JSON.parse(jsonString);

         if (parsedSearchParams === undefined) {
            return empty;
         }

         if ((parsedSearchParams.includeTagIds === undefined) || !(parsedSearchParams.includeTagIds instanceof Array)) {
            return empty;
         }

         if ((parsedSearchParams.includeKeywordList === undefined) || !(parsedSearchParams.includeKeywordList instanceof Array)) {
            return empty;
         }


         const unserializedTagIds: Array<any> = parsedSearchParams.includeTagIds;
         const unserializedKeywords: Array<any> = parsedSearchParams.includeKeywordList;

         const includeTagIds: Array<number> = [];
         const includeKeywords: Array<string> = [];

         let b: number;

         unserializedTagIds.forEach((a: any) => {

            b = parseInt(a.toString(), 10);
            if (!isNaN(b)) {
               includeTagIds.push(b);
            }
         });

         unserializedKeywords.forEach((a: any) => {
            includeKeywords.push(a.toString());
         });

         return new UserAdvancedSearchParamsObject(includeTagIds, includeKeywords);

      } catch (e) {
         return empty;
      }
   }

   public convertToJsonString(): string {
      const serialized = {
         includeTagIds: Array.from(this.includeTagIds.keys()),
         includeKeywordList: Array.from(this.includeKeywords.keys())
      };

      return JSON.stringify(serialized);
   }

   hasTagId(tagId: number): boolean {
      return this.includeTagIds.has(tagId);
   }

   getKeywordsCommaSeparated(): string {
      let result = '';

      this.includeKeywords.forEach((v: string, k: string) => {
         result = result + k + ', ';
      });

      if (result.length > 0) {
         result = result.substr(0, result.length - 2);
      }

      return result;

   }

   setRawKeywords(rawKeywords: string | null): void {
      this.includeKeywords.clear();

      if (rawKeywords === null) {
         return;
      }

      const individual = rawKeywords.split(',');

      individual.forEach((s: string) => {
         if (s.trim().length > 0) {
            this.includeKeywords.set(s, s);
         }
      });
   }

   addSelectedTagId(tagId: number): void {
      if (!this.includeTagIds.has(tagId)) {
         this.includeTagIds.set(tagId, '');
      }
   }

   removeSelectedTagId(tagId: number): void {
      if (this.includeTagIds.has(tagId)) {
         this.includeTagIds.delete(tagId);
      }
   }

   reset(): void {
      this.includeKeywords.clear();
      this.includeTagIds.clear();
   }

   getIncludedTagIds(): Array<number> {
      return Array.from(this.includeTagIds.keys());
   }

   getIncludedKeywordList(): Array<string> {
      return Array.from(this.includeKeywords.keys());
   }
}
