import {ShoppingCartStorageService} from '../../service/shopping-cart/shopping-cart-storage.service';
import {SearchPatternsService} from '../../service/api-client/search-pattern/search-patterns.service';
import {LanguageDetectorService} from '../../service/locale/language-detector/language-detector.service';
import {ProjectFullInfo} from '../../service/api-client/project/type/project-full-info';
import {ShoppingCartItem} from '../../service/shopping-cart/object/shopping-cart-item';
import {ShoppingCartItemDetails} from '../object/shopping-cart-item-details';
import {ProjectTemplate} from '../../service/api-client/search-pattern/type/project-template';
import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ShoppingCartContainer} from '../object/shopping-cart-container';
import {CommonNavBarRightComponent} from '../../top-navigation-bar/common-nav-bar-right/common-nav-bar-right.component';

@Injectable({
   providedIn: 'root'
})
export class BuildShoppingCartListService {

   private static computeIndexForTemplate(projectId: number, templateId: number): string {
      return projectId.toString() + '-' + templateId.toString();
   }

   constructor(
      private shoppingCartStorageService: ShoppingCartStorageService,
      private searchPatternsService: SearchPatternsService,
      private languageDetectorService: LanguageDetectorService
   ) {
   }

   public buildShoppingCartListAsync(): Observable<ShoppingCartContainer> {
      const result: ShoppingCartContainer = new ShoppingCartContainer();
      const shoppingCartContainer = this.shoppingCartStorageService.readFromStorage();
      const language = this.languageDetectorService.getLanguage();

      const activeItems = shoppingCartContainer.getActiveItems();

      let index: string;
      let template: ProjectTemplate | null;

      return new Observable<ShoppingCartContainer>((subscriber: Subscriber<ShoppingCartContainer>) => {
         CommonNavBarRightComponent.updateShoppingCartCount(activeItems.length);
         if (activeItems.length === 0) {
            subscriber.next(result);
         } else {
            activeItems.forEach((item: ShoppingCartItem) => {
               index = BuildShoppingCartListService.computeIndexForTemplate(item.getProjectId(), item.getTemplateId());
               this.searchPatternsService.getPatternById(item.getProjectId()).subscribe(
                  (searchResult: ProjectFullInfo) => {

                     template = searchResult.getTemplateById(item.getTemplateId());

                     if (template !== null) {
                        result.addItem(ShoppingCartItemDetails.buildFromSearchResultObj(
                           searchResult,
                           language,
                           item.getProjectId(),
                           item.getTemplateId(),
                           item.getNotes()
                        ));
                        subscriber.next(result);
                     }
                  });
            });
         }
      });
   }
}
