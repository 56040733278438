import {StandardResponseItemDeprecated} from './standard-response-item-deprecated';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ClientUtilsApiResponseInterfaceDeprecated} from '../interface/client-utils-api-response-interface-deprecated';

/**
 * @deprecated
 */
export class StandardApiResponseDeprecated {
   private readonly errorItems: Array<StandardResponseItemDeprecated>;
   private readonly statusCode: number;

   constructor(httpErrorCode: number, errors: Array<StandardResponseItemDeprecated>) {
      this.statusCode = httpErrorCode;
      this.errorItems = errors;
   }

   /**
    * @deprecated
    */
   static constructFromHttpErrorResponse(error: HttpErrorResponse): StandardApiResponseDeprecated {
      const statusCode = error.status;
      const errors = error.error.errors;
      const errorCollection = new Array<StandardResponseItemDeprecated>();

      if (!((errors === null) || (errors === undefined))) {
         errors.forEach((e: object) => {
            errorCollection.push(StandardResponseItemDeprecated.constructFromPlainObject(e));
         });
      }

      return new StandardApiResponseDeprecated(statusCode, errorCollection);
   }

   /**
    * @deprecated
    */
   static constructFromHttpSuccessResponse(response: HttpResponse<ClientUtilsApiResponseInterfaceDeprecated>): StandardApiResponseDeprecated {
      const statusCode = response.status;
      const errorCollection = new Array<StandardResponseItemDeprecated>();
      const errors = response.body?.errors;

      if (!((errors === null) || (errors === undefined))) {
         errors.forEach((e: object) => {
            errorCollection.push(StandardResponseItemDeprecated.constructFromPlainObject(e));
         });
      }

      return new StandardApiResponseDeprecated(statusCode, errorCollection);
   }

   getResponseItems(): Array<StandardResponseItemDeprecated> {
      return this.errorItems;
   }

   getHttpStatusCode(): number {
      return this.statusCode;
   }

   containsErrorItems(): boolean {
      return this.errorItems.length > 0;
   }

   containsErrorItemCode(errorItemCode: number): boolean {
      let result = false;
      this.errorItems.forEach((e: StandardResponseItemDeprecated) => {
         if (e.getCode() === errorItemCode) {
            result = true;
         }
      });

      return result;
   }
}
