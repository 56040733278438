import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ShoppingCartItem} from '../service/shopping-cart/object/shopping-cart-item';
import {CommonNavBarRightComponent} from '../top-navigation-bar/common-nav-bar-right/common-nav-bar-right.component';
import {ShoppingCartStorageService} from '../service/shopping-cart/shopping-cart-storage.service';
import {PatternClientFlagsService} from '../service/pattern-client-flags/pattern-client-flags.service';
import {PatternFlagsItem} from '../service/pattern-client-flags/object/pattern-flags-item';
import {Router} from '@angular/router';
import {DomUtils} from '../utils/dom/dom-utils';
import * as bootstrap from 'bootstrap';
import {EventLoggerService} from '../service/api-client/events/event-logger.service';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {AbstractUserNameControlIcons} from '../form-reusable-components/textboxes/online-validated-username-textbox/UserNameControlIcons/abstract-user-name-control-icons';
import {UserNameControlStandardIcon} from '../form-reusable-components/textboxes/online-validated-username-textbox/UserNameControlIcons/user-name-control-standard-icon';
import {ProjectTemplate} from '../service/api-client/search-pattern/type/project-template';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {OnlineValidatedUsernameTextboxComponent} from '../form-reusable-components/textboxes/online-validated-username-textbox/online-validated-username-textbox.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {environment} from '../../environments/environment';
import {SocialMediaShareUtils} from '../utils/social-media/social-media-share-utils';

@Component({
   selector: 'app-pattern-actions',
   templateUrl: './pattern-actions.component.html',
   styleUrls: ['./pattern-actions.component.scss']
})

export class PatternActionsComponent implements OnInit {

   shareWithFriendsModalStatuses = {
      beforeShare: 0,
      shareSuccess: 2
   };

   shareWithFriendsModalStatus: number;

   sharedWithFriends: boolean;
   sharedInFacebook: boolean;
   sharedInPinterest: boolean;

   @Input() templateObject: ProjectTemplate;
   @Input() projectId: number;
   @Input() showShareFriendsIcon = false;
   @Input() showShareFacebookIcon = false;
   @Input() showSharePinterestIcon = false;
   @Input() showFavouritesIcon = false;
   @Input() isFavouriteProject = false;
   @Input() showShoppingCartIcon = false;
   @Input() showInfoIcon = false;
   @Input() implicitFormat = true;
   @Input() addToShoppingCartHandler: () => void;
   @Input() removeFromShoppingCartHandler: () => void;

   @ViewChild(OnlineValidatedEmailComponent) onlineValidatedEmailComponent: OnlineValidatedEmailComponent;
   @ViewChild(OnlineValidatedUsernameTextboxComponent) onlineValidatedUsernameTextboxComponent: OnlineValidatedUsernameTextboxComponent;
   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent;

   socialPopup: bootstrap.Modal | null = null;

   inShoppingCart: boolean;

   translationSheet: TranslationSheet;

   shareWithEmailValid = false;
   shareWithNameValid = false;
   shareSuccessModalId = 'share_success';

   bussyButton = '';

   asyncSharingTypes = {
      // Used to show a spinner while the sharing URLs are calculated. This only happens (so far)with the buttons to
      // share in social media
      facebook: 1,
      pinterest: 2
   };

   constructor(
      private shoppingCartStorageService: ShoppingCartStorageService,
      private patternClientFlagsStorageService: PatternClientFlagsService,
      private router: Router,
      private eventLoggerService: EventLoggerService,
      private staticDictionary: StaticDictionaryService,
      private languageDetectorService: LanguageDetectorService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.shareWithFriendsModalStatus = this.shareWithFriendsModalStatuses.beforeShare;
      this.templateInShoppingCart();
      const patternFlagsContainer = this.patternClientFlagsStorageService.readFromStorage();

      const thisPatternFlags = patternFlagsContainer.getItemByPatternId(this.projectId);

      if (thisPatternFlags === null) {
         this.sharedWithFriends = false;
         this.sharedInFacebook = false;
         this.sharedInPinterest = false;
      } else {
         this.sharedWithFriends = thisPatternFlags.isSharedWithFriends();
         this.sharedInFacebook = thisPatternFlags.isSharedInFacebook();
         this.sharedInPinterest = thisPatternFlags.isSharedInPinterest();
      }

      const element = DomUtils.getHtmlElementById('exampleModal');
      if ((this.socialPopup === null) && (element !== null)) {
         this.socialPopup = new bootstrap.Modal(element, {keyboard: false});
      }
   }

   shouldShowSecondaryShoppingCartButton(): boolean {
      const itemsInShoppingCart = this.shoppingCartStorageService.readFromStorage().countActiveItems();
      const shoppingCartEmpty = itemsInShoppingCart === 0;

      if (shoppingCartEmpty) {
         return false;
      }

      return !shoppingCartEmpty && this.showShoppingCartIcon;
   }

   private templateInShoppingCart(): void {
      const shoppingCart = this.shoppingCartStorageService.readFromStorage();

      let templatesInShoppingCart;

      if ((this.templateObject === null) || (this.templateObject === undefined)) {
         templatesInShoppingCart = shoppingCart.getItemsByProjectId(this.projectId);
         this.inShoppingCart = templatesInShoppingCart.length > 0;
      } else {
         templatesInShoppingCart = shoppingCart.getItemByIds(this.projectId, this.templateObject.getTemplateId());
         this.inShoppingCart = templatesInShoppingCart !== null;
      }
   }

   addToShoppingCart(): void {

      if (!((this.addToShoppingCartHandler === null) || (this.addToShoppingCartHandler === undefined))) {
         this.addToShoppingCartHandler();
         return;
      }

      const item = new ShoppingCartItem(this.projectId, this.templateObject.getTemplateId(), 1, '', false);
      const shoppingCart = this.shoppingCartStorageService.readFromStorage();
      shoppingCart.addItem(item);
      this.shoppingCartStorageService.writeToStorage(shoppingCart);
      this.inShoppingCart = true;
      CommonNavBarRightComponent.updateShoppingCartCount(shoppingCart.countActiveItems());
   }

   removeFromShoppingCart(): void {
      if (!((this.removeFromShoppingCartHandler === null) || (this.removeFromShoppingCartHandler === undefined))) {
         this.removeFromShoppingCartHandler();
         return;
      }
      const shoppingCart = this.shoppingCartStorageService.readFromStorage();

      if ((this.templateObject === null) || (this.templateObject === undefined)) {
         shoppingCart.removeItemsByProjectId(this.projectId);
      } else {
         shoppingCart.removeItemByIds(this.projectId, this.templateObject.getTemplateId());
      }

      this.shoppingCartStorageService.writeToStorage(shoppingCart);
      this.inShoppingCart = false;
      CommonNavBarRightComponent.updateShoppingCartCount(shoppingCart.countActiveItems());
   }

   shareWithFriends(): void {
      this.writeShareWithFriendsToLocalStore();

      const elem = DomUtils.getNullSafeHtmlElementById(this.calculateShareWithFriendsModalId());
      const popup = new bootstrap.Modal(elem);
      popup.show();
   }

   calculateShareWithFriendsModalId(): string {
      return 'send-to-a-friend-in-modal-toggle_' +
         this.templateObject.getProjectId().toString() + '_' +
         this.templateObject.getTemplateId().toString();
   }

   private writeShareWithFriendsToLocalStore(): void {
      const patternFlagsContainer = this.patternClientFlagsStorageService.readFromStorage();

      let itm = patternFlagsContainer.getItemByPatternId(this.projectId);

      if (itm === null) {
         itm = new PatternFlagsItem(this.projectId, true, false, false);
         patternFlagsContainer.addItem(itm);
      } else {
         itm.setSharedWithFriends(true);
      }

      this.sharedWithFriends = true;
      this.patternClientFlagsStorageService.writeToStorage(patternFlagsContainer);
   }

   shareInFacebook(sharingButton: string): void {
      this.bussyButton = sharingButton;

      this.eventLoggerService.logTemplateShareFacebookClick(this.templateObject.getTemplateId()).subscribe(() => {
         const patternFlagsContainer = this.patternClientFlagsStorageService.readFromStorage();

         let itm = patternFlagsContainer.getItemByPatternId(this.projectId);

         // I took this URL from the FB share plug in. I didn't like how the embedded iFrame looked like, and I didn't like the
         // look & feel of the share button.

         SocialMediaShareUtils.openFacebookSharePopup(
            this.templateObject.getFacebookShareForLanguage(this.languageDetectorService.getLanguage())
         );

         if (itm === null) {
            itm = new PatternFlagsItem(this.projectId, false, true, false);
            patternFlagsContainer.addItem(itm);
         } else {
            itm.setSharedInFacebook(true);
         }

         this.sharedInFacebook = true;
         this.patternClientFlagsStorageService.writeToStorage(patternFlagsContainer);
         this.bussyButton = '';
      }, () => {
         this.bussyButton = '';
      });
   }

   shareInPinterest(sharingButton: string): void {

      this.bussyButton = sharingButton;

      this.eventLoggerService.logTemplateSharePinterestClick(this.templateObject.getTemplateId()).subscribe(() => {
         const patternFlagsContainer = this.patternClientFlagsStorageService.readFromStorage();

         let itm = patternFlagsContainer.getItemByPatternId(this.projectId);

         if (itm === null) {
            itm = new PatternFlagsItem(this.projectId, false, false, true);
            patternFlagsContainer.addItem(itm);
         } else {
            itm.setSharedInPinterest(true);
         }

         this.sharedInPinterest = true;
         this.patternClientFlagsStorageService.writeToStorage(patternFlagsContainer);

         SocialMediaShareUtils.openPinterestSharePopupForProjectTemplate(
            this.templateObject,
            this.languageDetectorService.getLanguage()
         );
         this.bussyButton = '';
      }, () => {
         this.bussyButton = '';
      });
   }

   getShareWithFriendIcon(): AbstractUserNameControlIcons {
      return new UserNameControlStandardIcon();
   }

   canShareTemplate(): boolean {
      return this.shareWithEmailValid && this.shareWithNameValid;
   }

   shareThisTemplate(): void {
      const email = this.onlineValidatedEmailComponent.getWellFormedEmail();
      const name = this.onlineValidatedUsernameTextboxComponent.getWellFormedUsername();

      this.primaryActionComponent.setBusy(true);

      this.eventLoggerService.logUserShareTemplateByEmail(this.templateObject.getTemplateId(), name, email).subscribe(
         () => {
            this.recicleShareWithFriends();
         }, () => {
            this.recicleShareWithFriends();
         });
   }

   private recicleShareWithFriends(): void {

      this.primaryActionComponent.setBusy(false);
      this.shareWithFriendsModalStatus = this.shareWithFriendsModalStatuses.shareSuccess;

      const success = DomUtils.getNullSafeHtmlElementById(this.shareSuccessModalId);
      const successModal = new bootstrap.Modal(success);
      successModal.show();
      this.shareWithFriendsModalStatus = this.shareWithFriendsModalStatuses.beforeShare;
   }

   goToShoppingCart(): void {
      this.router.navigateByUrl(environment.frontEndRoutes.shoppingCartRoute).then();
   }

   getButtonId(implicitMod: boolean, shareType: number): string {
      let result: string;
      result = this.projectId.toString() + '_' + this.templateObject.getTemplateId() + '_' + shareType;

      if (implicitMod) {
         result = 'implicit_' + result;
      } else {
         result = 'explicit_' + result;
      }

      return result;
   }
}
