import {TagDetailsInterface} from '../interface/tag-details.interface';
import {AbstractLanguage} from '../../../locale/language-detector/type/abstract-language';
import {Spanish} from '../../../locale/language-detector/type/spanish';
import {French} from '../../../locale/language-detector/type/french';

export class TagDetailsObject {

   constructor(
      protected tagId: number,
      protected english: string,
      protected spanish: string,
      protected french: string,
      protected imgUrl: string,
      protected countProjectsWithSameTag: number,
      protected cssSubclass: string
   ) {

   }

   static constructFromApi(i: TagDetailsInterface): TagDetailsObject {
      return new TagDetailsObject(
         i.tagId,
         i.tagName.english,
         i.tagName.spanish,
         i.tagName.french,
         i.imgUrl,
         i.countProjectsWithSameTag,
         i.cssSubclass
      );
   }

   getTagId(): number {
      return this.tagId;
   }

   getTitleForLanguage(l: AbstractLanguage): string {
      if (l instanceof Spanish) {
         return this.spanish;
      }

      if (l instanceof French) {
         return this.french;
      }

      return this.english;
   }

   getCategoryImgUrl(): string {
      return this.imgUrl;
   }

   getCountProjectsWithSameTag(): number {
      return this.countProjectsWithSameTag;
   }

   getCssSubclass(): string {
      return this.cssSubclass;
   }
}
