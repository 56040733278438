import {AbstractLanguage} from './abstract-language';

export class English extends AbstractLanguage {
   public static readonly LITERAL = 'en-GB';

   getApiLiteral(): string {
      return English.LITERAL;
   }

   getIso639Dash1Locale(): string {
      return 'en';
   }

   getDbId(): number {
      return 1;
   }

   isEnglish(): boolean {
      return true;
   }

   isFrench(): boolean {
      return false;
   }

   isSpanish(): boolean {
      return false;
   }
}
