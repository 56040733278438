import {Component, Input, OnInit} from '@angular/core';
import {AbstractSelfContainedModalComponent} from '../abstract-self-contained-modal/abstract-self-contained-modal.component';
import {UnconstructibleModalException} from '../exception/unconstructible-modal-exception';

@Component({
   selector: 'app-modal',
   templateUrl: './modal.component.html',
   styleUrls: ['./modal.component.scss']
})
export class ModalComponent extends AbstractSelfContainedModalComponent implements OnInit {

   public widthClasses = {
      W350px: 'w350px'
   };

   @Input() widthClass = '';

   ngOnInit(): void {
      if ((this.id === null) || (this.id === undefined)) {
         // Can't do this check on the constructor, because the bindings haven't occurred yet at that point
         throw new UnconstructibleModalException('Missing modal ID. Use proper binding [id] and not the attribute "id"');
      }
   }
}
