import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c02-personal-data-we-collect',
  templateUrl: './c02-personal-data-we-collect.component.html'
})
export class C02PersonalDataWeCollectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
