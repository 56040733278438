import {Component, OnInit, ViewChild} from '@angular/core';
import {BuildShoppingCartListService} from './service/build-shopping-cart-list-service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {Router} from '@angular/router';
import {CommonSearchBarComponent} from '../top-navigation-bar/common-search-bar/common-search-bar.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {SearchRequest} from '../service/api-client/search-pattern/type/search-request';
import {UserService} from '../service/api-client/user/user.service';
import {ShoppingCartContainer} from './object/shopping-cart-container';
import {UserCollection} from '../service/api-client/user/type/user-collection';
import {UserCollectionItem} from '../service/api-client/user/type/user-collection-item';
import {ShoppingCartStorageService} from '../service/shopping-cart/shopping-cart-storage.service';
import {environment} from '../../environments/environment';

@Component({
   selector: 'app-shopping-cart',
   templateUrl: './shopping-cart.component.html',
   styleUrls: ['./shopping-cart.component.scss']
})

export class ShoppingCartComponent implements OnInit {
   shoppingCartList: ShoppingCartContainer | null = null;

   translationSheet: TranslationSheet;

   loginCheckStatuses = {
      checking: 1,
      userLoggedIn: 2,
      userNotLoggedIn: 3
   };

   loginCheckStatus = this.loginCheckStatuses.checking;


   loadingShoppingCarStatuses = {
      loading: 1,
      loaded: 2
   };

   loadingShoppingCartStatus = this.loadingShoppingCarStatuses.loading;

   @ViewChild('generic_search_button') primaryActionComponent: PrimaryActionComponent;

   constructor(
      protected shoppingCartStorageService: ShoppingCartStorageService,
      protected buildShoppingCartListService: BuildShoppingCartListService,
      protected staticDictionaryService: StaticDictionaryService,
      protected router: Router,
      protected user: UserService
   ) {
      this.translationSheet = staticDictionaryService.getTranslationSheet();

      user.getCurrentUser().subscribe(() => {
         const shoppingCartContainer = this.shoppingCartStorageService.readFromStorage();

         this.loginCheckStatus = this.loginCheckStatuses.userLoggedIn;
         this.user.getUserCollection().subscribe((userCollection: UserCollection) => {
            userCollection.getItems().forEach((item: UserCollectionItem) => {
               shoppingCartContainer.removeItemByIds(item.getProjectId(), item.getTemplateId());
            });

            this.shoppingCartStorageService.writeToStorage(shoppingCartContainer);
            this.buildShoppingCart();
         });

      }, () => {
         this.loginCheckStatus = this.loginCheckStatuses.userNotLoggedIn;
         this.buildShoppingCart();
      });
   }

   private buildShoppingCart(): void {
      this.buildShoppingCartListService.buildShoppingCartListAsync().subscribe((s: ShoppingCartContainer) => {
         this.loadingShoppingCartStatus = this.loadingShoppingCarStatuses.loaded;
         this.shoppingCartList = s;
      });
   }

   ngOnInit(): void {

   }

   genericSearch(): void {
      this.primaryActionComponent.setBusy(true);
      const searchCriteria = new SearchRequest([], [], []);
      const route = environment.frontEndRoutes.searchResults + '?' + searchCriteria.serializeAsQueryString();
      this.router.navigateByUrl(route).then(() => {
         CommonSearchBarComponent.searchRequestParametersSubject.next(new SearchRequest([], [], []));
         this.primaryActionComponent.setBusy(false);
      });
   }
}
