import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c10-third-parties',
  templateUrl: './c10-third-parties.component.html'
})
export class C10ThirdPartiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
